import React from 'react';
import { FormControl, FormHelperText, InputLabel, Chip, makeStyles } from '@material-ui/core';
import { FieldTitle, useResourceContext, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';

const useStyles = makeStyles(theme => ({
  label: {
    transform: 'translate(0, -10px) scale(0.75)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(.5),
  },
}));

const onClick = (value, input, multiple) => {
  let nextValue;
  if (multiple) {
    const currentValue = input.value || [];
    nextValue = currentValue.includes(value)
      ? currentValue.filter(v => v !== value)
      : [...currentValue, value];
  } else {
    nextValue = input.value !== value ? value : null;
  }
  input.onChange(nextValue);
  input.onBlur(nextValue);
};

const ChipInput = ({ choices, optionText, input, id, label, source, isRequired, meta, allowEmpty = false, multiple = false, ...props }) => {
  const resource = useResourceContext(props);
  const classes = useStyles();
  const translate = useTranslate();
  const { values } = useFormState();
  const value = source && values[source];
  const { touched, error, submitError, helperText = false } = meta;

  const formHelperText = (touched && (error || submitError)) || helperText;

  const hasEmptyLabel = allowEmpty && allowEmpty !== true;
  return <FormControl
    error={!!(touched && (error || submitError))}
    {...props}
  >
    {label && (
      <InputLabel htmlFor={id} className={classes.label}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </InputLabel>
    )}
    <div className={classes.chips}>
      {choices.map(choice => {
        const choiceValue = choice.id;
        const label = typeof optionText === 'function'
          ? optionText(choice)
          : choice[optionText];
        const isSelected = multiple
          ? (input.value || []).includes(choiceValue)
          : input.value === choiceValue;
        const color = isSelected ? 'secondary' : undefined;
        const variant = isSelected ? 'default' : 'outlined';
        return (
          <Chip
            key={choiceValue}
            label={label}
            color={color}
            size="medium"
            variant={variant}
            className={classes.chip}
            onClick={() => onClick(choiceValue, input, multiple)}
          />
        );
      })}
      {hasEmptyLabel && choices.length > 0 && <Chip
        label={allowEmpty}
        color={value === null ? 'secondary' : undefined}
        variant={value === null ? 'default' : 'outlined'}
        size="medium"
        className={classes.chip}
        onClick={() => onClick(null, input, multiple)}
      />}
    </div>
    {formHelperText && (
      <FormHelperText>
        {translate(formHelperText, { _: formHelperText })}
      </FormHelperText>
    )}
  </FormControl>
};

export default ChipInput;
