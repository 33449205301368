import React from 'react';
import { TabbedForm, TabbedFormTabs, FormTab, useTranslate, useRecordContext, NumberInput, useQueryWithStore } from 'react-admin';
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FileCopyOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

import { FF_DOUBLE_FORFEIT, FF_PERIODS, FF_SPORTSMANSHIP } from '@hisports/common/featureFlags';

import { useFlag, useSport } from '../../http';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import SwitchInput from '../../common/inputs/SwitchInput';
import { FieldDependency } from '../../common/FieldDependency';
import { SurfaceSizeEnumInput } from '../../common/inputs/EnumInputs';
import { isAuthorized } from '../../common/Authorize';

import { SportsmanshipPointsInput, HiddenStatsInput, ScoringModeInput, PenaltyModeInput, PeriodsInput, DraftRequestInput, GroupingTypeInput } from '../officeSettings/OfficeSettingsForm';
import { SchemaInput } from '../schemas/SchemaInput';
import { OfficeInput } from '../offices/OfficeInput';
import { RankingInput } from './RankingInput';

const inputProps = {
  resource: 'scheduleSettings',
  basePath: '/scheduleSettings',
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const useStyles = makeStyles(theme => ({
  advancedTab: {
    color: theme.palette.tertiary.main,
  }
}));

const validate = values => {
  const errors = { points: {} }

  errors.points.Team = ['win', 'otWin', 'soWin', 'forfeitWin', 'loss', 'otLoss', 'soLoss', 'forfeitLoss', 'tie', 'forfeitTie']
    .reduce((errors, key) => {
      const value = values.points.Team[key];
      if (value == null) {
        errors[key] = 'ra.validation.required'
      }
      return errors;
    }, {})

  if (values.sportsmanshipPoints !== 'none' && values.points.Team.sportsmanship == null) {
    errors.points.Team.sportsmanship = 'ra.validation.required';
  }

  errors.points.Player = ['goal', 'assist']
    .reduce((errors, key) => {
      const value = values.points.Player[key];
      if (value == null) {
        errors[key] = 'ra.validation.required'
      } else if (value < 0) {
        errors[key] = 'ra.validation.greater_than_or_equal_zero'
      }
      return errors;
    }, {})

  if (values.maxScoreDiff != null && values.maxScoreDiff <= 0) errors.maxScoreDiff = 'ra.validation.greater_than_zero'
  if (values.gameLength != null && values.gameLength <= 0) errors.gameLength = 'resources.scheduleSettings.validations.game_length';
  if (values.rescheduleRequestsEnabled && !values.requiredRescheduleApprovals?.length) errors.requiredRescheduleApprovals = 'ra.validation.required'

  return errors;
}

const DefaultAlert = () => {
  const translate = useTranslate();
  return <Alert
    severity="info"
    fullWidth
    icon={<FileCopyOutlined />}
  >
    {translate('resources.scheduleSettings.alerts.new_scoresheets_only')}
  </Alert>
}

export const StandingsOrderInput = ({ source, type, label, ...props }) => {
  const translate = useTranslate();
  const { values: { schemaIds } } = useFormState();
  const schemaId = schemaIds?.[type]?.Schedule;
  const { data: schemas } = useQueryWithStore({
    type: 'getMany',
    resource: 'schemas',
    payload: { ids: [ schemaId ] }
  }, {
    enabled: schemaId != null,
    action: 'CUSTOM_QUERY',
  })

  return <>
    <Typography variant="body2" color="textSecondary">{translate(label)}</Typography>
    <RankingInput schema={schemas?.[0]} source={`${source}.${type}`} label={null} {...props} margin="none" />
  </>
}

const SchedulingTab = ({ schedule, ...props }) => {
  return <FormTab label="resources.scheduleSettings.labels.tabs.scheduling" {...props}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <NumberInput
          source="gameLength"
          helperText="resources.scheduleSettings.helpers.gameLength"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SurfaceSizeEnumInput
          multiple
          source="surfaceSizes"
          helperText="resources.scheduleSettings.helpers.surfaceSizes"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <OfficeInput
          source="surfaceOfficeId"
          helperText="resources.scheduleSettings.helpers.surfaceOfficeId"
          filter={{ type: { inq: ['Organization', 'Branch', 'Region', 'District', 'Association', 'Administrative', 'Historical', 'Zone'] } }}
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12}>
        <DraftRequestInput />
      </Grid>
    </Grid>
  </FormTab>
}

const ScoresheetTab = (props) => {
  const translate = useTranslate();
  const isEnabled = useFlag();

  return <FormTab label="resources.scheduleSettings.labels.tabs.scoresheet" {...props}>
    <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.scorekeeping')}</Typography>
    <ScoringModeInput source="scoringMode" helperText="resources.scheduleSettings.helpers.scoringMode" />
    <PenaltyModeInput source="penaltyMode" helperText="resources.scheduleSettings.helpers.penaltyMode" />
    <br />

    <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.lineups')}</Typography>
    <SwitchInput
      source="includeRegistrations"
      helperText="resources.scheduleSettings.helpers.includeRegistrations"
      {...inputProps}
    />
    <SwitchInput
      source="allowExtras"
      helperText="resources.scheduleSettings.helpers.allowExtras"
      {...inputProps}
    />

    {isEnabled(FF_PERIODS) && <>
      <br />
      <Typography variant="subtitle2">{translate('resources.officeSettings.labels.periods')}</Typography>
      <DefaultAlert />
      <PeriodsInput />
    </>}
  </FormTab>
}

const TeamStatsTab = (props) => {
  const translate = useTranslate();
  const isEnabled = useFlag();
  const scheduleSettings = useRecordContext(props);
  const schedule = useSelector(state => state.admin.resources.schedules.data?.[scheduleSettings?.id])

  const isLeague = schedule?.type === 'League';

  return <FormTab label="resources.scheduleSettings.labels.tabs.team_stats" {...props}>
    <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.points')}</Typography>
    <Grid container spacing={2}>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.win"
          label="resources.scheduleSettings.fields.points.Team.win"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.otWin"
          label="resources.scheduleSettings.fields.points.Team.otWin"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.soWin"
          label="resources.scheduleSettings.fields.points.Team.soWin"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.forfeitWin"
          label="resources.scheduleSettings.fields.points.Team.forfeitWin"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.loss"
          label="resources.scheduleSettings.fields.points.Team.loss"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.otLoss"
          label="resources.scheduleSettings.fields.points.Team.otLoss"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.soLoss"
          label="resources.scheduleSettings.fields.points.Team.soLoss"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.forfeitLoss"
          label="resources.scheduleSettings.fields.points.Team.forfeitLoss"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.tie"
          label="resources.scheduleSettings.fields.points.Team.tie"
          helperText=""
          {...inputProps}
        />
      </Grid>
      {isEnabled(FF_DOUBLE_FORFEIT) && <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.forfeitTie"
          label="resources.scheduleSettings.fields.points.Team.forfeitTie"
          helperText=""
          {...inputProps}
        />
      </Grid>}
      <Grid item xs={6} md={3}>
        <NumberInput
          source="maxScoreDiff"
          label="resources.scheduleSettings.fields.maxScoreDiff"
          helperText="ra.message.optional"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <FieldDependency fieldSource="sportsmanshipPoints" test={value => value != 'none'}>
          <NumberInput
            source="points.Team.sportsmanship"
            label="resources.scheduleSettings.fields.points.Team.sportsmanship"
            helperText=""
            {...inputProps}
          />
        </FieldDependency>
      </Grid>
    </Grid>

    <br />
    {isEnabled(FF_SPORTSMANSHIP) && <SportsmanshipPointsInput
      source="sportsmanshipPoints"
      label="resources.scheduleSettings.fields.sportsmanshipPoints"
      helperText="resources.scheduleSettings.helpers.sportsmanshipPoints"
      {...inputProps}
    />}

    {isLeague && <SwitchInput
      source="disableSportsmanshipCarry"
      helperText="resources.scheduleSettings.helpers.disableSportsmanshipCarry"
      {...inputProps}
    />}

    <br />
    <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.standings')}</Typography>
    <StandingsOrderInput source="standingsOrder" type="Team" label="resources.scheduleSettings.labels.standings_order.team" {...inputProps} />

    <br />
    <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.public')}</Typography>
    <HiddenStatsInput
      source="hiddenStats"
      type="Team"
      scope="Schedule"
      label="resources.scheduleSettings.labels.hidden_stats.team"
      {...inputProps}
    />
  </FormTab>
}

const PlayerStatsTab = (props) => {
  const translate = useTranslate();
  const sport = useSport();
  const scheduleSettings = useRecordContext(props);

  const scoreOnly = scheduleSettings?.scoringMode === 'Score';

  return <FormTab label="resources.scheduleSettings.labels.tabs.player_stats" {...props}>
    {!scoreOnly && <>
      <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.points')}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <NumberInput
            source="points.Player.goal"
            label="resources.scheduleSettings.fields.points.Player.goal"
            helperText=""
            min={0}
            {...inputProps}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <NumberInput
            source="points.Player.assist"
            label="resources.scheduleSettings.fields.points.Player.assist"
            helperText=""
            min={0}
            {...inputProps}
          />
        </Grid>
      </Grid>
    </>}

    <br />
    <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.standings')}</Typography>
    <StandingsOrderInput source="standingsOrder" type="Skater" label="resources.scheduleSettings.labels.standings_order.skater" {...inputProps} />
    {sport != 'Baseball' && <StandingsOrderInput source="standingsOrder" type="Goalie" label="resources.scheduleSettings.labels.standings_order.goalie" {...inputProps} />}

    <br />
    <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.public')}</Typography>
    <HiddenStatsInput
      source="hiddenStats"
      type="Skater"
      scope="Schedule"
      label="resources.scheduleSettings.labels.hidden_stats.skater"
      {...inputProps}
    />
    {sport != 'Baseball' && <HiddenStatsInput
      source="hiddenStats"
      type="Goalie"
      scope="Schedule"
      label="resources.scheduleSettings.labels.hidden_stats.goalie"
      {...inputProps}
    />}
  </FormTab>
}

const AdvancedTab = ({ schedule, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (!isAuthorized(schedule, 'schedules', 'internal')) return null;
  return <FormTab className={classes.advancedTab} label="resources.scheduleSettings.labels.tabs.advanced" {...props}>

    <br />
    <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.scheduling')}</Typography>
    <GroupingTypeInput source="groupingType" helperText="resources.scheduleSettings.helpers.groupingType" {...inputProps} />

    <br />
    <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.schedule_stats')}</Typography>
    <SchemaInput
      label="resources.scheduleSettings.labels.stats_schema.team"
      source="schemaIds.Team.Schedule"
      filter={{ type: 'Team', scope: 'Schedule' }}
      {...inputProps}
    />
    <SchemaInput
      label="resources.scheduleSettings.labels.stats_schema.skater"
      source="schemaIds.Skater.Schedule"
      filter={{ type: 'Skater', scope: 'Schedule' }}
      {...inputProps}
    />
    <SchemaInput
      label="resources.scheduleSettings.labels.stats_schema.goalie"
      source="schemaIds.Goalie.Schedule"
      filter={{ type: 'Goalie', scope: 'Schedule' }}
      {...inputProps}
    />

    <br />
    <Typography variant="subtitle2">{translate('resources.scheduleSettings.labels.game_stats')}</Typography>
    <SchemaInput
      label="resources.scheduleSettings.labels.stats_schema.team"
      source="schemaIds.Team.Game"
      filter={{ type: 'Team', scope: 'Game' }}
      {...inputProps}
    />
    <SchemaInput
      label="resources.scheduleSettings.labels.stats_schema.skater"
      source="schemaIds.Skater.Game"
      filter={{ type: 'Skater', scope: 'Game' }}
      {...inputProps}
    />
    <SchemaInput
      label="resources.scheduleSettings.labels.stats_schema.goalie"
      source="schemaIds.Goalie.Game"
      filter={{ type: 'Goalie', scope: 'Game' }}
      {...inputProps}
    />
  </FormTab>
}

export default ({ schedule, ...props }) => {
  return <TabbedForm toolbar={<DialogFormToolbar />} validate={validate} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} syncWithLocation={false} {...props}>
    <SchedulingTab schedule={schedule} />
    <ScoresheetTab />
    <TeamStatsTab />
    <PlayerStatsTab />
    <AdvancedTab schedule={schedule} />
  </TabbedForm>
}
