import React from 'react';
import { Resource, createAdminStore, registerResource } from 'react-admin'

import { isAuthorized } from '../common/Authorize';

import { ScheduleList, ScheduleShow, ScheduleCreate, ScheduleEdit } from './schedules';
import { GameList, GameShow, GameCreate, GameEdit } from './games';
import { PracticeList, PracticeShow, PracticeCreate, PracticeEdit } from './practices';
import { ActivityList, ActivityShow, ActivityCreate, ActivityEdit } from './activities';

import { TeamList, TeamShow, TeamCreate, TeamEdit } from './teams';
import { ParticipantList, ParticipantShow, ParticipantCreate, ParticipantEdit } from './participants';
import { OfficeList, OfficeShow, OfficeCreate, OfficeEdit } from './offices';
import { RulebookCreate, RulebookEdit } from './rulebooks';

import { LeagueList, LeagueShow, LeagueCreate, LeagueEdit } from './leagues';
import { TournamentList, TournamentShow, TournamentCreate, TournamentEdit } from './tournaments';
import { CupList, CupShow, CupCreate, CupEdit } from './cups';
import { VenueList, VenueShow, VenueCreate, VenueEdit } from './venues';
import { SurfaceList, SurfaceShow, SurfaceCreate, SurfaceEdit } from './surfaces';
import { AccountList, AccountShow, AccountCreate, AccountEdit } from './accounts';

import { AnnouncementList, AnnouncementShow, AnnouncementCreate, AnnouncementEdit } from './announcements';
import { PageList, PageShow, PageCreate, PageEdit } from './pages';
import { PostList, PostShow, PostCreate, PostEdit } from './posts';
import { WebEventList, WebEventShow, WebEventCreate, WebEventEdit } from './webevents';
import { ArticleList, ArticleShow, ArticleCreate, ArticleEdit } from './articles';
import { PayPeriodCreate, PayPeriodEdit, PayPeriodShow } from './payPeriods';
import { SuspensionEdit, SuspensionList, SuspensionShow } from './suspensions';

import { AccountIcon, AnnouncementIcon, SurfaceIcon, ArticleIcon, GameIcon, PracticeIcon, ActivityIcon, OfficeIcon, LeagueIcon, TournamentIcon, CupIcon, ScheduleIcon, TeamIcon, ParticipantIcon, PageIcon, PostIcon, WebEventIcon, RulebookIcon, VenueIcon, BalanceIcon, GavelIcon, SportsKabaddiIcon } from './icons';
import { RoleList, RoleShow } from './roles';

export const allResources = [
  <Resource name="schedules" icon={ScheduleIcon} list={ScheduleList} show={ScheduleShow} create={ScheduleCreate} edit={ScheduleEdit} />,
  <Resource name="games" icon={GameIcon} list={GameList} show={GameShow} create={GameCreate} edit={GameEdit} />,
  <Resource name="teams" icon={TeamIcon} list={TeamList} show={TeamShow} create={TeamCreate} edit={TeamEdit} />,
  <Resource name="practices" icon={PracticeIcon} list={PracticeList} show={PracticeShow} create={PracticeCreate} edit={PracticeEdit} />,
  <Resource name="activities" icon={ActivityIcon} list={ActivityList} show={ActivityShow} create={ActivityCreate} edit={ActivityEdit} />,
  <Resource name="participants" icon={ParticipantIcon} list={ParticipantList} show={ParticipantShow} create={ParticipantCreate} edit={ParticipantEdit} />,
  <Resource name="webevents" icon={WebEventIcon} list={WebEventList} show={WebEventShow} create={WebEventCreate} edit={WebEventEdit} />,
  <Resource name="posts" icon={PostIcon} list={PostList} show={PostShow} create={PostCreate} edit={PostEdit} />,
  <Resource name="pages" icon={PageIcon} list={PageList} show={PageShow} create={PageCreate} edit={PageEdit} />,
  <Resource name="leagues" icon={LeagueIcon} list={LeagueList} show={LeagueShow} create={LeagueCreate} edit={LeagueEdit} />,
  <Resource name="tournaments" icon={TournamentIcon} list={TournamentList} show={TournamentShow} create={TournamentCreate} edit={TournamentEdit} />,
  <Resource name="cups" icon={CupIcon} list={CupList} show={CupShow} create={CupCreate} edit={CupEdit} />,
  <Resource name="offices" icon={OfficeIcon} list={OfficeList} show={OfficeShow} create={OfficeCreate} edit={OfficeEdit} />,
  <Resource name="venues" icon={VenueIcon} list={VenueList} show={VenueShow} create={VenueCreate} edit={VenueEdit} />,
  <Resource name="surfaces" icon={SurfaceIcon} list={SurfaceList} show={SurfaceShow} create={SurfaceCreate} edit={SurfaceEdit} />,
  <Resource name="accounts" icon={AccountIcon} list={AccountList} show={AccountShow} create={AccountCreate} edit={AccountEdit} />,
  <Resource name="announcements" icon={AnnouncementIcon} list={AnnouncementList} show={AnnouncementShow} create={AnnouncementCreate} edit={AnnouncementEdit} />,
  <Resource name="help" icon={ArticleIcon} list={ArticleList} show={ArticleShow} create={ArticleCreate} edit={ArticleEdit} />,
  <Resource name="rulebooks" icon={RulebookIcon} create={RulebookCreate} edit={RulebookEdit} />,
  <Resource name="payPeriods" icon={BalanceIcon} show={PayPeriodShow} create={PayPeriodCreate} edit={PayPeriodEdit} />,
  <Resource name="suspensions" icon={GavelIcon} list={SuspensionList} show={SuspensionShow} edit={SuspensionEdit} />,
  <Resource name="roles" icon={SportsKabaddiIcon} list={RoleList} show={RoleShow} />,
  <Resource name="rules" />,
  <Resource name="ruleSections" />,
  <Resource name="ruleOptions" />,
  <Resource name="ruleOptionMembers" />,
  <Resource name="infractions" />,
  <Resource name="infractionTypes" />,
  <Resource name="infractionAccumulations" />,
  <Resource name="infractionAccumulationMembers" />,
  <Resource name="divisions" />,
  <Resource name="classes" />,
  <Resource name="categories" />,
  <Resource name="sections" />,
  <Resource name="profiles" />,
  <Resource name="members" />,
  <Resource name="contacts" />,
  <Resource name="addresses" />,
  <Resource name="registrations" />,
  <Resource name="qualifications" />,
  <Resource name="qualificationCategories" />,
  <Resource name="officialOffices" />,
  <Resource name="officialConflicts" />,
  <Resource name="officialClaims" />,
  <Resource name="officialTransactions" />,
  <Resource name="attributeTypes" />,
  <Resource name="attributeValues" />,
  <Resource name="permissions" />,
  <Resource name="identities" />,
  <Resource name="gameAffiliates" />,
  <Resource name="gameOfficials" />,
  <Resource name="availabilities" />,
  <Resource name="officialAssignments" />,
  <Resource name="availableOfficials" />,
  <Resource name="gameStatuses" />,
  <Resource name="gameAssignSettings" />,
  <Resource name="officeAssignSettings" />,
  <Resource name="assignSystems" />,
  <Resource name="assignFees" />,
  <Resource name="assignRules" />,
  <Resource name="assignTargets" />,
  <Resource name="lists" />,
  <Resource name="listMembers" />,
  <Resource name="gameEvents" />,
  <Resource name="gameMessages" />,
  <Resource name="gameDocuments" />,
  <Resource name="officialClaimDocuments" />,
  <Resource name="gameIncidents" />,
  <Resource name="gamepenalties" />,
  <Resource name="gamePurges" />,
  <Resource name="groups" />,
  <Resource name="pools" />,
  <Resource name="arenaoffices" />,
  <Resource name="arenaslots" />,
  <Resource name="scheduleteams" />,
  <Resource name="schedulesequences" />,
  <Resource name="notifications" />,
  <Resource name="officeSettings" />,
  <Resource name="branchOfficeSettings" />,
  <Resource name="effectiveBranchOfficeSettings" />,
  <Resource name="scheduleSettings" />,
  <Resource name="teamArenas" />,
  <Resource name="teamContacts" />,
  <Resource name="scheduleContacts" />,
  <Resource name="drafts" />,
  <Resource name="draftGames" />,
  <Resource name="draftGameApprovals" />,
  <Resource name="officemembers" />,
  <Resource name="preferences" />,
  <Resource name="schemas" />,
  <Resource name="schedulingConstraints" />,
  <Resource name="sanctions" />,
  <Resource name="sanctionMembers" />,
  <Resource name="sanctionOffenses" />,
  <Resource name="gameBrackets" />,
  <Resource name="externalProviders" />,
  <Resource name="officeClaimsSettings" />,
  <Resource name="suspensionDisciplines" />,
]

export const authResources = scopes =>
  allResources
    .map(Resource => {
      const ResourceType = Resource.type
      const { name: resource, icon, ...props } = Resource.props;

      const allowedProps = ['list', 'show', 'create', 'edit'].reduce((methods, method) => {
        if (props[method] && isAuthorized(scopes, resource, method)) {
          methods[method] = props[method]
        }
        return methods;
      }, {});

      return <ResourceType name={resource} icon={icon} {...allowedProps} />
    }).sort((a, b) => {
      if (a.props.list && !b.props.list) return -1; // a first
      if (!a.props.list && b.props.list) return 1; // b first
      return 0;
    })

// workaround to deal with resources not registering properly
export const registerResources = dispatch => {
  allResources.forEach(resource => {
    dispatch(registerResource({
      name: resource.props.name,
      hasList: false,
      hasEdit: false,
      hasShow: false,
      hasCreate: false,
    }))
  })
}

export const getInitialState = history => {
  const store = createAdminStore({ history });
  registerResources(store.dispatch)
  return store.getState();
}
