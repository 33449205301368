import React, { Fragment, useEffect, useState } from 'react';
import { RecordContextProvider, useTranslate, useRecordContext, BooleanInput, SimpleForm } from 'react-admin';
import {
  styled,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  LinearProgress,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import { BarChart, ExpandMore } from '@material-ui/icons'
import { useFormState } from 'react-final-form';

import { FF_DRAFT_GAMES } from '@hisports/common/featureFlags';
import { isEmpty } from '@hisports/parsers';

import { apiClient, useFlag } from '../../http';
import { ActionMenuItem } from '../../common/ActionMenu';
import DateRangeField from '../../common/fields/DateRangeField';
import FunctionField from '../../common/fields/FunctionField';

import { TeamField } from '../teams/TeamField';
import { OfficeField } from '../offices/OfficeField';
import { GroupField } from '../groups/GroupField';
import { PoolChipField } from '../pools/PoolField';
import { ScheduleGroupInput } from '../groups/GroupInput';
import { PoolInput } from '../pools/PoolInput';
import { useScheduleGroupingType } from '../scheduleSettings';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
}

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: 0,
  overflowX: 'auto',
  overflowY: 'hidden',
})

const ByesList = styled('ul')({
  margin: 0,
  padding: 0,
  listStyleType: 'none',
})

const ByesListItem = styled('li')({
  marginBottom: 4,
})

const useStyles = makeStyles(theme => ({
  draftInput: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  groupInput: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  status: {
    width: '100%',
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  table: {
    // add margin to minimize scrolling
    marginRight: theme.spacing(15),
  },
  officeHeader: {
    width: theme.spacing(3),
    height: theme.spacing(15),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  rotate: {
    transform: 'translate(25px, 58px) rotate(315deg)',
    width: theme.spacing(1.5),
  },
  rotateInner: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(.5),
  },
  officeCell: {
    borderRight: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  link: {
    display: 'inline-block',
    width: theme.spacing(21),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  loaderDiv: {
    height: theme.spacing(0.5)
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const filterMatchupsByPool = (matchups, poolId) => matchups.filter(team => !poolId || team.poolId === poolId);

const Accordion = props => <MuiAccordion TransitionProps={{ unmountOnExit: true }} {...props} />
const Heading = props => <Typography variant="body2" gutterBottom {...props} />

const TeamFieldWithPool = ({ activePoolId }) => {
  const { values: { id: scheduleId } } = useFormState();
  const { isPools } = useScheduleGroupingType(scheduleId);
  const classes = useStyles();

  return <FunctionField render={({ poolId }) => {
    const showPool = isPools && poolId;

    return <div className={classes.group}>
      <Typography variant="body2" display="inline"><TeamField source="teamId" /></Typography>
      {showPool && <PoolChipField source="poolId" variant={activePoolId === poolId ? 'default' : 'outlined'} />}
    </div>
  }} />
}

const Summary = ({ matchups, activePoolId }) => {
  const translate = useTranslate();

  const filteredMatchups = filterMatchupsByPool(matchups, activePoolId);
  const hasGroup = filteredMatchups.some(matchup => matchup.groupId);

  if (isEmpty(filteredMatchups)) return <MuiAccordionDetails>{translate('resources.schedules.messages.no_matchups')}</MuiAccordionDetails>

  return <Table>
    <TableHead>
      <TableRow>
        <TableCell>{translate('resources.schedules.labels.team')}</TableCell>
        {hasGroup && <TableCell>{translate('resources.schedules.labels.group')}</TableCell>}
        <TableCell>{translate('resources.schedules.labels.opponents')}</TableCell>
        <TableCell>{translate('resources.schedules.labels.conflicts')}</TableCell>
        <TableCell>{translate('resources.schedules.labels.byes')}</TableCell>
        <TableCell>{translate('resources.schedules.labels.home')}</TableCell>
        <TableCell>{translate('resources.schedules.labels.away')}</TableCell>
        <TableCell>{translate('resources.schedules.labels.total')}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredMatchups.map(team => {
        const { teamId, home, away, byes, conflicts, totalOpponents, groupId, poolId } = team;

        return <RecordContextProvider key={teamId} value={{ teamId, groupId, poolId }}>
          <TableRow>
            <TableCell component="th" scope="row">
              <TeamFieldWithPool activePoolId={activePoolId} />
            </TableCell>
            {hasGroup && <TableCell>
              <GroupField source="groupId" />
            </TableCell>}
            <TableCell>{totalOpponents}</TableCell>
            <TableCell>{conflicts}</TableCell>
            <TableCell>{byes}</TableCell>
            <TableCell>{home}</TableCell>
            <TableCell>{away}</TableCell>
            <TableCell>{home + away}</TableCell>
          </TableRow>
        </RecordContextProvider>
      })}
    </TableBody>
  </Table>
}

const Matchups = ({ matchups, groupId, activePoolId }) => {
  const translate = useTranslate();

  if (isEmpty(matchups)) return <MuiAccordionDetails>{translate('resources.schedules.messages.no_matchups')}</MuiAccordionDetails>

  const showGroups = groupId != null;
  const teamIds = matchups.map(team => team.teamId);

  return <Table>
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>{translate('resources.schedules.labels.team')}</TableCell>
        {showGroups && <TableCell>{translate('resources.schedules.labels.group')}</TableCell>}
        {teamIds.map((teamId, index) => <TableCell key={`head_${teamId}`}>{index + 1}</TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
      {matchups.map((team, index) => {
        const { teamId, poolId, opponents } = team;

        return <RecordContextProvider key={teamId} value={{ teamId, groupId, poolId }} >
          <TableRow hover>
            <TableCell component="th" scope="row">{index + 1}</TableCell>
            <TableCell><TeamFieldWithPool activePoolId={activePoolId} /></TableCell>
            {showGroups && <TableCell><GroupField source="groupId" /></TableCell>}
            {opponents.map(opponent => {
              const { home, away, teamId: opponentTeamId } = opponent;

              return <TableCell key={`opponent_${opponentTeamId}`}>
                {(home + away === 0) ? null : `${home}/${away}`}
              </TableCell>
            })}
          </TableRow>
        </RecordContextProvider>
      })}
    </TableBody>
  </Table>
}

const OfficeMatchups = ({ matchups, officeIds, activePoolId }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const filteredMatchups = filterMatchupsByPool(matchups, activePoolId);

  if (isEmpty(filteredMatchups)) return <MuiAccordionDetails>{translate('resources.schedules.messages.no_matchups')}</MuiAccordionDetails>

  return <Table className={classes.table}>
    <TableHead>
      <TableRow>
        <TableCell />
        {officeIds.map(officeId => <TableCell key={`head_${officeId}`} className={classes.officeHeader}>
          <div className={classes.rotate}>
            <span className={classes.rotateInner}>
              <RecordContextProvider value={{ officeId }}>
                <OfficeField source="officeId" classes={{ link: classes.link }} />
              </RecordContextProvider>
            </span>
          </div>
        </TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredMatchups.map(team => {
        return <TableRow key={team.teamId} hover>
          <TableCell component="th" scope="row">
            <RecordContextProvider value={team}>
              <TeamFieldWithPool activePoolId={activePoolId} />
            </RecordContextProvider>
          </TableCell>
          {officeIds.map(officeId => <TableCell key={officeId} className={classes.officeCell}>
            {team?.officeMatchups?.[officeId] || 0}
          </TableCell>)}
        </TableRow>
      })}
    </TableBody>
  </Table>
}

const Byes = ({ matchups, byes, activePoolId }) => {
  const translate = useTranslate();

  const teams = matchups.map(team => ({
    teamId: team.teamId,
    poolId: team.poolId,
  }));

  const filteredByesByPoolId = byes.map(week => {
    const teamIds = week.teamIds.filter(teamId => {
      const team = teams.find(team => team.teamId === teamId);
      if (!team) return false;
      return !activePoolId || team.poolId === activePoolId;
    });

    return { ...week, teamIds };
  });

  if (!filteredByesByPoolId?.length || !filteredByesByPoolId.some(week => week.teamIds?.length > 0)) return <MuiAccordionDetails>{translate('resources.schedules.messages.no_byes')}</MuiAccordionDetails>

  return <Table>
    <TableHead>
      <TableRow>
        <TableCell align="center">{translate('resources.schedules.labels.week')}</TableCell>
        <TableCell align="left">{translate('ra.date.name')}</TableCell>
        <TableCell>{translate('resources.schedules.labels.team')}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredByesByPoolId.map(week => {
        const { weekNumber, teamIds } = week;

        return <TableRow key={weekNumber}>
          <TableCell component="th" scope="row" align="center">
            {weekNumber}
          </TableCell>
          <TableCell component="th" scope="row" align="left">
            <RecordContextProvider value={week}>
              <DateRangeField source="startDate" startSource="startDate" endSource="endDate" />
            </RecordContextProvider>
          </TableCell>
          <TableCell>
            {!teamIds?.length
              ? translate('ra.message.none')
              : <ByesList>
                {teamIds.map(teamId => {
                  const poolId = teams.find(team => team.teamId === teamId)?.poolId;
                  return <RecordContextProvider key={teamId} value={{ teamId, poolId }}>
                    <ByesListItem>
                      <TeamFieldWithPool activePoolId={activePoolId} />
                    </ByesListItem>
                  </RecordContextProvider>
                })}
              </ByesList>
            }
          </TableCell>
        </TableRow>
      })}
    </TableBody>
  </Table>
}

const AnalyzeBody = ({ officeId, scheduleId }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values } = useFormState();
  const record = useRecordContext();
  const [analysis, setAnalysis] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { matchups = [], officeMatchups, officeIds, byes } = analysis;
  const { poolId, groupId, includeDraft } = values;
  const { seasonId } = record;

  useEffect(() => {
    setLoading(true);
    apiClient(`/offices/${officeId}/analyze`, {
      method: "GET",
      params: {
        scheduleId,
        groupId,
        includeDraft,
        seasonId
      }
    })
      .then(res => res.data)
      .then(data => setAnalysis(data))
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [ scheduleId, officeId, groupId, includeDraft, seasonId ])

  if (!matchups?.length && loading) return <LinearProgress colour="primary" variant="indeterminate" />
  if (error) return <MuiAccordionDetails>{translate('resources.schedules.messages.unable_to_load')}</MuiAccordionDetails>

  if (!matchups?.length) return <MuiAccordionDetails>{translate('resources.schedules.messages.no_matchups')}</MuiAccordionDetails>
  if (matchups?.length > 200) return <MuiAccordionDetails>{translate('resources.schedules.messages.too_many_matchups')}</MuiAccordionDetails>

  return <>
    <div className={classes.loaderDiv}>
      {loading && <LinearProgress colour="primary" variant="indeterminate" />}
    </div>
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Heading>{translate('ra.action.summary')}</Heading>
      </AccordionSummary>
      <AccordionDetails>
        <Summary matchups={matchups} activePoolId={poolId} />
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Heading>{translate('resources.schedules.labels.matchups')}</Heading>
      </AccordionSummary>
      <AccordionDetails>
        <Matchups matchups={matchups} groupId={groupId} activePoolId={poolId} />
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Heading>{translate('resources.schedules.labels.office_matchups')}</Heading>
      </AccordionSummary>
      <AccordionDetails>
        <OfficeMatchups matchups={officeMatchups} officeIds={officeIds} activePoolId={poolId} />
      </AccordionDetails>
    </Accordion>

    {scheduleId ? <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Heading>{translate('resources.schedules.labels.byes')}</Heading>
      </AccordionSummary>
      <AccordionDetails>
        <Byes matchups={matchups} byes={byes} activePoolId={poolId} />
      </AccordionDetails>
    </Accordion> : null}
  </>
}

const AnalyzeForm = ({ scheduleId }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const isEnabled = useFlag();

  return <>
    {isEnabled(FF_DRAFT_GAMES) && <BooleanInput className={classes.draftInput} source="includeDraft" label="resources.schedules.labels.include_drafts" helperText="" {...inputProps} />}
    <ScheduleGroupInput className={classes.groupInput} source="groupId" scheduleId={scheduleId} defaultValue={null} showNone={translate('resources.groups.messages.all_groups')} {...inputProps} />
    <PoolInput className={classes.groupInput} source="poolId" scheduleId={scheduleId} {...inputProps} />
  </>
}

const AnalyzeDialog = ({ isOpen, handleClose, scheduleId, officeId }) => {
  const translate = useTranslate();

  return <Dialog
    maxWidth="lg"
    fullWidth
    open={isOpen}
    onClose={handleClose}
  >
    <DialogTitle>{translate('resources.schedules.labels.analysis')}</DialogTitle>
    {isOpen && <SimpleForm component={Fragment} resource="schedules" toolbar={null}>
      <AnalyzeForm scheduleId={scheduleId} />
      <AnalyzeBody scheduleId={scheduleId} officeId={officeId} />
    </SimpleForm>}
    <DialogActions>
      <Button color="primary" onClick={handleClose}>{translate('ra.action.close')}</Button>
    </DialogActions>
  </Dialog>
}

export const AnalyzeButton = ({ onClick, size = "small", ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  return <>
    <Button color="primary" size={size} variant="text" startIcon={<BarChart />} onClick={() => setOpen(true)}>
      {translate('ra.action.analyze')}
    </Button>
    {isOpen && <AnalyzeDialog
      isOpen={isOpen}
      handleClose={() => setOpen(false)}
      {...props}
    />}
  </>
}

export const AnalyzeMenuItem = ({ onClick, handleClose: onClose, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  const handleClick = () => {
    onClick();
    setOpen(true);
  }

  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><BarChart fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.offices.labels.analyzeSchedules')}</ListItemText>
    </ActionMenuItem>
    {isOpen && <AnalyzeDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />}
  </>
}
