import React from 'react';
import { useLocale, useRecordContext } from 'react-admin';
import { Grid, useMediaQuery } from '@material-ui/core';

import { FF_DRAFT_GAMES, FF_LEGACY_DRAFTS_DISABLED, FF_POOLS, FF_SEQUENCES } from '@hisports/common/featureFlags';

import { useFlag, useSport } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { TabbedShowLayout, Tab } from '../../common/ra/TabbedShowLayout';
import SchedulerView from '../../common/calendar/scheduler/SchedulerView';
import { useScheduleSettings } from '../scheduleSettings';
import { useBranchSettings } from '../branchOfficeSettings';
import { useScheduleSeeds } from '../gameBrackets/useSeeds';

import ScheduleCard from './cards/ScheduleCard';
import ScheduleLinksCard from './cards/ScheduleLinksCard';
import ScheduleTeamsCard from './cards/TeamsCard';
import DraftsCard from './cards/DraftsCard';
import DraftGamesCard from './cards/DraftGamesCard'
import GamesCard from './cards/GamesCard';
import TeamContactsCard from './cards/TeamContactsCard';
import ScheduleContactsCard from './cards/ScheduleContactsCard';
import ScheduleSettingsCard from './cards/ScheduleSettingsCard';
import NotificationsCard from './cards/NotificationsCard';
import AssignTargetsCard from './cards/AssignTargetsCard';
import RolesCard from './cards/RolesCard';
import SuspensionsCard from './cards/SuspensionsCard';
import GamePenaltiesCard from './cards/GamePenaltiesCard';
import ScheduleSequencesCard from './cards/ScheduleSequencesCard';
import BracketsCard from '../gameBrackets/cards/BracketsCard';
import PlayerScheduleStatsCard from './cards/PlayerScheduleStatsCard';
import TeamScheduleStandingsCard from './cards/TeamScheduleStandingsCard';
import PoolsCard from './PoolsCard';

const SummaryTab = props => {
  const schedule = useRecordContext(props);
  const { data: branchSettings } = useBranchSettings(schedule?.officeId)

  const showSuspensions = isAuthorized(schedule, 'suspensions', 'show') && branchSettings?.ff_suspensions;
  const showGamePenalties = isAuthorized(schedule, 'gamepenalties', 'show')

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <ScheduleCard />
      </Grid>
      <Grid item xs={12} md={4}>
        <ScheduleLinksCard />
      </Grid>
      {showGamePenalties && <Grid item xs={12}>
        <GamePenaltiesCard collapsable />
      </Grid>}
      {showSuspensions && <Grid item xs={12}>
        <SuspensionsCard collapsable />
      </Grid>}
    </Grid>
  </Tab>
}

const TeamsTab = props => {
  const schedule = useRecordContext();
  const isEnabled = useFlag();

  if (!isAuthorized(schedule, "schedules", "teams")) return <div />;

  return <Tab {...props}>
    <Grid container spacing={3}>
      {isEnabled(FF_POOLS) && <Grid item xs={12}>
        <PoolsCard />
      </Grid>}
      <Grid item xs={12}>
        <ScheduleTeamsCard />
      </Grid>
    </Grid>

  </Tab>
}

const GamesTab = props => {
  const schedule = useRecordContext(props);
  const isEnabled = useFlag()

  if (!isAuthorized(schedule, 'games', 'show')) return <div />;

  const showSequences = isAuthorized(schedule, 'schedulesequences', 'show') && isEnabled(FF_SEQUENCES);
  const showDraftGames = isAuthorized(schedule, 'draftGames', 'show') && isEnabled(FF_DRAFT_GAMES);
  const showLegacyDrafts = isAuthorized(schedule, 'drafts', 'show') && !isEnabled(FF_LEGACY_DRAFTS_DISABLED);

  return <Tab {...props}>
    <Grid container spacing={3}>
      {showSequences && <Grid item xs={12}>
        <ScheduleSequencesCard />
      </Grid>}
      {showLegacyDrafts && <Grid item xs={12}>
        <DraftsCard collapsable={isEnabled(FF_DRAFT_GAMES)} />
      </Grid>}
      {showDraftGames && <Grid item xs={12}>
        <DraftGamesCard collapsable />
      </Grid>}
      <Grid item xs={12}>
        <GamesCard />
      </Grid>
    </Grid>
  </Tab>
}

const BracketsTab = props => {
  const schedule = useRecordContext(props);

  /* Used by the bracket system */
  const sport = useSport();
  const locale = useLocale();
  const BRACKET_URL = process.env.BRACKET_URL;
  const isSeeded = useScheduleSeeds(schedule?.id)

  if (!isSeeded || !BRACKET_URL) return <div />;

  const bracketUrl = `${BRACKET_URL}/${schedule?.id}/${sport}/${locale}`

  return <Tab {...props}>
    <BracketsCard url={bracketUrl} scheduleId={schedule?.id} />
  </Tab>
}

const SchedulerTab = props => {
  const schedule = useRecordContext();
  const isEnabled = useFlag()
  const { data: settings } = useScheduleSettings(schedule?.id);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const canSchedule = isAuthorized(schedule, 'schedules', 'scheduler');

  if (!isEnabled(FF_DRAFT_GAMES) || isMobile || !canSchedule) return <div />;

  return <Tab {...props}>
    <SchedulerView schedule={schedule} scheduleSettings={settings} />
  </Tab>
}

const StandingsTab = props => {
  const schedule = useRecordContext(props);

  if (!isAuthorized(schedule, "schedules", "standings")) return <div />;

  return <Tab {...props}>
    <TeamScheduleStandingsCard />
  </Tab>
}

const StatsTab = props => {
  const schedule = useRecordContext(props);

  if (!isAuthorized(schedule, "schedules", "stats")) return <div />;

  return <Tab {...props}>
    <PlayerScheduleStatsCard />
  </Tab>
}

const ContactsTab = props => {
  const schedule = useRecordContext(props);
  const { data: settings } = useScheduleSettings(schedule?.id);

  if (!['schedules', 'teams'].includes(settings?.contactsVisibility)) return <div />;

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TeamContactsCard />
      </Grid>
      <Grid item xs={12}>
        <ScheduleContactsCard />
      </Grid>
    </Grid>
  </Tab>
}

const SettingsTab = props => {
  const schedule = useRecordContext(props);

  if (!isAuthorized(schedule, "schedules", "settings")) return <div />;
  const showTargets = isAuthorized(schedule, 'schedules', 'targets', true);

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <ScheduleSettingsCard />
      </Grid>
      <Grid item xs={0} md={6} />
      <Grid item xs={12}>
        <RolesCard />
      </Grid>
      <Grid item xs={12}>
        <NotificationsCard />
      </Grid>
      {showTargets && <Grid item xs={12}>
        <AssignTargetsCard />
      </Grid>}
    </Grid>
  </Tab>
}

export default props =>
  <TabbedShowLayout {...props}>
    <SummaryTab label="resources.schedules.labels.tabs.summary" />

    <TeamsTab label="resources.schedules.labels.tabs.teams" path="teams" />
    <GamesTab label="resources.schedules.labels.tabs.games" path="games" />
    <BracketsTab label="resources.schedules.labels.tabs.bracket" path="bracket" />
    <SchedulerTab label="resources.schedules.labels.tabs.scheduler" path="scheduler" />
    <StandingsTab label="resources.schedules.labels.tabs.standings" path="standings" />
    <StatsTab label="resources.schedules.labels.tabs.stats" path="stats" />
    <ContactsTab label="resources.schedules.labels.tabs.contacts" path="contacts" />

    <SettingsTab label="resources.schedules.labels.tabs.settings" path="settings" />
  </TabbedShowLayout>

