import React, { Fragment, useState } from 'react';
import { useTranslate, SimpleForm, useNotify } from 'react-admin';
import { Dialog, Button, CircularProgress, DialogActions, ListItemIcon, ListItemText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import arrayMutators from 'final-form-arrays'
import { useFormState } from 'react-final-form';

import { ActionMenuItem } from '../../../common/ActionMenu';
import { useIsOwnParticipant } from '../../../common/hooks/hooks';
import { apiClient } from '../../../http';
import { OfficeIcon } from '../../icons';
import { useOffices } from './offices';
import { OfficeList } from './OfficeList';

const Toolbar = ({ handleSubmit, onClose }) => {
  const translate = useTranslate();
  const { submitting } = useFormState();

  return <DialogActions>
    <Button onClick={onClose}>{translate('ra.action.cancel')}</Button>
    <Button type="submit" color="primary" onClick={handleSubmit} disabled={submitting}>
      {!submitting ? translate('ra.action.save') : translate('resources.participants.labels.availability.saving')}
    </Button>
  </DialogActions>
}

const OfficialAssignersForm = ({ onClose, participantId }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const { assigners, offices, loaded, error } = useOffices()

  if (error) {
    return <Alert severity="error">{translate('ra.page.error_try_again')}</Alert>
  }

  if (!loaded) {
    return <Alert severity="info" icon={<CircularProgress color="secondary" size={22} />}>{translate('ra.page.loading')}</Alert>
  }

  const handleSave = async values => {
    const { officeIds } = values;

    try {
      await apiClient(`/participants/${participantId}/officialSettings`, {
        method: 'PATCH',
        data: {
          officeIds,
        },
      })

      notify('resources.participants.labels.availability.assigners_updated', 'success');
    } catch (error) {
      notify('resources.participants.labels.availability.assigners_error', 'error');
    }

    onClose();
  }

  return <SimpleForm mutators={arrayMutators} component={Fragment} toolbar={<Toolbar onClose={onClose} />} fullWidth save={handleSave}>
    <OfficeList participantId={participantId} assigners={assigners} offices={offices} />
  </SimpleForm>
}

const OfficialAssignersDialog = ({ isOpen, handleClose, participantId }) => {
  return <Dialog
    maxWidth="sm"
    fullWidth
    open={isOpen}
  >
    <OfficialAssignersForm onClose={handleClose} participantId={participantId} />
  </Dialog>
}

export const OfficialAssignersMenuItem = ({ participantId, ...props }) => {
  const translate = useTranslate();
  const isOwnParticipant = useIsOwnParticipant(participantId);
  const [isOpen, setOpen] = useState(false);

  return <>
    <ActionMenuItem color="primary" size="small" onClick={() => setOpen(true)}>
      <ListItemIcon><OfficeIcon fontSize="small" /></ListItemIcon>
      <ListItemText>{translate(`resources.participants.labels.availability.assigner_availabilities.${isOwnParticipant ? 'own_profile' : 'other_profile'}.title`)}</ListItemText>
    </ActionMenuItem>
    {isOpen && <OfficialAssignersDialog isOpen={isOpen} handleClose={() => setOpen(false)} participantId={participantId} {...props} />}
  </>
}
