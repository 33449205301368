import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core';

import Toolbar from '../../common/ra/Toolbar';

import { OfficeInput } from '../offices/OfficeInput';

const validate = values => {
  const errors = {};

  if (!values.name) errors.name = 'ra.validation.required';

  return errors;
}

const inputProps = {
  resource: 'lists',
  basePath: '/lists',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const ListForm = (props) => {
  return <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <TextInput source="name" autoFocus helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <OfficeInput source="officeId" disabled helperText="" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
