import React from 'react';

import { ParticipantField } from '../participants/ParticipantField';
import { OfficeField } from '../offices/OfficeField';
import { OfficialDatagrid } from './OfficialModal';

export const OfficialGrid = props =>
  <OfficialDatagrid size="medium" {...props}>
    <ParticipantField source="participantId" label="resources.officials.fields.participantId" includeId="inline" />
    <OfficeField source="officeId" label="resources.officials.fields.officeId" />
  </OfficialDatagrid>
