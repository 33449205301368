import React from "react"
import { FunctionField, TextField } from "react-admin";

import { NotPublicIcon } from "../icons/NotPublicIcon";
import { SyncedIndicator } from "../SyncAlert";

export const NameWithStatusesField = ({ source = "name" }) => {
  return <FunctionField source={source} render={(record) => {
    return <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
      <TextField source={source} />
      <SyncedIndicator record={record} />
      <NotPublicIcon record={record} />
    </div>
  }} />
}
