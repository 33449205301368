import React from 'react';
import { useTranslate } from 'react-admin';
import { DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogContentText, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, CircularProgress, styled } from '@material-ui/core'
import { Clear } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useForm, useFormState } from 'react-final-form';
import { orderBy } from 'lodash'

import AutocompleteInput from '../../../common/inputs/AutocompleteInput';
import { useIsOwnParticipant } from '../../../common/hooks/hooks';
import { useOfficeSelection } from './offices';

const DialogTitle = styled(MuiDialogTitle)({
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 10,
})

const DialogContent = styled(MuiDialogContent)({
  paddingTop: 0,
})

const Office = ({ office }) => {
  const { mutators } = useForm();
  const { values: { officeIds } } = useFormState();

  const removeOffice = () => {
    const index = officeIds.findIndex(value => value === office.id)
    mutators.remove('officeIds', index)
  }

  return <ListItem dense disablePadding component="li">
    <ListItemText primary={office?.name || 'Unknown'} secondary={office?.parent?.name} />
    <ListItemSecondaryAction>
      <IconButton edge="end" onClick={removeOffice}>
        <Clear />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
}

const Selection = ({ offices }) => {
  const translate = useTranslate();
  const { values: { officeIds } } = useFormState();
  const isOwnParticipant = useIsOwnParticipant();

  if (!officeIds?.length) {
    return <Alert severity="error">
      <AlertTitle>{translate('resources.participants.alerts.availability.no_associations.title')}</AlertTitle>
      <p>{translate(`resources.participants.alerts.availability.no_associations.${isOwnParticipant ? 'own_profile' : 'other_profile'}.search_make_yourself_available`)}</p>
      <p>{translate(`resources.participants.alerts.availability.no_associations.${isOwnParticipant ? 'own_profile' : 'other_profile'}.not_available_to_assigners`)}</p>
    </Alert>
  }

  let selection = officeIds.map(officeId => offices.find(office => office.id === officeId)).filter(Boolean)
  selection = orderBy(selection, ['parent.name', 'name'])

  return <List disablePadding>
    {selection.map(office => {
      return <Office office={office} />
    })}
  </List>
}

const OfficesForm = ({ participantId, offices, assigners }) => {
  const translate = useTranslate();
  const { selection, loaded, error } = useOfficeSelection(participantId);

  if (error) {
    return <Alert severity="error">{translate('ra.page.error_try_again')}</Alert>
  }

  if (!loaded) {
    return <Alert severity="info" icon={<CircularProgress color="secondary" size={22} />}>{translate('resources.participants.alerts.availability.loading_associations')}</Alert>
  }

  const officeIds = assigners.map(assigner => assigner.id);
  return <>
    <AutocompleteInput
      choices={offices}
      defaultValue={selection}
      getOptionDisabled={office => !officeIds.includes(office.id)}
      source="officeIds"
      label="resources.participants.labels.availability.add_associations"
      placeholder="resources.participants.messages.availability.search_by_association"
      emptyOptionsText="resources.participants.messages.availability.enter_name_search"
      multiple
      hideDisabled
      disableClearable
      minFilterLength={3}
      groupBy={office => office.parent?.name || translate('ra.message.unknown')}
      variant="outlined"
      margin="normal"
      fullWidth
      disableRemove
      renderTags={() => null}
    />
    <Selection offices={offices} />
  </>
}

export const OfficeList = ({ participantId, offices, assigners }) => {
  const translate = useTranslate();
  const isOwnParticipant = useIsOwnParticipant(participantId);

  return <>
    <DialogTitle>{translate(`resources.participants.labels.availability.assigner_availabilities.${isOwnParticipant ? 'own_profile' : 'other_profile'}.title`)}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate(`resources.participants.labels.availability.assigner_availabilities.${isOwnParticipant ? 'own_profile' : 'other_profile'}.select_associations`)}
      </DialogContentText>
      <OfficesForm participantId={participantId} offices={offices} assigners={assigners} />
    </DialogContent>
  </>
}
