import React from 'react';
import { LinearProgress, RecordContextProvider, useTranslate } from 'react-admin';
import { Table, TableBody } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { useOfficeSettingsWithStore } from '.';

import Row from '../../common/cards/TableRow';
import FunctionField from '../../common/fields/FunctionField';
import { useSport } from '../../http';

const renderPoints = (settings, source, resource, translate) => {
  const { win, loss, tie } = settings.points.Team
  const result = translate('resources.officeSettings.fields.points.preview', { win, loss, tie })

  return result;
}

const renderStandingOrder = (settings, source, resource, translate) => {
  const standingsOrder = settings?.standingsOrder[source];

  if (isEmpty(standingsOrder)) return '-';

  return <ul style={{ listStyleType: 'decimal', paddingLeft: '15px', margin: 0 }}>
    {standingsOrder.map((standing) => (
      <li key={standing.property}>
        {standing.property ? `${translate(`resources.officeSettings.labels.standings_order.${standing.property}`)} ` : ''}
        {standing.order}
        {standing.type ? ` (${translate(`resources.officeSettings.labels.standings_type.${standing.type}`)})` : ''}
      </li>
    ))}
  </ul>
};

const renderEnforcement = (settings, source, resource, translate) => {
  const enforcement = settings[source];
  if (!enforcement) return '-';

  const enforcementValue = translate(`resources.officeSettings.values.enforcement.${enforcement.toLowerCase()}`)

  return <div>{translate(`resources.officeSettings.fields.enforcements.preview.${source}`, { enforcementValue })}</div>
};

const renderReports = (settings, source, resource, translate) => {
  const reports = settings[source];
  if (isEmpty(reports)) return '-';

  const values = reports.map((value) => {
    return translate(`resources.officeSettings.values.reports.${value.toLowerCase()}`)
  })

  return values.join(', ')
};

export const OfficeSettingsField = ({ officeId }) => {
  const translate = useTranslate();
  const sport = useSport();
  const { data: officeSettings, loading, error } = useOfficeSettingsWithStore(officeId);

  if (loading) return <LinearProgress />;
  if (error) return translate('resources.officeSettings.messages.load_error');

  return (
    <RecordContextProvider value={officeSettings}>
      <Table>
        <TableBody>
          <Row label="resources.officeSettings.labels.points_system">
            <FunctionField source="points" render={renderPoints} />
          </Row>
          {!!officeSettings?.standingsOrder?.Team?.length && <Row label="resources.officeSettings.labels.standings_order.team">
            <FunctionField source="Team" render={renderStandingOrder} />
          </Row>}
          {!!officeSettings?.standingsOrder?.Skater?.length && <Row label="resources.officeSettings.labels.standings_order.skater">
            <FunctionField source="Skater" render={renderStandingOrder} />
          </Row>}
          {sport !== 'Baseball' && !!officeSettings?.standingsOrder?.Goalie?.length && <Row label="resources.officeSettings.labels.standings_order.goalie">
            <FunctionField source="Goalie" render={renderStandingOrder} />
          </Row>}
          <Row label="resources.officeSettings.labels.conflict_enforcement">
            <FunctionField source="conflictEnforcement" render={renderEnforcement} />
            <FunctionField source="availabilityEnforcement" render={renderEnforcement} />
            <FunctionField source="constraintEnforcement" render={renderEnforcement} />
          </Row>
          {!!officeSettings?.adminReports?.length && <Row label="resources.officeSettings.labels.admin_reports">
            <FunctionField source="adminReports" render={renderReports} />
          </Row>}
          {!!officeSettings?.teamReports?.length && <Row label="resources.officeSettings.labels.team_reports">
            <FunctionField source="teamReports" render={renderReports} />
          </Row>}
        </TableBody>
      </Table>
    </RecordContextProvider>
  );
};
