import React, { cloneElement, useState, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { Box, Tabs, Tab, makeStyles, styled } from "@material-ui/core"
import { Alert as MuiAlert } from '@material-ui/lab'

import { useAssignmentContext } from '../AssignmentContext';
import { filterFlags, isAssigned } from '../util';

import SummaryTab from './SummaryTab';
import ScheduleTab from './ScheduleTab';
import HistoryTab from './HistoryTab';
import ContactsTab from './ContactsTab';
import Suggestions from './Suggestions';

const Alert = styled(MuiAlert)({
  margin: 16,
})

const TabPanel = ({ children, value, index, padding = 1, ...other }) => {
  const ref = useRef(null);
  return <div
    ref={ref}
    role="tabpanel"
    hidden={value !== index}
    {...other}
  >
    {value === index && <Box padding={padding}>
      {cloneElement(children, { panelRef: ref })}
    </Box>}
  </div>
}

const useStyles = makeStyles(theme => ({
  menu: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(6),
    },
  },
  panel: {
    overflow: 'auto',
    flexGrow: 1,
    maxHeight: theme.spacing(42 - 6),
    width: '100%',
  },
  message: {
    padding: theme.spacing(1, 3),
  },
}))

export default () => {
  const { assignment, meta, selectedId, official, filters } = useAssignmentContext();
  const classes = useStyles();
  const translate = useTranslate();

  const [ currentTab, setCurrentTab ] = useState(0);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  }

  const { canAssign, canRequest, isUnassigned, isAssignedSelf, isOfficialSelf } = meta;

  if (!canAssign) {
    if (!isUnassigned && !isAssignedSelf) return <Alert severity="warning">
      {translate('resources.games.alerts.assignment.this_position_already_assigned')}
    </Alert>

    if (canRequest && official) {
      const flags = filterFlags(official.flags, assignment.position, filters, official.meta);
      const assigned = isAssigned(flags);

      if (!isOfficialSelf && assigned) return <Alert severity="warning">
        {translate('resources.games.alerts.assignment.already_assigned_to_game')}
      </Alert>
    }
  }

  if (canAssign && !selectedId) return <Suggestions />

  if (!official) return <Alert severity="warning">
    {translate('resources.games.alerts.assignment.official_details_error')}
  </Alert>

  return <>
    <Tabs
      className={canAssign ? classes.menu : undefined}
      variant="scrollable"
      scrollButtons="auto"
      value={currentTab}
      onChange={handleTabChange}
    >
      <Tab label={translate('ra.action.summary')} />
      <Tab label={translate('resources.schedules.name', 1)} />
      <Tab label={translate('resources.games.labels.assignment.history')} />
      <Tab label={translate('resources.contacts.name', 1)} />
    </Tabs>
    <TabPanel value={currentTab} index={0} className={classes.panel} padding={2}>
      <SummaryTab role={canAssign && !isOfficialSelf ? 'assigner' : 'assignee'} />
    </TabPanel>
    <TabPanel value={currentTab} index={1} className={classes.panel} padding={0}>
      <ScheduleTab />
    </TabPanel>
    <TabPanel value={currentTab} index={2} className={classes.panel} padding={0}>
      <HistoryTab />
    </TabPanel>
    <TabPanel value={currentTab} index={3} className={classes.panel} padding={0}>
      <ContactsTab />
    </TabPanel>
  </>
}
