import React, { memo, useMemo, useRef, useState } from 'react';
import { DatagridRow, RecordContextProvider, useListContext, useNotify, useRecordContext, useTranslate } from 'react-admin';
import { Typography, Card, Table, TableBody, TableRow, TableCell, makeStyles, Checkbox, darken, Grid, IconButton, Tooltip, Box, Popover as MuiPopover, Divider, useTheme } from '@material-ui/core';
import { Check, DoneAll, EventBusy, Flag as FlagIcon, Event as EventIcon } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { useSelector } from 'react-redux';
import { groupBy, orderBy } from 'lodash';
import moment from 'moment-timezone'

import { dedupe, isEmpty } from '@hisports/parsers';

import { usePermissions, useScopes } from '../../http';
import DateField from '../../common/fields/DateField';
import TimeRangeField from '../../common/fields/TimeRangeField';
import ConfirmDialog from '../../common/dialogs/ConfirmDialog';
import { useSchedulingContext } from '../../common/calendar/SchedulingContext';
import { isDraft, isGame } from '../../common/calendar/EventDetails';
import { validateEvent } from '../../common/calendar/DragAndDropGrid';
import { hasAnyScope } from '../../common/Authorize';

import { SurfaceField } from '../surfaces/SurfaceField';
import { ScheduleField } from '../schedules/ScheduleField';
import { GameStatusField } from '../gameStatus';

import { DescriptionField, LocationField } from './EventGrid';
import { useGroupDates, useGroupArenas, useGroupRounds, useShowAssignments, useShowFlags, CALENDAR_VIEWS, useCalendarView } from './EventViewSettings';
import { EventAssignmentList } from './EventAssignment';
import { DraftGameActions } from '../draftgames/DraftGameActions';

// const stripes = (r, g, b) => `repeating-linear-gradient(
//   -55deg,
//   rgba(${r}, ${g}, ${b}, .015),
//   rgba(${r}, ${g}, ${b}, .015) 8px,
//   rgba(${r}, ${g}, ${b}, .030) 8px,
//   rgba(${r}, ${g}, ${b}, .030) 16px
// )`

// const getStripes = game => {
//   if (!game || game.isApproved) return
//   switch (game.status) {
//     case 'Cancelled':
//     case 'Conflict':
//       return stripes(244, 67, 54);
//     case 'Postponed':
//       return stripes(255, 245, 59)
//     default:
//       return;
//   }
// }

const useEventStyles = makeStyles(theme => ({
  root: {
    // background: game => getStripes(game),
    '& .column-date': {
      width: theme.spacing(10),
    },
    '& .column-time': {
      width: theme.spacing(10),
    },
    '& .column-arenaId': {
      width: theme.spacing(40),
    },
    '& .column-status': {
      width: theme.spacing(10),
      textAlign: 'right',
    },
  },
  selected: {
    backgroundColor: theme.palette.primary[50],
  },
  icon: {
    color: theme.palette.success.main,
  },
  selectedTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    marginLeft: 'auto'
  },
  divider: {
    marginBlock: theme.spacing(1)
  }
}))

const useInfoStyles = makeStyles(theme => ({
  row: {
    backgroundColor: '#fdfdfd',
    boxShadow: '0 5px 8px #eee inset',
    '& + $row': {
      boxShadow: 'none',
    }
  },
  column: {
    padding: theme.spacing(1),
  },
}))

const useFlagStyles = makeStyles(theme => ({
  column: {
    padding: theme.spacing(1, 1, 1, .5),
  },
  flag: {
    marginRight: theme.spacing(2),
    paddingLeft: 0,
    fontSize: '.95em',
    whiteSpace: 'nowrap',
  },
  label: {
    fontStyle: 'italic',
    color: theme.palette.grey[500],
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    verticalAlign: 'middle',
    paddingRight: theme.spacing(1)
  },
}))

export const useLimitDateChange = (draggingGame) => {
  const { selectedGame, limitDateChange } = useSchedulingContext();
  const permissions = usePermissions();

  const game = draggingGame || selectedGame;

  if (!isDraft(game) || !game?.isShared || !limitDateChange) return false

  const hasAccess = permissions.some(p =>
    p.roleType === 'System' ||
    (
      hasAnyScope(p.scopes, ['leagues:manage'])
      && p.roleType === 'Office'
      && !p.inherited
      && (p.officeIds || []).includes(game.officeId)
    )
  );

  return !hasAccess;
}

const Flag = ({ event, flag, ...props }) => {
  const classes = useFlagStyles();
  const translate = useTranslate();

  const label = [translate(`resources.games.labels.flags.${flag}`, 2)];

  return <span className={classes.flag}>
    <span className={classes.icon}>
      <FlagIcon fontSize="small" />
    </span>
    <span>{label}</span>
  </span>
}

export const ScheduleButton = ({ size = 'medium', ...props }) => {
  const [ datePickerOpen, setDatePickerOpen ] = useState(false);
  const anchorRef = useRef();
  const record = useRecordContext();
  const translate = useTranslate();
  const notify = useNotify()
  const { setSelectedGame, onSave, schedule } = useSchedulingContext();
  const disableDateChange = useLimitDateChange();

  if (disableDateChange) return null;

  const handleDateChange = async (date) => {
    if (!onSave || !record || !date) return;
    const newDate = date.format('YYYY-MM-DD');

    const { grouping, ...newGame } = record;
    const newEvent = { ...newGame, date: newDate }

    const error = validateEvent(newEvent, schedule, translate);
    if (error) {
      return notify(error, 'error');
    }

    await onSave({ ...newGame, date: newDate });
    setSelectedGame((selectedGame) => ({
      ...selectedGame,
      date: newDate,
    }));

    setDatePickerOpen(false);
  };

  const onClick = (e) => {
    e.stopPropagation();
    setDatePickerOpen(true);
  }

  return (
    <>
      <Tooltip title={translate('components.calendar.tooltips.schedule')}>
        <IconButton size={size} onClick={onClick} ref={anchorRef}>
          <EventIcon />
        </IconButton>
      </Tooltip>
      <MuiPopover
        open={datePickerOpen}
        anchorEl={anchorRef.current}
        onClose={() => setDatePickerOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClick={e => e.stopPropagation()}
      >
        <DatePicker
          autoOk
          variant="static"
          openTo="date"
          value={new Date()}
          onChange={handleDateChange}
        />
      </MuiPopover>
    </>
  )
}

export const UnscheduleButton = ({ size = 'medium', ...props }) => {
  const translate = useTranslate();
  const record = useRecordContext(props);
  const { setSelectedGame, onSave, setRefreshAvailability } = useSchedulingContext();
  const disableDateChange = useLimitDateChange();
  const [ view ] = useCalendarView();
  const [ open, setOpen ] = useState(false);

  if (disableDateChange) return null;

  const handleConfirm = async (e) => {
    e.stopPropagation();
    if (!onSave || !record) return;
    // sanitize and save record
    const { grouping, ...newGame } = record;
    await onSave({ ...newGame, date: null });
    // update selected game
    setSelectedGame((selectedGame) => ({
      ...selectedGame,
      date: null,
    }));

    if (view === CALENDAR_VIEWS.SEASON) {
      setRefreshAvailability(true)
    }
  }

  const onClick = (e) => {
    e.stopPropagation();
    setOpen(!open)
  }

  return <>
    <Tooltip title={translate('components.calendar.tooltips.unschedule')}>
      <IconButton size={size} onClick={onClick}>
        <EventBusy fontSize="medium" />
      </IconButton>
    </Tooltip>
    <ConfirmDialog
      title={translate('components.calendar.labels.unschedule')}
      text={translate('components.calendar.messages.remove_date')}
      open={open}
      setOpen={setOpen}
      handleConfirm={handleConfirm}
    />
  </>
}

export const AssignmentsRow = ({ event, columns, offset = 1 }) => {
  const classes = useInfoStyles();

  const scopes = useScopes();
  const gameStatus = useSelector(state => event && state.admin.resources.gameStatuses.data[event.id])

  if (!scopes.includes('assigning:assign')) return null;
  if (!gameStatus || !gameStatus.officials) return null;

  const { assignments, officeId, status: assignSettingStatus } = gameStatus.officials;
  if (!assignments || !assignments.length) return null;

  return <TableRow className={classes.row}>
    {offset >= 1 && <TableCell />}
    {offset >= 2 && <TableCell />}
    <TableCell colSpan={Number(columns) - 1} className={classes.column}>
      <RecordContextProvider value={event}>
        <EventAssignmentList assignments={assignments} mainAssignOfficeId={officeId} assignSettingStatus={assignSettingStatus} />
      </RecordContextProvider>
    </TableCell>
  </TableRow>
}

export const FlagsRow = ({ event, columns, offset = 1 }) => {
  const baseClasses = useInfoStyles();
  const classes = useFlagStyles();

  const status = useSelector(state => event && state.admin.resources.gameStatuses.data?.[event.id])
  if (!status || isEmpty(status.flags)) return null;

  const flags = dedupe(status.flags.map(flag => flag.name));

  return <TableRow className={baseClasses.row}>
    {offset >= 1 && <TableCell />}
    {offset >= 2 && <TableCell />}
    <TableCell colSpan={Number(columns) - 1} className={classes.column}>
      {flags.map(flag => <Flag event={event} flag={flag} />)}
    </TableCell>
  </TableRow>
}

const DraggableEvent = memo(({ isScheduled, groupByDate, hideActions = false, showPools = false, actions, classes }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const { selectedGame, showSchedule } = useSchedulingContext();
  const eventClasses = useEventStyles();
  const theme = useTheme();

  const showChecked = isScheduled;
  const showClear = !hideActions && !isScheduled && record?.date && selectedGame?.id === record?.id;
  const showCalendar = !hideActions && !isScheduled && !record?.date;
  const showDate = (actions?.length && selectedGame?.id === record?.id) || !groupByDate

  const CheckIcon = isGame(record) ? DoneAll : Check

  return <Grid container fullWidth alignItems="center">
    {actions?.length && <Grid item xs={12}>
      <div className={eventClasses.selectedTitle}>
        <Typography variant="subtitle2">{translate('components.calendar.labels.selected_game')}</Typography>
        <div className={eventClasses.actions}>{actions.map(action => action)}</div>
      </div>
      <Divider className={classes.divider} />
    </Grid>}
    <Grid item xs>
      <DescriptionField source="homeTeamId" authMethod="gameTeams" link={false} showTime showDate={showDate} showPools={showPools} padHeader />
      <SurfaceField source="arenaId" emptyText={`(${translate('resources.drafts.labels.no_venue')})`} link={false} includeAttributes="inline" />
      {showSchedule && <div style={{ paddingTop: theme.spacing(1.5) }}>
        <ScheduleField source="scheduleId" link={false} includeCategory="inline" />
      </div>}
    </Grid>
    {!actions?.length && showCalendar && <Grid item xs={2} style={{ textAlign: "end" }}>
      <ScheduleButton />
    </Grid>
    }
    {showChecked && <Grid item xs={2} style={{ textAlign: "end" }}>
      <Box padding={1}><CheckIcon className={classes.icon} /></Box>
    </Grid>}
    {!actions?.length && showClear && <Grid item xs={2} style={{ textAlign: "end" }}>
      <UnscheduleButton />
    </Grid>}
  </Grid>
})

export const DraggableEventRow = memo(({ event, groupByDate, groupByArena, isRowSelectable, hideTime, hideArena, hideStatus, hideActions, showPools, dragComponent: DragComponent, actions, ...props }) => {
  const classes = useEventStyles();
  const { selectedGame } = useSchedulingContext();

  const isSelected = selectedGame?.id === event.id;
  const isScheduled = event.startTime != null && event.endTime != null && event.arenaId != null;
  const hasDate = !!event?.date

  let className = classes.root;
  if (isSelected) {
    className = `${className} ${classes.selected}`;
  }

  return <DatagridRow {...props} record={event} id={event.id} className={className} hover={!isSelected}>
    <DragComponent event={event} draggable={isSelected && (!isScheduled || !hasDate)}>
      <DraggableEvent isScheduled={isScheduled} classes={classes} actions={actions} hideActions={hideActions} groupByDate={groupByDate} showPools={showPools} />
    </DragComponent>
  </DatagridRow>
})

const EventRow = memo(({ event, groupByDate, groupByArena, isRowSelectable, hideGroup = false, hideStatus, hideNumber = false, showDraftActions = false, showPools, team, rowClick = 'show', ...props }) => {
  const translate = useTranslate();
  const { selectedIds, onToggleItem } = useListContext();
  const [ showAssignments ] = useShowAssignments();
  const [ showFlags ] = useShowFlags();
  const classes = useEventStyles(event);

  const selected = selectedIds.includes(event.id);
  const selectable = !isRowSelectable || isRowSelectable(event.id)

  const style = { cursor: rowClick ? 'pointer' : 'default', ...props.style }
  const columns = 5 + (groupByArena ? 0 : 1) + (props.hasBulkActions ? 1 : 0);

  return <>
    <DatagridRow {...props} record={event} id={event.id} style={style} rowClick={rowClick} className={classes.root} selected={selected} selectable={selectable} onToggleItem={onToggleItem}>
      {!groupByDate && <DateField source="date" label="ra.date.name" emptyText={`(${translate('resources.drafts.labels.no_date')})`} />}
      <TimeRangeField source="time" startSource="startTime" endSource="endTime" emptyText={`(${translate('resources.drafts.labels.no_time')})`} />
      <DescriptionField source="homeTeamId" authMethod="gameTeams" team={team} hideGroup={hideGroup} hideNumber={hideNumber} showPools={showPools} />
      {!groupByArena && <LocationField source="arenaId" emptyText={`(${translate('resources.drafts.labels.no_venue')})`} />}
      {!hideStatus && <GameStatusField source="status" />}
      {showDraftActions && <DraftGameActions />}
    </DatagridRow>

    {showAssignments && <AssignmentsRow event={event} columns={columns} offset={1} />}
    {showFlags && <FlagsRow event={event} columns={columns} offset={1} />}
  </>
})

export const SelectAllCheckbox = ({ ids, hasBulkActions, isRowSelectable, className, disableCheckboxes, ...props }) => {
  const { selectedIds, onSelect } = useListContext(props);
  if (!hasBulkActions) return null;

  const all = isRowSelectable ? ids.filter(id => isRowSelectable(id)) : ids
  if (!all.length) return null;

  const checked = selectedIds.length > 0 && all.length > 0 && all.every(id => selectedIds.includes(id))

  const handleSelectAll = event => {
    if (event.target.checked) {
      let selection = ids
        .filter(id => !selectedIds.includes(id))
        .concat(selectedIds)

      if (isRowSelectable) {
        selection = selection.filter(id => isRowSelectable(id))
      }

      onSelect(selection)
    } else {
      const selection = selectedIds
        .filter(id => !ids.includes(id))

      onSelect(selection);
    }
  }

  return <Checkbox color="primary" checked={checked} onChange={handleSelectAll} className={className} />
}

const useGroupStyles = makeStyles(theme => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1, // neccesary for game buttons
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    height: props => theme.spacing(props.level ? 6 : 7),
    backgroundColor: props => darken(theme.palette.background.default, props.level * 0.025)
  },
  checkbox: {
    display: 'inline-block',
    minWidth: theme.spacing(3),
    paddingLeft: 0,
    marginLeft: -3,
  },
  heading: {
    paddingLeft: props => theme.spacing(props.level + 1),
  },
}))

const EventGroup = ({ group, dragComponent, ...props }) => {
  const { level, heading, showHeading, ids, data: events } = group;
  const classes = useGroupStyles({ level });

  const Row = dragComponent ? DraggableEventRow : EventRow

  return <div>
    {showHeading && <div className={classes.header}>
      <SelectAllCheckbox {...props} className={classes.checkbox} ids={ids} />
      <Typography variant="subtitle2" display="inline" className={classes.heading}>{heading}</Typography>
    </div>}
    <Table size="medium">
      <TableBody>
        {events?.map(event => <Row key={event.id} {...props} event={event} dragComponent={dragComponent} />)}
      </TableBody>
    </Table>
  </div>
}

const useGroupingStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
}))

export default ({ component: Component = Card, className, ...props }) => {
  const translate = useTranslate();
  const classes = useGroupingStyles();
  const [ groupRounds ] = useGroupRounds();
  const [ groupDates ] = useGroupDates();
  const [ groupArenas ] = useGroupArenas();
  const { ids: listIds = [], data: listData } = useListContext(props);

  const ids = useMemo(() => {
    return listIds;
  }, [listIds])
  const data = useMemo(() => {
    return listData
  }, [listData])

  // combine event view settings and prop, allow prop disabling with false
  const groupByRound = props.groupByRound !== false && (props.groupByRound || groupRounds);
  const groupByDate = props.groupByDate !== false && (props.groupByDate || groupDates);
  const groupByArena = props.groupByArena !== false && (props.groupByArena || groupArenas);

  // generate grouped object with the following key pattern: "{round}+{date}" ex. '1+2022-11-01', '1+2022-11-02', '1+_TBD_'
  const groups = useMemo(() => {
    if (!ids?.length) return;

    const TBD = '_TBD_';
    const SPLIT = '+'
    const types = [];
    if (groupByRound) types.push('round')
    if (groupByDate) types.push('date')
    if (groupByArena) types.push('arena')

    const events = ids.map(id => data?.[id]).filter(Boolean);
    const groupedEvents = groupBy(events, event => {
      const groups = []
      if (groupByRound) groups.push(event.round || TBD)
      if (groupByDate) groups.push(event.date ? moment.tz(event.date, event.timezone).format('YYYY-MM-DD') : TBD)
      if (groupByArena) groups.push(event.arenaId || TBD)

      return groups.join(SPLIT);
    })

    const groupKeys = Object.keys(groupedEvents);

    let groups = groupKeys.flatMap((groupKey, index, groupKeys) => {
      const prevGroupKey = groupKeys[index - 1] || ''
      const prevGroupValues = prevGroupKey.split(SPLIT) || [];
      const groupValues = groupKey.split(SPLIT)
      return groupValues.map((value, level) => {
        let heading = null;
        switch (types[level]) {
          case 'round': {
            if (value === TBD) {
              heading = `(${translate('resources.drafts.labels.no_round')})`;
            } else {
              heading = `${translate('resources.drafts.labels.round', 1)} ${value}`;
            }
            break;
          }
          case 'date': {
            const date = moment.utc(value, 'YYYY-MM-DD')
            if (value === TBD || !date.isValid()) {
              heading = `(${translate('resources.drafts.labels.no_date')})`
            } else {
              heading = date.format('dddd, LL')
            }
            break;
          }
          case 'arena': {
            if (value === TBD) {
              heading = `(${translate('resources.drafts.labels.no_venue')})`;
            } else {
              heading = <RecordContextProvider value={{ arenaId: value }}>
                <SurfaceField source="arenaId" link={false} includeAttributes="inline" includeCity="inline" />
              </RecordContextProvider>
            }
            break;
          }
        }

        const criteria = types.filter((type, typeIndex) => typeIndex <= level)
        const events = ids.map(id => data?.[id]).filter(event => {
          if (!event) return false;
          return criteria.every(type => {
            const typeIndex = types.findIndex(t => t === type);
            const groupValue = groupValues[typeIndex];

            switch (type) {
              case 'round': {
                if (groupValue === TBD) return !event.round;
                return event.round == groupValue;
              }
              case 'date': {
                const date = moment.utc(groupValue, 'YYYY-MM-DD')
                if (!date.isValid()) return !event.date
                return date.format('YYYY-MM-DD') == moment.utc(event.date, 'YYYY-MM-DD').format('YYYY-MM-DD')
              }
              case 'arena': {
                if (groupValue === TBD) return !event.arenaId;
                return event.arenaId == groupValue;
              }
            }
            return false;
          })
        })

        const groupKey = groupValues.slice(0, level + 1).join(SPLIT);
        return {
          groupKey,
          topGroup: groupValues[0],
          level,
          heading,
          showHeading: groupKey != prevGroupValues.slice(0, level + 1).join(SPLIT),
          data: level === (types.length - 1) || types.length === 0 ? events : [],
          ids: events.map(event => event.id),
        }
      })
    })

    // maintain sort order from context
    groups = orderBy(groups, group => ids.indexOf(group.ids[0]))
    const topGroups = groupBy(groups, 'topGroup')
    const topGroupKeys = orderBy(Object.keys(topGroups), topGroup => groups.findIndex(group => group.topGroup === topGroup))
    return topGroupKeys.map(key => topGroups[key])
  }, [ translate, ids, data, groupByRound, groupByDate, groupByArena ])

  if (!groups?.length) return null;
  return <div className={className}>
    {groups.map(groups => {
      const key = groups?.[0]?.topGroup;
      return <Component className={classes.root} key={key}>
        {groups.map(group => <EventGroup key={group.groupKey} {...props} group={group} groupByDate={groupByDate} groupByArena={groupByArena} />)}
      </Component>
    })}
  </div>
}
