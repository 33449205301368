import { useQueryWithStore, GET_ONE } from 'react-admin';
import { useSelector } from 'react-redux';

export const useScheduleSettings = scheduleId =>
  useQueryWithStore({
    type: GET_ONE,
    resource: 'scheduleSettings',
    payload: { id: scheduleId },
  })

// force check in cache (use with caution, data may have changed since last load)
export const useScheduleSettingsWithStore = (scheduleId, enabled = true) => {
  const scheduleSettingsInStore = useSelector(state => state?.admin?.resources?.scheduleSettings?.data?.[scheduleId])

  const { data, loading, loaded, error } = useQueryWithStore({
    type: GET_ONE,
    resource: 'scheduleSettings',
    payload: { id: scheduleId },
  }, {
    enabled: !scheduleSettingsInStore && !!scheduleId && !!enabled
  })
  return { data: scheduleSettingsInStore || data, loading, loaded, error };
}

export const useScheduleGroupingType = scheduleId => {
  const { data: scheduleSettings, loading, loaded, error } = useScheduleSettingsWithStore(scheduleId);

  return {
    isPools: scheduleSettings?.groupingType === 'Pools',
    isGroups: scheduleSettings?.groupingType === 'Groups',
    loading,
    loaded,
    error,
  }
}
