import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, IconButton, Tooltip, Badge, useMediaQuery, ClickAwayListener } from '@material-ui/core';
import { FilterList, Menu, MenuOpen } from '@material-ui/icons';
import cn from 'classnames';

import { isIneligible, isAssigned, isUnavailable, isUnregistered, filterFlags, isNew, isLocal, isExpired, hasMatchingAttributeValues, hasAttributeValuesFilter } from '../util';
import { useAssignmentContext } from '../AssignmentContext';

import { SearchInput, useSearch, OfficialFilters } from './OfficialFilters';
import { OfficialList } from './OfficialList';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(38),
    minWidth: theme.spacing(38),
    maxHeight: theme.spacing(42),
    position: 'relative',
  },
  open: {
    position: 'absolute',
    zIndex: 10,
    backgroundColor: theme.palette.common.white,
    boxShadow: '10px 0px 5px -5px #ddd',
  },
  closed: {
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    width: 'calc(100% - 16px)',
    paddingTop: theme.spacing(.5),
  },
  filters: {
    display: 'flex',
    height: theme.spacing(7),
  },
  search: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  filtersPanel: {
    position: 'absolute',
    top: theme.spacing(6),
    maxHeight: theme.spacing(32),
    overflowY: 'auto',
    zIndex: 1,
    backgroundColor: `#fff`,
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    boxShadow: '0 15px 20px -15px #000',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: theme.spacing(20),
  },
  panel: {
    overflow: 'auto',
    flexGrow: 1,
    maxHeight: theme.spacing(42),
    width: '100%',
  },
}))

export default () => {
  const { assignment, allOfficials, lists, attributeTypes, isMenuOpen, toggleMenu, closeMenu, selectOfficial, filters, isFilterMenuOpen, toggleFilter, toggleFilterMenu } = useAssignmentContext()
  const { search, onSearch, onClear } = useSearch();
  const small = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const classes = useStyles();
  const translate = useTranslate();

  useEffect(() => {
    if (!lists) return;
    const listIds = lists.map(list => list.id)
    if (!filters.listId || listIds.includes(filters.listId)) return;
    toggleFilter('listId', null)
  }, [ lists, filters, toggleFilter ])

  const listIds = lists?.map(list => list.id) || [];

  const filteredOfficials = allOfficials.filter(official => {
    // ignore assigned officials in other positions
    if (isAssigned(official.flags) && assignment.participantId !== official.participantId) return false;

    // search ignores other filters
    if (search) return official.participant.fullName.toUpperCase().includes(search.toUpperCase())

    const flags = filterFlags(official.flags, assignment.position, filters, official.meta);

    if (filters.onlyNew && !isNew(flags)) return false;
    if (filters.onlyLocal && !isLocal(flags)) return false;

    // always include the currently selected official ignoring the below filters
    if (assignment.participantId === official.participantId) return true;

    if (filters.listId && listIds.includes(filters.listId)) {
      if (!official.listIds || !official.listIds.includes(filters.listId)) return false;
    }

    // Attribute values filters
    if (hasAttributeValuesFilter(filters, attributeTypes)) {
      if (!hasMatchingAttributeValues(filters, official.attributeValues, attributeTypes)) {
        return false
      }
    }

    if (!filters.unavailable && isUnavailable(flags)) return false;
    if (!filters.ineligible && isIneligible(flags)) return false;
    if (!filters.expired && isExpired(flags)) return false;
    if (!filters.unregistered && isUnregistered(flags)) return false;
    return true;
  });

  const toggle = <IconButton onClick={toggleMenu}>
    {isMenuOpen ? <MenuOpen /> : <Menu /> }
  </IconButton>

  if (!isMenuOpen && small) {
    return <div className={classes.closed}>
      {toggle}
    </div>
  }

  const handleSelect = participantId => {
    selectOfficial(participantId)
    if (small) closeMenu()
  }

  const isFiltered = filteredOfficials.length !== allOfficials.length;
  const menu = <div className={cn(classes.root, { [classes.open]: isMenuOpen })}>
    <div className={classes.filters}>
      {small && toggle}
      <SearchInput
        className={classes.search}
        autoFocus
        value={search}
        placeholder={translate('resources.games.labels.assignment.search_names')}
        onChange={onSearch}
        onClear={onClear}
      />
      <Tooltip title={isFiltered ? `${allOfficials.length - filteredOfficials.length}/${allOfficials.length} ${translate('resources.games.messages.assignment.hidden_by_filter')}` : ''}>
        <IconButton onClick={toggleFilterMenu}>
          <Badge color="primary" variant="dot" invisible={!isFiltered}>
            <FilterList color={isFilterMenuOpen ? 'primary' : 'secondary'} />
          </Badge>
        </IconButton>
      </Tooltip>
    </div>
    <OfficialFilters className={classes.filtersPanel} />
    <OfficialList officials={filteredOfficials} onSelect={handleSelect} />
  </div>

  if (small) return <ClickAwayListener onClickAway={closeMenu}>{menu}</ClickAwayListener>
  return menu;
}
