import React from 'react';
import { useRecordContext } from 'react-admin';
import { Card, List } from '@material-ui/core';

import { FF_OFFICIAL_HIDE_ASSIGNERS } from '@hisports/common/featureFlags';

import { isAuthorized } from '../../../common/Authorize';
import { useFlag } from '../../../http';

import { OfficialAssignersMenuItem } from '../AvailabilitySettings/OfficialAssignersDialog';
import { OfficialVenuesMenuItem } from '../AvailabilitySettings/OfficialVenuesDialog';
import { OfficialCategoriesMenuItem } from '../AvailabilitySettings/OfficialCategoriesDialog';

export default props => {
  const record = useRecordContext();
  const isEnabled = useFlag();

  const canManageAssigners = !isEnabled(FF_OFFICIAL_HIDE_ASSIGNERS);
  const canManageCategories = isAuthorized(record, 'officialAvailabilities', 'categories');

  const participantId = record?.participantId || record?.id;

  const actions = [
    canManageAssigners && <OfficialAssignersMenuItem key="assigners" participantId={participantId} />,
    <OfficialVenuesMenuItem key="venues" participantId={participantId} />,
    canManageCategories && <OfficialCategoriesMenuItem key="categories" participantId={participantId} />,
  ].filter(Boolean);

  if (!actions.length) return null;

  return <Card>
    <List>
      {actions}
    </List>
  </Card>
}
