import { sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { GET_LIST, useQueryWithStore, useTranslate } from 'react-admin';

import { groupBy } from '@hisports/common/src/lodash';

import { useScheduleGroupingType } from '../../../scheduleSettings';
import { STEPS } from './Generator';


const hasOrderGap = (scheduleTeams = []) => {
  // No gaps if 0 or 1 team
  if (scheduleTeams.length <= 1) return false;

  const groupedByPool = groupBy(scheduleTeams, 'poolId');

  return Object.values(groupedByPool).some(poolTeams => {
    const sortedTeams = sortBy(poolTeams, 'order');
    return sortedTeams.some((team, index) => {
      // skip the first item
      if (index === 0) return false;
      // check for gaps
      return team.order !== sortedTeams[index - 1].order + 1;
    });
  })
};

export const useScheduleTeamsWithPools = (scheduleId, enabled = true) => useQueryWithStore({
  type: GET_LIST,
  resource: 'scheduleteams',
  payload: {
    filter: { scheduleId, _include: ['pool', { 'team': ['logo'] }] },
    pagination: { page: 1, perPage: 999 },
  }
}, { enabled: !!scheduleId && enabled, action: 'CUSTOM_QUERY' })

const GeneratorContext = React.createContext(null);

export const useGeneratorContext = () => useContext(GeneratorContext) || {};

export const GeneratorContextProvider = ({ schedule = {}, activeStep, setShowMatrix, children }) => {
  const translate = useTranslate();
  const [ groupId, setGroupId ] = useState(null);
  const [ poolIds, setPoolIds ] = useState([]);
  const [ alert, setAlert ] = useState(null);

  const { isPools, isGroups, loading: groupingTypeLoading, loaded: groupingTypeLoaded } = useScheduleGroupingType(schedule.id);

  // loading schedule teams
  const { data: scheduleTeamsUnfiltered = [], loading: scheduleTeamsLoading, loaded: scheduleTeamsLoaded } = useScheduleTeamsWithPools(schedule.id);

  // loading latest published draft game
  const { data: drafts, loading: draftsLoading, loaded: draftsLoaded } = useQueryWithStore({
    type: GET_LIST,
    resource: 'draftGames',
    payload: {
      filter: { scheduleId: schedule.id },
      sort: { field: 'round', order: 'DESC' },
      pagination: { page: 1, perPage: 1 },
    }
  }, { enabled: !!schedule.id, action: 'CUSTOM_QUERY' })

  const loading = scheduleTeamsLoading || draftsLoading || groupingTypeLoading;
  const loaded = scheduleTeamsLoaded || draftsLoaded || groupingTypeLoaded;

  const scheduleTeams = useMemo(() => {
    return sortBy(scheduleTeamsUnfiltered.filter(scheduleTeam => scheduleTeam.groupId === groupId && (!poolIds.length || poolIds.includes(scheduleTeam.poolId)), 'order'))
  }, [scheduleTeamsUnfiltered, groupId, poolIds])

  const hasPool = poolIds.length > 0;

  useEffect(() => {
    if (!scheduleTeamsLoaded) return
    if (!scheduleTeams.length) {
      return setAlert({ step: STEPS.MATCHUPS, severity: 'error', message: translate(`resources.draftGames.messages.no_teams${groupId ? '_group' : hasPool ? '_pool' : ''}_alert`) })
    } else if (scheduleTeams.length === 1) {
      return setAlert({ step: STEPS.MATCHUPS, severity: 'error', message: translate(`resources.draftGames.messages.one_team${groupId ? '_group' : hasPool ? '_pool' : ''}_alert`) })
    } else if (hasOrderGap(scheduleTeams)) {
      return setAlert({ step: STEPS.MATCHUPS, severity: 'warning', message: translate(`resources.draftGames.messages.gaps${groupId ? '_group' : hasPool ? '_pool' : ''}_alert`) })
    }
    return setAlert(null)
  }, [scheduleTeamsLoaded, scheduleTeams, groupId, hasPool, setAlert, translate])

  const value = useMemo(() => {
    const [ lastPublishedDraft ] = drafts || [];
    const lastPublishedDraftRound = lastPublishedDraft?.round;

    return {
      schedule,
      groupId,
      poolIds,
      activeStep,
      lastPublishedDraftRound,
      scheduleTeams,
      loading,
      loaded,
      alert,
      setAlert,
      setGroupId,
      setPoolIds,
      setShowMatrix,
      isPools,
      isGroups,
    }
  }, [ setGroupId, setPoolIds, alert, setAlert, setShowMatrix, scheduleTeams, drafts, loading, loaded, schedule, activeStep, groupId, poolIds, isPools, isGroups ]);

  return <GeneratorContext.Provider value={value}>
    {children}
  </GeneratorContext.Provider>
}
