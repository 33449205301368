import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { ListContextProvider, ResourceContextProvider } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { LinearProgress } from '@material-ui/core';

import { parseMatrix } from '@hisports/scheduler';

import { apiClient } from '../../../../http';
import { useList } from '../../../../common/ra/useList';
import { ListView } from '../../../../common/cards/ListCard';
import { BaseEventView } from '../../../events/EventView';
import { CALENDAR_VIEWS, EventViewSettingsContext, EventViewSettingsMenu, GAME_LIST_FILTERS, useReverseHomeAway } from '../../../events/EventViewSettings';
import { TeamInput } from '../../../teams/TeamInput';

import { useGeneratorContext } from './GeneratorContext';

const EventViewSettingsProvider = ({ ...props }) => {
  const groupDates = useState(true);
  const groupArenas = useState(false);
  const groupRounds = useState(false);
  const assignments = useState(false);
  const gameInfo = useState(false);
  const flags = useState(false);
  const reverseHomeAway = useReverseHomeAway();
  const calendar = useState(false);
  const calendarView = useState(CALENDAR_VIEWS.WEEK);
  const teamEvents = useState(false);
  const slots = useState(false);
  const teamNames = useState(false)
  const availabilities = useState(false);
  const surfaceSizes = useState([]);
  const gameListFilter = useState(GAME_LIST_FILTERS.ALL_GAMES);
  const value = useMemo(() => ({
    calendar,
    calendarView,
    teamEvents,
    slots,
    teamNames,
    availabilities,
    gameInfo,
    flags,
    reverseHomeAway,
    groupDates,
    groupArenas,
    groupRounds,
    assignments,
    surfaceSizes,
    gameListFilter
  }), [ calendar, calendarView, teamEvents, slots, availabilities, gameInfo, flags, reverseHomeAway, groupDates, groupArenas, groupRounds, assignments, surfaceSizes, gameListFilter, teamNames ])
  return <EventViewSettingsContext.Provider value={value} {...props} />
}

export const Preview = props => {
  const { change } = useForm();
  const { values } = useFormState();
  const { schedule, groupId, scheduleTeams } = useGeneratorContext()
  const [ generateLoaded, setGenerateLoaded ] = useState(false);
  const [ generateLoading, setGenerateLoading ] = useState(false);
  const [ generateError, setGenerateError ] = useState()
  const { games = [], matrixTable, crossScheduleTeams, roundType, gamesPerTeam, totalRounds, limitByes, startDate, startRound, type, weekdays, weekdaysType, breaks, poolIds } = values;

  useEffect(() => {
    setGenerateLoading(true)
    const teams = [...(scheduleTeams || []), ...(crossScheduleTeams || [])]
    apiClient(`/schedules/${schedule.id}/generate`, { params: {
      groupId,
      poolIds,
      generationOptions: { matrix: parseMatrix(matrixTable, teams), roundType, gamesPerTeam, totalRounds, limitByes, startDate, startRound, type, weekdays, weekdaysType, breaks }
    } })
      .then(res => res.data)
      .then(games => games.map((games, id) => ({ ...games, id })))
      .then(games => change('games', games))
      .catch(err => {
        change('games', [])
        setGenerateError(err)
      })
      .finally(() => {
        setGenerateLoaded(true)
        setGenerateLoading(false)
      })
  }, [ schedule.id, groupId, poolIds, change, matrixTable, roundType, gamesPerTeam, totalRounds, limitByes, startDate, startRound, type, weekdays, weekdaysType, breaks, scheduleTeams, crossScheduleTeams ])

  const filters = [
    <TeamInput source="teamId" label="resources.teams.fields.teamId" filter={{ scheduleId: schedule?.id, seasonId: schedule?.seasonId, 'scheduleteams.groupId': values?.groupId }} variant="outlined" multiple allowEmpty alwaysOn />
  ]
  const actions = [
    <EventViewSettingsMenu
      disableCalendar
      disableAssignments
      disableGroupRounds={false}
      disableGroupArenas={false}
      alwaysOn
      showTeamEvents={false}
      showSlots={false}
      showAvailabilties={false}
      showSurfaceSizes={false}
      showFlagsInput={false}
      showRenumber={false}
      showGameInfoInput={false}
    />
  ]

  const context = useList({
    data: games,
    resource: 'draftgames', // resource has to be lowercase for team filtering to work correctly
    ids: games.map(game => game.id),
    loading: generateLoading,
    loaded: generateLoaded,
    error: generateError,
    page: 1,
    perPage: 25,
  })

  if (generateLoading || !generateLoaded) return <LinearProgress />;
  return <ResourceContextProvider value="draftGames">
    <EventViewSettingsProvider>
      <ListContextProvider value={context}>
        <ListView
          actions={actions}
          filters={filters}
          open
          noResultsText="resources.draftGames.messages.no_results"
        >
          <BaseEventView
            rowClick={false}
            disableCalendar
            disableAssignments
            hideStatus
            hideNumber
            showPools
            component={Fragment}
          />
        </ListView>
      </ListContextProvider>
    </EventViewSettingsProvider>
  </ResourceContextProvider>
}
