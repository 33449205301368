import React from 'react';
import { useRecordContext } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';

import NotPublicChip from './chips/NotPublicChip';
import { SyncedIndicator } from './SyncAlert';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    margin: theme.spacing(1),
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: theme.spacing(10),
    overflow: 'hidden',
    '& img': {
      margin: 0,
      verticalAlign: 'middle',
    }
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h1': {
      margin: 0,
    },
    '& h2': {
      margin: 0,
    }
  },
  typeText: {
    lineHeight: 1,
  },
  description: {
    lineHeight: 2,
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
}))

export const ShowHeader = ({ name, description, type, avatar }) => {
  const classes = useStyles();
  const record = useRecordContext();

  return <div className={classes.root}>
    {avatar && <div className={classes.avatar}>
      {avatar(record)}
    </div>}
    <div className={classes.main}>
      {type && <Typography variant="overline" component="h2" color="textSecondary" className={classes.typeText}>
        {type(record)}
      </Typography>}
      {name &&
        <div className={classes.name}>
          <Typography variant="h6" component="h1">
            {name(record)}
          </Typography>
          <SyncedIndicator record={record} style={{ fontSize: 18 }} />
          <NotPublicChip record={record} />
        </div>}
      {description && <Typography variant="overline" component="h2" className={classes.description}>{description(record)}</Typography>}
    </div>
  </div>
}
