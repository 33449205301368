import React from "react"
import { useTranslate } from "react-admin";
import { Chip, makeStyles, Tooltip } from "@material-ui/core"
import { VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  chip: {
    color: theme.palette.warning[500],
    border: `1px solid ${theme.palette.warning[500]}`,
    backgroundColor: theme.palette.warning[50],
    marginLeft: theme.spacing(0.5),
    height: theme.spacing(2.5),
    fontSize: '0.7rem',
  },
  icon: {
    color: theme.palette.warning[500],
    marginLeft: '6px !important',
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
}))

export default ({ record }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (record?.isPublic !== false) return null;
  return <Tooltip title={translate("resources.schedules.tooltips.hidden_to_public")}>
    <Chip
      className={classes.chip}
      size="small"
      label={translate("resources.schedules.labels.not_public")}
      icon={<VisibilityOff className={classes.icon} />}
    />
  </Tooltip>
}
