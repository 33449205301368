import { ceil, floor } from '@hisports/common/src/lodash.js';


/**
 * get the ideal extra / missing value of the currentTotal to reach the required gamesPerTeam
 *
 * @param {number} gamesPerTeam - the number of games per team
 * @param {number} currentTotal - the total value of this row/col (home of away)
 * @param {number} otherTotal - the total value of this team's other location (home or away)
 * @return {number} diff between gamesPerTeam and currentTotal
 */
export const getValidationDiff = (gamesPerTeam, currentTotal, otherTotal) => {
  const upperBound = ceil(gamesPerTeam / 2)
  const lowerBound = floor(gamesPerTeam / 2)
  const idealValue = gamesPerTeam / 2 // for odd gamesPerTeam, this is a x.5, but the logic bellow still work

  // if gamesPerTeam is even, return currentTotal - idealValue
  if ((currentTotal >= idealValue && otherTotal <= idealValue) ||
    (currentTotal <= idealValue && otherTotal <= idealValue)) {
    // current should be the upperBound and other should be the lowerBound
    return currentTotal - upperBound;
  }

  if ((currentTotal >= idealValue && otherTotal >= idealValue) ||
    (currentTotal <= idealValue && otherTotal >= idealValue)) {
    // current should be the lowerBound and other should be the upperBound
    return currentTotal - lowerBound;
  }
}

// parse the matrix into a list of home matchups per team
// format: [{ homeTeamId: 19001, matchups: [2, 4, ...], ...]
export const parseMatrix = (matrix = [], teams = []) => {
  return matrix.map((row, homeIndex) => {
    const homeTeam = teams[homeIndex]
    return {
      homeScheduleId: homeTeam.scheduleId,
      homeGroupId: homeTeam.groupId,
      homeTeamId: homeTeam.teamId,
      matchups: row.map(count => count)
    }
  })
}

// unparse the list of home matchups per team into a matrix
export const unparseMatrix = (list = []) => {
  return list.map(({ matchups }) => {
    return matchups.map(count => count)
  })
}

// reset the matrix to 0s
export const clearMatrix = (matrix = []) => {
  return matrix.map(row => row.map((val) => {
    if (val === undefined || val === null) return val;
    return 0;
  }))
}
