import React, { useMemo } from 'react';
import { useResourceContext, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Accordion, AccordionSummary, AccordionDetails, styled, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, DateRange as Schedule } from '@material-ui/icons';
import { RRule } from 'rrule';
import moment from 'moment-timezone';

const ScheduleIcon = styled(Schedule)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const List = styled('ul')(({ theme }) => ({
  margin: 0,
  paddingLeft: theme.spacing(5),
}))

const ListItem = styled('li')(({ theme }) => ({
  padding: theme.spacing(.5, 0),
}))

export const getRule = values => {
  if (!values.frequency || values.frequency === 'Once' || !values.endDate || !values.timezone) return;

  const FREQ = { 'Daily': RRule.DAILY, 'Weekly': RRule.WEEKLY, 'Monthly': RRule.MONTHLY };
  const WDAY = { 'Sun': RRule.SU, 'Mon': RRule.MO, 'Tue': RRule.TU, 'Wed': RRule.WE, 'Thu': RRule.TH, 'Fri': RRule.FR, 'Sat': RRule.SA };

  const weekdays = values.weekdays?.map(day => WDAY[day]).filter(Boolean);
  const dtstart = values.startTime ? moment.tz(values.startTime, values.timezone).startOf('day').toDate() : undefined;
  const until = moment.tz(values.endDate, values.timezone).endOf('day').toDate();

  return new RRule({
    freq: FREQ[values.frequency],
    dtstart,
    until,
    interval: values.interval || 1,
    byweekday: weekdays?.length > 0 ? weekdays : undefined,
  });
}

export const useRecurrence = recurrence => {
  return useMemo(() => {
    if (!recurrence) return {};

    const FREQ = { [RRule.DAILY]: 'Daily', [RRule.WEEKLY]: 'Weekly', [RRule.MONTHLY]: 'Monthly' };
    const WDAY = { [RRule.SU]: 'Sun', [RRule.MO]: 'Mon', [RRule.TU]: 'Tue', [RRule.WE]: 'Wed', [RRule.TH]: 'Thu', [RRule.FR]: 'Fri', [RRule.SA]: 'Sat' }

    const rule = RRule.fromString(recurrence);
    if (!rule?.origOptions) return {};

    const { freq, interval, byweekday, until } = rule.origOptions;

    return {
      frequency: FREQ[freq],
      interval,
      weekdays: byweekday?.map(day => WDAY[day]),
      endDate: until,
    };
  }, [recurrence]);
};

export const RecurrenceSchedule = () => {
  const translate = useTranslate();
  const resource = useResourceContext();

  const { values } = useFormState();
  const rule = useMemo(() => getRule(values), [ values ])

  const occurrences = useMemo(() => {
    if (!rule) return [];
    return rule.all().map(instance => {
      const date = moment(instance).tz(values.timezone).format('YYYY-MM-DD');
      return moment.tz(`${date}T00:00:00`, values.timezone);
    });
  }, [rule, values.timezone]);

  if (!rule || !values.startTime || !occurrences) return null;

  const total = occurrences.length;
  const first = occurrences.slice(0, 5);
  const last = occurrences[total - 1];

  const summary = total > 0
    ? translate(`resources.${resource}.messages.event_repeat`, { smart_count: total })
    : translate(`resources.${resource}.messages.schedule_no_occurences`)

  return <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <ScheduleIcon />
      <Typography color="textSecondary">{summary}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <List>
        {first?.map(timestamp => <ListItem>{timestamp.format('ddd, LL')}</ListItem>)}
        {total > 5 && <ListItem>{translate(`resources.${resource}.messages.additional_instances`, { smart_count: total - 6 })}</ListItem>}
        {total > 5 && <ListItem>{last.format('ddd, LL')}</ListItem>}
      </List>
    </AccordionDetails>
  </Accordion>
}
