import { orderBy } from 'lodash';
import { useState, useEffect } from 'react';
import { useQuery, GET_LIST, GET_MANY_REFERENCE } from 'react-admin';

import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

export const useOffices = () => {
  const [ assigners, setAssigners ] = useState([])
  const [ offices, setOffices ] = useState([])
  const [ loaded, setLoaded ] = useState(false)

  const { data: allData = [], loaded: allLoaded = false, error: officesError } = useQuery({
    type: GET_LIST,
    resource: 'offices',
    payload: {
      filter: { _scope: 'Tenant' },
      pagination: { page: 0, perPage: 9999 },
    }
  })
  const { data: assignerData = [], loaded: assignersLoaded = false, error: assignersError } = useQuery({
    type: GET_LIST,
    resource: 'offices',
    payload: {
      filter: { _scope: 'Tenant', isAssigner: true },
      pagination: { page: 0, perPage: 9999 },
    }
  })

  useEffect(() => {
    if (!assignersLoaded || !allLoaded) return;
    let assigners = assignerData
      .filter(office => ![...GAME_OFFICE_TYPES, 'Historical'].includes(office.type))
      .map(office => {
        office.parent = allData.find(parent => parent.id == office.parentId)
        return office;
      })

    let offices = allData
      .filter(office => ![...GAME_OFFICE_TYPES, 'Historical'].includes(office.type))
      .map(office => {
        office.parent = allData.find(parent => parent.id == office.parentId)
        return office;
      })

    assigners = orderBy(assigners, 'parent.name', 'name')
    offices = orderBy(offices, 'parent.name', 'name')

    setAssigners(assigners)
    setOffices(offices)
    setLoaded(true)
  }, [ assignerData, assignersLoaded, allData, allLoaded ])

  return { loaded, assigners, offices, error: officesError || assignersError }
}


export function useOfficeSelection(participantId) {
  const [ selection, setSelection ] = useState([])
  const { data = [], ...props } = useQuery({
    type: GET_MANY_REFERENCE,
    resource: 'officialOffices',
    payload: {
      target: 'participants',
      id: participantId,
      pagination: { page: 0, perPage: 9999 },
      filter: {},
    }
  }, {
    enabled: participantId != null,
    onSuccess({ data }) {
      const officeIds = data.map(relation => relation.officeId);
      setSelection(officeIds)
    }
  })

  return { selection, ...props }
}
