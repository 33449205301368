import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { BooleanInput, Datagrid, GET_LIST, ListContextProvider, Pagination, RecordContextProvider, SimpleForm, useDataProvider, useList, useListContext, useNotify, useQuery, useRefresh, useResourceContext, useTranslate } from "react-admin";
import { useForm, useFormState } from 'react-final-form';
import { Button, Dialog, DialogContent, DialogTitle, Grid, LinearProgress, useMediaQuery } from "@material-ui/core";
import { PlaylistAdd } from '@material-ui/icons';
import moment from 'moment';

import { dedupeBy } from '@hisports/parsers';
import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import { FieldDependency } from '../../common/FieldDependency';
import { DebounceTextInput } from '../../common/inputs/DebounceTextInput';

import { OfficeInput } from '../offices/OfficeInput';
import { ParticipantField } from '../participants/ParticipantField';
import { VenueInput } from '../venues/VenueInput';
import { ListInput } from '../lists/ListInput';

const DEFAULT_FORM_VALUES = {
  officeId: null,
  venueId: null,
  participant: null,
  includeSubOffices: false,
  today: moment().format('YYYY-MM-DD'),
};

const SUBMIT_MSGS = {
  officialOffices: {
    success: 'resources.officialOffices.notifications.officials_added',
    error: 'resources.officialOffices.notifications.add_officials_error',
  },
  listMembers: {
    success: 'resources.listMembers.notifications.list_officials_added',
    error: 'resources.listMembers.notifications.add_list_officials_error',
  },
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
  helperText: false,
}

const getFilters = (values) => {
  const { today, officeId, includeSubOffices, participant, venueId, type } = values;

  return {
    ...(officeId && { officeId, effectiveOffices: includeSubOffices }),
    ...(venueId && { 'participant.officialVenues.venueId': venueId }),
    ...(type && { 'qualificationCategory.types': { inq: [type] } }),
    and: [
      { or: [{ expiry: { gte: today } }, { expiry: { eq: null } }] },
      ...(participant ? [{
        or: [
          { 'participant.firstName': { ilike: `%${participant}%` } },
          { 'participant.lastName': { ilike: `%${participant}%` } },
        ],
      }] : []),
    ],
  }
}

const useOfficials = (filter) => useQuery({
  type: GET_LIST,
  resource: 'officials',
  payload: {
    filter,
    sort: { field: 'participant.firstName', order: 'ASC' },
    pagination: { page: 1, perPage: 999 },
  }
});

const OfficialsGrid = (props) => {
  const { selectedIds } = useListContext();
  const { change } = useForm();

  useEffect(() => {
    change('participantIds', selectedIds);
  }, [selectedIds, change]);

  return <Datagrid {...props} size="medium" rowClick="toggleSelection" hasBulkActions>
    <ParticipantField source="participantId" includeId="inline" />
  </Datagrid>
}

const ListToolbar = ({ handleSearch, handleReset }) => {
  const { dirty } = useFormState();
  const translate = useTranslate();

  return <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
    <Button variant="outlined" size="small" onClick={handleReset} disabled={!dirty}>
      {translate('ra.action.reset')}
    </Button>
    <Button color="primary" variant="contained" size="small" onClick={handleSearch}>
      {translate('ra.action.search')}
    </Button>
  </div>
}

const OfficialList = () => {
  const { change, batch } = useForm();
  const { values, initialValues } = useFormState();
  const [filters, setFilters] = useState(values);
  const { data, loading, loaded, error } = useOfficials(getFilters(filters));

  const { officeId, includeSubOffices } = values;

  const officials = useMemo(() =>
    dedupeBy('participantId', (data || [])).map(official => ({ ...official, id: official.participantId }))
  , [data]);

  useEffect(() => {
    if (includeSubOffices && !officeId) {
      change('includeSubOffices', false);
    }
  }, [officeId, includeSubOffices, change]);

  const handleSearch = () => {
    setFilters(values);
  };

  const handleReset = () => {
    batch(() => Object.entries(initialValues).forEach(([key, value]) => change(key, value)));
    setFilters(initialValues);
  }

  const listContext = useList({
    data: officials,
    error,
    loading,
    loaded,
    page: 1,
    perPage: 25,
  })

  return <ListContextProvider value={listContext}>
    <ListToolbar handleSearch={handleSearch} handleReset={handleReset} />
    <LinearProgress style={{ marginTop: 12, visibility: loading ? 'visible' : 'hidden' }} />
    <OfficialsGrid />
    <Pagination rowsPerPageOptions={[]} />
  </ListContextProvider>
}

const SelectOfficialsForm = ({ onClose, initialValues, ...props }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const resource = useResourceContext();

  const onSubmit = ({ listId, participantIds }) => {
    if (!participantIds?.length) return;

    const listMembers = participantIds.map(participantId => ({
      officeId: initialValues?.officeId,
      participantId,
      ...(listId ? { listId } : {}),
    }));

    return dataProvider.createMany(resource, { data: listMembers })
      .then(data => data.data)
      .then(data => {
        onClose();
        refresh();
        notify(translate(SUBMIT_MSGS[resource].success, data.length || 0));
      })
      .catch(() => {
        onClose();
        notify(translate(SUBMIT_MSGS[resource].error), 'warning');
      });
  };

  const showListInput = !!initialValues?.listId;

  return <SimpleForm save={onSubmit} component={Fragment} initialValues={{ ...DEFAULT_FORM_VALUES, ...initialValues }} {...props}>
    <Grid container spacing={2} alignItems="center" style={{ paddingTop: 8 }}>
      {showListInput && <Grid item xs={12}>
        <ListInput source="listId" label="resources.listMembers.fields.listId" disabled {...inputProps} />
      </Grid>}
      <Grid item xs={12} md={8}>
        <OfficeInput source="officeId" label="resources.officials.fields.officeId" filter={{ type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] } }} {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <FieldDependency fieldSource="officeId" disabled>
          <BooleanInput source="includeSubOffices" label="resources.officials.fields.includeSubOffices" {...inputProps} />
        </FieldDependency>
      </Grid>
      <Grid item xs={12} md={12}>
        <VenueInput source="venueId" label="resources.officials.fields.venueId" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <DebounceTextInput source="participant" label="resources.officials.fields.participant" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <OfficialList />
      </Grid>
    </Grid>
  </SimpleForm>
}

const SelectOfficialsDialog = ({ title, isOpen, onSubmit, onClose, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return <Dialog open={isOpen} maxWidth="md" fullWidth fullScreen={fullScreen}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent style={{ paddingTop: 0 }}>
      <RecordContextProvider value={{}}>
        <SelectOfficialsForm
          onClose={onClose}
          toolbar={<DialogFormToolbar submitLabel="ra.action.add" cancelLabel="ra.action.cancel" onCancel={onClose} />}
          {...props}
        />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}

export const SelectOfficialsButton = ({ size = "small", variant, ...props }) => {
  const translate = useTranslate();
  const resource = useResourceContext(props);
  const [open, setOpen] = useState(false);

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<PlaylistAdd fontSize="small" />} onClick={() => setOpen(true)}>
      {translate(`resources.${resource}.actions.add_officials`)}
    </Button>
    <SelectOfficialsDialog
      title={translate(`resources.${resource}.labels.add_officials`)}
      isOpen={open}
      onClose={() => setOpen(false)}
      {...props}
    />
  </>
}
