import React from 'react';
import { useTranslate } from 'react-admin';
import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  styled,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useForm, useFormState } from 'react-final-form';

import AutocompleteInput from '../../../common/inputs/AutocompleteInput';
import { useVenueSelection } from './venues'
import { getVenueCity } from '../../venues/util';
import { matchVenue, VenueSuggestion } from '../../venues/VenueInput';
import { useIsOwnParticipant } from '../../../common/hooks/hooks';

const DialogTitle = styled(MuiDialogTitle)({
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 10,
})

const DialogContent = styled(MuiDialogContent)({
  paddingTop: 0,
})

const Venue = ({ venue }) => {
  const { mutators } = useForm();
  const { values: { venueIds } } = useFormState();

  const removeVenue = () => {
    const index = venueIds.findIndex(venueId => venueId === venue.id)
    mutators.remove('venueIds', index)
  }

  if (!venue) return null;
  return <ListItem dense disableRipple component="li">
    <ListItemText primary={venue.name} secondary={getVenueCity(venue, true)} />
    <ListItemSecondaryAction>
      <IconButton edge="end" onClick={removeVenue}>
        <Clear />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
}

const Selection = ({ venues, participantId }) => {
  const translate = useTranslate();
  const { values: { venueIds = [] } } = useFormState();
  const isOwnParticipant = useIsOwnParticipant(participantId);

  const selectedVenues = venueIds
    .map(venueId => venues.find(venue => venue.id === venueId))
    .filter(Boolean)

  if (!selectedVenues?.length) {
    return <Alert severity="success">
      <AlertTitle>{translate('resources.participants.alerts.availability.selection.title')}</AlertTitle>
      <p>{translate(`resources.participants.alerts.availability.selection.${isOwnParticipant ? 'own_profile' : 'other_profile'}.available_to_all_arenas`)}</p>
    </Alert>
  }

  return <List disablePadding>
    {selectedVenues.map(venue => <Venue key={venue.id} venue={venue} />)}
  </List>
}

const VenuesForm = ({ participantId, venues }) => {
  const translate = useTranslate();
  const { selection, loaded, error } = useVenueSelection(participantId, venues);

  if (error) {
    return <Alert severity="error">{translate('ra.page.error_try_again')}</Alert>
  }

  if (!loaded) {
    return <Alert severity="info" icon={<CircularProgress color="secondary" size={22} />}>{translate('resources.venues.alerts.loading_venues')}</Alert>
  }

  return <>
    <AutocompleteInput
      choices={venues}
      defaultValue={selection}
      source="venueIds"
      label="resources.venues.messages.add_venues"
      placeholder="resources.venues.messages.search_venue"
      emptyOptionsText="resources.venues.messages.enter_name_city"
      multiple
      disableClearable
      groupBy={venue => venue && getVenueCity(venue, true, true, false)}
      minFilterLength={3}
      suggestionComponent={VenueSuggestion}
      inputValueMatcher={matchVenue}
      variant="outlined"
      margin="normal"
      fullWidth
      disableRemove
      renderTags={() => null}
    />
    <Selection venues={venues} participantId={participantId} />
  </>
}

export const VenueList = ({ participantId, venues }) => {
  const translate = useTranslate();
  const isOwnParticipant = useIsOwnParticipant(participantId);

  return <>
    <DialogTitle>{translate(`resources.participants.labels.availability.venues_availabilities.${isOwnParticipant ? 'own_profile' : 'other_profile'}.title`)}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate(`resources.participants.labels.availability.venues_availabilities.${isOwnParticipant ? 'own_profile' : 'other_profile'}.select_venues`)}
      </DialogContentText>
      <VenuesForm participantId={participantId} venues={venues} />
    </DialogContent>
  </>
}
