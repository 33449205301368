import { Card, makeStyles } from '@material-ui/core';
import React from 'react';

import { ListView } from '../../../common/cards/ListCard';
import EventGroupedGrid from '../../events/EventGroupedGrid';

const useDraggableListStyles = makeStyles(theme => ({
  card: {
    maxHeight: '100vh',
    overflow: 'auto',
  }
}))

const DivComponent = ({ children, className, ...props }) => {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export default ({ rowClick, dragComponent, className, listViewProps = {}, hideActions, showPools }) => {
  const classes = useDraggableListStyles();

  return <Card className={className}>
    <ListView open {...listViewProps}>
      <EventGroupedGrid
        className={classes.card}
        hideTime
        hideGroup
        hideStatus
        disableAssignments
        component={DivComponent}
        disableCalendar
        rowClick={rowClick}
        dragComponent={dragComponent}
        hideActions={hideActions}
        showPools={showPools}
      />
    </ListView>
  </Card>
}
