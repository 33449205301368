import moment from 'moment-timezone';

import { createRange, overlaps, areConsecutiveGames } from '@hisports/common';
import { dedupe } from '@hisports/parsers';
import { sortGameSuspensions } from '@hisports/scoresheet/src/util/index.js';

import { isAssigned, isRequested, isUnconfirmed, isDeclined, isNoShow } from './assignment.js';
import { ALL_OFFICIAL_POSITIONS } from '@hisports/scoresheet/src/constants.js';

const INACTIVE_STATUSES = ['Cancelled', 'Postponed', 'Conflict'];

const isGameInactive = game => !game || INACTIVE_STATUSES.includes(game.status)

const isSameDay = (assignmentDate, gameDate) => assignmentDate == gameDate

const isConsecutive = (assignmentA, assignmentB) => {
  if (!assignmentA || !assignmentB) return;
  if (!assignmentA.game || !assignmentB.game) return;
  if (!assignmentA.game.assignSettings || !assignmentB.game.assignSettings) return;

  const assignerAssignmentA = assignmentA.officeId || assignmentA.game.assignSettings.officeId;
  const assignerAssignmentB = assignmentB.officeId || assignmentB.game.assignSettings.officeId;
  if (assignerAssignmentA !== assignerAssignmentB) return false;

  return areConsecutiveGames(assignmentA.game, assignmentB.game)
}

const isAffiliate = (teamIds = [], rosterMembers = []) => {
  const rosterMember = rosterMembers.find(member => teamIds.includes(member.teamId))
  if (!rosterMember) return false;
  return rosterMember.isAffiliate;
}

const toGameSummary = (game, { consecutive = false, isAffiliate = false } = {}) => {
  if (!game) return;
  const { id, number, startTime, endTime, surface, timezone } = game;
  let summary = `${number}: ${moment.tz(startTime, timezone).format('MMM D, HH:mm')} - ${moment.tz(endTime, timezone).format('HH:mm z')}`
  if (surface) {
    const { name, venue } = surface;
    const fullName = venue.name === name ? name : `${venue.name} - ${name}`
    summary += ` @ ${fullName}`
  }
  return { id, summary, consecutive, isAffiliate }
}

const toPracticeSummary = (practice, { isAffiliate = false } = {}) => {
  if (!practice) return;
  const { id, startTime, endTime, surface, timezone } = practice;
  let summary = `Practice: ${moment.tz(startTime, timezone).format('MMM D, HH:mm')} - ${moment.tz(endTime, timezone).format('HH:mm z')}`
  if (surface) {
    const { name, venue } = surface;
    const fullName = venue.name === name ? name : `${venue.name} - ${name}`
    summary += ` @ ${fullName}`
  }
  return { id, summary, isAffiliate }
}

const toActivitySummary = (activity, { isAffiliate = false } = {}) => {
  if (!activity) return;
  const { id, type, startTime, endTime, location, timezone } = activity;
  const summary = `${type}: ${moment.tz(startTime, timezone).format('MMM D, HH:mm')} - ${moment.tz(endTime, timezone).format('HH:mm z')} @ ${location}`
  return { id, summary, isAffiliate }
}

const toSuspensionSummary = suspensions => {
  if (!suspensions.length) return [];
  const suspension = sortGameSuspensions(suspensions)[0];
  const { effectiveRequiredGames, expiry, effectiveDurationType, positionGroup, game: { date } } = suspension;
  return { effectiveRequiredGames, expiry, effectiveDurationType, positionGroup, date }
}

const getDeclineEvent = (events, participantId) => {
  // ignore declines after a game has been rescheduled, and return the latest decline of the official
  // decline1 -> rescheduled -> decline2 = decline2
  // decline1 -> rescheduled = n/a

  const rescheduled = events.find(event => event.eventType === 'gameRescheduled');
  const officialEvents = events.filter(event => {
    if (event.eventType !== 'officialDeclined' || event.event.participantId !== participantId) return false;
    if (rescheduled) return moment(event.timestamp).isAfter(rescheduled.timestamp) // include declines after the rescheduling
    return true;
  })
  if (!officialEvents.length) return;
  return officialEvents[0];
}

const getRequestedEvent = (events, participantId) => {
  return events.find(event => event.eventType === 'officialRequested' && event.event.official.participantId === participantId)
}

const getAssignedEvent = (events, participantId) => {
  return events.find(event => event.eventType === 'officialAssigned' && event.event.official.participantId === participantId)
}

export const getInitialSlots = game => {
  const slots = [];
  const duration = moment.tz(game.endTime, game.timezone).diff(moment.tz(game.startTime, game.timezone), 'minutes')
  const totalSlots = Math.ceil(duration / 15)
  for (let index = 0; index < totalSlots; index++) {
    const startTime = moment.tz(game.startTime, game.timezone).add(15 * index, 'minutes').toISOString();
    const endTime = moment(startTime).add(15, 'minutes').toISOString();
    slots.push({
      startTime,
      endTime,
      timezone: game.timezone,
    });
  }
  return slots;
}

const getAvailabilitySlots = (slots, availabilities) => {
  // simplified version of assignAvailability below based on calendar availability only
  // this ensures unknown slots are able to be flagged
  return slots.map(slot => {
    const start = moment.tz(slot.startTime, slot.timezone).toDate()
    const end = moment.tz(slot.endTime, slot.timezone).toDate()

    const slotRange = createRange(start, end);
    const availability = availabilities.find(availability => overlaps(slotRange, availability))

    let notes, isAvailable = null;
    if (availability) {
      isAvailable = availability.isAvailable;
      notes = availability.notes || undefined;
    }

    return {
      startTime: slot.startTime,
      endTime: slot.endTime,
      isAvailable, // true, false, null (unknown)
      notes,
    }
  })
}

const getSeasonDate = (season) => {
  const [ start ] = season.split('-');
  return moment(`${start}-12-31`)
}

const calculateAge = (profile, referenceDate) => {
  if (!profile || !profile.birthdate) return null;
  const birthdate = moment.utc(profile.birthdate)
  return referenceDate.diff(birthdate, 'years')
}

const isExpired = (expiry, startTime, timezone) => {
  return expiry && moment.tz(startTime, timezone).isAfter(moment.tz(expiry, timezone), 'day');
}

const isValidType = (qualification) => {
  return qualification.qualificationCategory.types && qualification.qualificationCategory.types.some(type => ['Official', 'Scorekeeper'].includes(type));
}

const getLevel = (qualifications, game, type) => {
  qualifications = qualifications.filter(qualification => {
    return qualification.qualificationCategory.types && qualification.qualificationCategory.types.includes(type)
  })

  if (!qualifications.length) return null

  let levels = qualifications.filter(qualification => !isExpired(qualification.expiry, game.startTime, game.timezone))
  if (!levels.length) {
    levels = qualifications
  }
  return Math.max(...levels.map(qualification => qualification.level))
}

const getGrade = (qualifications, game, type, position) => {
  qualifications = qualifications.filter(qualification => {
    return qualification.qualificationCategory.types && qualification.qualificationCategory.types.includes(type)
  })

  if (!qualifications.length) return null;

  let grades = qualifications.filter(qualification => !isExpired(qualification.expiry, game.startTime, game.timezone))
  if (!grades.length) {
    grades = qualifications
  }

  return Math.max(...grades.map(qualification => qualification.grades && qualification.grades[position] || 1))
}

export const getOfficialCategoryIds = (position, officialCategories) => {
  const positionEntry = officialCategories.find(c => c && c.position === position);
  const allEntry = officialCategories.find(c => c && c.position === null);

  const positionCategoryIds = positionEntry ? positionEntry.categoryIds : null;
  const allCategoryIds = allEntry ? allEntry.categoryIds : null;

  // specific position > "all" position > null
  return positionCategoryIds || allCategoryIds || null;
}

export const getFlags = (p, game, settings, surface, officeIds = [], localOfficeIds = [], arenaIds = [], slots = [], games = [], practices = [], activities = [], events = [], invites = [], sport, isAssigner) => {
  const {
    officialQualifications: qualifications = [],
    availabilities = [],
    officialOffices = [],
    officialVenues = [],
    officialCategories = [],
    officialConflicts: conflicts = [],
    members: rosterMembers = [],
    lists = [],
    attributeValues = [],
    officialGames: assignments = [],
    addresses = [],
    contacts = [],
    identities = [],
    profile = [],
    suspensions = [],
    ...participant
  } = p;

  const seasonDate = getSeasonDate(game.seasonId)
  const age = calculateAge(profile, seasonDate)
  const address = addresses[0] || {} // api limits a single primary address

  const levels = {
    Official: getLevel(qualifications, game, 'Official'),
    Scorekeeper: getLevel(qualifications, game, 'Scorekeeper')
  }
  const grades = {
    Referee: getGrade(qualifications, game, 'Official', 'Referee'),
    Linesperson: getGrade(qualifications, game, 'Official', 'Linesperson'),
  }
  const types = Object.keys(levels).filter(index => levels[index] != null)

  const rosteredTeamIds = rosterMembers.map(member => member.teamId);
  const rosteredGames = games.filter(game => rosteredTeamIds.includes(game.homeTeamId) || rosteredTeamIds.includes(game.awayTeamId))
  const rosteredPractices = practices.filter(practice => rosteredTeamIds.some(teamId => practice.teamIds.includes(teamId)))
  const rosteredActivities = activities.filter(activity => rosteredTeamIds.includes(activity.teamId))

  const flags = [];
  const meta = {};

  const addMeta = (type, values) => {
    meta[type] = Object.assign({}, meta[type], values);
  }

  const addFlag = (flag, meta) => {
    flags.push(flag);
    if (meta) addMeta(flag, meta);
  }

  // flag if the qualifiation has expired at the time of the game
  const valid = qualifications.some(qualification => isValidType(qualification) && !isExpired(qualification.expiry, game.startTime, game.timezone))
  if (!valid) addFlag('Expired')

  // flag if does not meet minimum referee, linesperson, official, scorekeeper, or timekeeper level
  if (settings.minReferee != null) {
    const refereeLevelOk = levels.Official >= settings.minReferee
    if (!refereeLevelOk) addFlag('Referee Level');
  }
  if (settings.minLinesperson != null) {
    const linespersonOk = levels.Official >= settings.minLinesperson
    if (!linespersonOk) addFlag('Linesperson Level')
  }
  if (settings.minOfficial != null) {
    const officialOk = levels.Official >= settings.minOfficial
    if (!officialOk) addFlag('Official Level')
  }
  if (settings.minScorekeeper != null) {
    const officialOk = levels.Scorekeeper >= settings.minScorekeeper
    if (!officialOk) addFlag('Scorekeeper Level')
  }
  if (settings.minTimekeeper != null) {
    const officialOk = levels.Scorekeeper >= settings.minTimekeeper
    if (!officialOk) addFlag('Timekeeper Level')
  }

  const requiresGrades = settings.minRefereeGrade != null || settings.minLinespersonGrade != null
  if (sport === 'Hockey') {
    // flag grade if applicable (only when the same level; a level 2 grade 1 official is eligible for any level 1 game)
    if (settings.minRefereeGrade != null && levels.Official == settings.minReferee) {
      const refereeGradeOk = grades.Referee && grades.Referee >= settings.minRefereeGrade
      if (!refereeGradeOk) addFlag('Referee Grade');
    }

    if (settings.minLinespersonGrade != null && levels.Official == settings.minLinesperson) {
      const linespersonGradeOk = grades.Linesperson && grades.Linesperson >= settings.minLinespersonGrade
      if (!linespersonGradeOk) addFlag('Linesperson Grade');
    }
  }

  // flag if under min age
  if (settings.minAge != null) {
    const ageOk = age && age >= settings.minAge
    if (!ageOk) addFlag('Underage', { missing: !age });
  }

  // flag if not opted into game's assigner
  const officeOk = officialOffices.some(availability => officeIds.includes(availability.officeId));
  if (!officeOk) addFlag('Office');

  // flag if not opted into game's venue
  const venueOk = !officialVenues.length || officialVenues.some(availability => availability.venueId === surface.venueId);
  if (!venueOk) addFlag('Arena');

  // flag if not opted into game's category, by position
  if (officialCategories.length) {
    const unavailablePositions = [];

    ALL_OFFICIAL_POSITIONS.forEach(position => {
      const positionCategoryIds = getOfficialCategoryIds(position, officialCategories);

      if (positionCategoryIds && !positionCategoryIds.includes(game.categoryId)) {
        unavailablePositions.push(position);
      }
    })

    if (unavailablePositions.length) {
      addFlag('Category', { unavailablePositions });
    }
  }


  // flag if game time is outside of game availability
  const gameRange = createRange(game.startTime, game.endTime);
  const availabilitySlots = getAvailabilitySlots(slots, availabilities);
  const gameSlots = availabilitySlots.filter(slot => overlaps(gameRange, slot))
  const unavailableSlots = gameSlots.filter(slot => slot.isAvailable === false)
  const unknownSlots = gameSlots.filter(slot => slot.isAvailable == null)
  const availableSlots = gameSlots.filter(slot => slot.isAvailable === true);

  if (unavailableSlots.length) {
    addFlag('Unavailable');
    const notes = dedupe(unavailableSlots.map(slot => slot.notes)).join('\n');
    if (notes) addMeta('Unavailable', { notes })
  } else if (unknownSlots.length) {
    addFlag('Availability');
    const notes = dedupe(unknownSlots.map(slot => slot.notes)).join('\n');
    if (notes) addMeta('Availability', { notes });
  } else {
    const notes = dedupe(availableSlots.map(slot => slot.notes)).join('\n');
    if (notes) addMeta('Availability', { notes })
  }

  // flag if conflict entered for a given team
  const teamIds = [game.homeTeamId, game.awayTeamId];
  const teamConflict = conflicts.some(conflict =>
    conflict.targetType === 'Team' && teamIds.includes(conflict.targetId)
  )
  if (teamConflict) addFlag('Team Conflict');

  // flag if own team
  const rosterConflict = rosteredGames.some(rosteredGame => rosteredGame.id === game.id);
  if (rosterConflict) addFlag('Roster')

  const gameAssignment = assignments.find(assignment => assignment.gameId === game.id)

  const assigned = isAssigned(gameAssignment)
  if (assigned) {
    addFlag('Assigned');
    const assignedEvent = getAssignedEvent(events, participant.id);
    const assigner = assignedEvent && assignedEvent.meta.assignerId
    if (assigner) addMeta('Assigned', { assigner });
    if (gameAssignment.notes) addMeta('Assigned', { notes: gameAssignment.notes });
    if (gameAssignment.status) addMeta('Assigned', { confirmed: gameAssignment.status === 'confirmed' })
  }

  const requested = isRequested(gameAssignment);
  if (requested) {
    const event = getRequestedEvent(events, participant.id);
    addFlag('Requested', {
      position: gameAssignment.position,
      notes: gameAssignment.notes || undefined,
      timestamp: event && event.timestamp,
    })
  }

  const unconfirmed = isUnconfirmed(gameAssignment);
  if (unconfirmed) {
    addFlag('Unconfirmed')
    if (gameAssignment.notes) {
      addMeta('Unconfirmed', { notes: gameAssignment.notes })
    }
  }

  const declined = isDeclined(gameAssignment)
  if (declined) {
    addFlag('Declined');
    const assignedEvent = getAssignedEvent(events, participant.id);
    const assigner = assignedEvent && assignedEvent.meta.assignerId
    if (assigner) addMeta('Declined', { assigner });
    if (gameAssignment.notes) {
      addMeta('Declined', { notes: gameAssignment.notes })
    }
  }

  const declineEvent = getDeclineEvent(events, participant.id);
  if (!gameAssignment && declineEvent) {
    addFlag('Removed', {
      response: 'Declined',
      notes: declineEvent.event.notes || undefined,
    })
  }

  const noShow = isNoShow(gameAssignment)
  if (noShow) {
    addFlag('No Show');
  }

  // other assignments only includes active games that are assigned/confirmed (no declined/cancelled games)
  const otherAssignments = assignments.filter(assignment => assignment.gameId !== game.id && isAssigned(assignment) && !isGameInactive(assignment.game));

  // flag if reached daily limit
  if (settings.dailyLimit && otherAssignments.length >= settings.dailyLimit) {
    addFlag('Daily Limit', { dailyLimit: settings.dailyLimit });
  }

  // flag if game overlaps with another game
  const officialConflicts = otherAssignments
    .filter(assignment => {
      if (isUnconfirmed(assignment)) return false;
      return overlaps(gameRange, assignment.game)
    })
    .map(assignment => toGameSummary(assignment.game))

  const hasOfficialConflict = officialConflicts.length > 0;
  if (hasOfficialConflict) {
    addFlag('Game Conflict', {
      ids: officialConflicts.map(summary => summary.id), // deprecated
      games: officialConflicts,
    })
    if (requested) {
      addMeta('Requested', { conflict: true });
    }
  }

  // flag if game overlaps with another assignment
  const officialOverlaps = otherAssignments
    .filter(assignment => {
      if (!isUnconfirmed(assignment)) return false;
      return overlaps(gameRange, assignment.game)
    })
    .map(assignment => toGameSummary(assignment.game))

  const hasOfficialOverlap = officialOverlaps.length > 0;
  if (hasOfficialOverlap) {
    addFlag('Game Overlap', {
      ids: officialOverlaps.map(summary => summary.id), // deprecated
      games: officialOverlaps,
    })
    if (requested) {
      addMeta('Requested', { conflict: true });
    }
  }

  // flag if official is at another venue within an hour of the game
  if (!hasOfficialConflict && !hasOfficialOverlap) {
    const travelRange = createRange(
      moment.tz(game.startTime, game.timezone).subtract(1, 'hour').toDate(),
      moment.tz(game.endTime, game.timezone).add(1, 'hour').toDate()
    );

    const assignedTravelConflicts = otherAssignments
      .filter(assignment => {
        if (!isAssigned(assignment) || arenaIds.includes(assignment.game.arenaId)) return false;
        return overlaps(travelRange, assignment.game, { adjacent: true })
      })
      .map(assignment => {
        const summary = toGameSummary(assignment.game, { consecutive: isConsecutive(gameAssignment, assignment) });
        return summary;
      })

    const rosteredTravelConflicts = rosteredGames
      .filter(game => {
        if (arenaIds.includes(game.arenaId)) return false;
        return overlaps(travelRange, game, { adjacent: true })
      })
      .map(game => toGameSummary(game))

    const practiceTravelConflicts = rosteredPractices
      .filter(practice => {
        if (arenaIds.includes(practice.arenaId)) return false;
        return overlaps(travelRange, practice, { adjacent: true })
      })
      .map(practice => toPracticeSummary(practice))

    const activityTravelConflicts = rosteredActivities
      .filter(activity => {
        return overlaps(travelRange, activity, { adjacent: true })
      })
      .map(activity => toActivitySummary(activity))

    const hasTravelConflict = assignedTravelConflicts.length > 0 || rosteredTravelConflicts.length > 0 || practiceTravelConflicts.length > 0 || activityTravelConflicts.length > 0;
    if (hasTravelConflict) {
      const ids = Array.from(new Set([
        ...assignedTravelConflicts.map(summary => summary.id),
        ...rosteredTravelConflicts.map(summary => summary.id),
      ]))

      addFlag('Travel', {
        ids, // deprecated
        games: [ ...assignedTravelConflicts, ...rosteredTravelConflicts ],
        practices: practiceTravelConflicts,
        activities: activityTravelConflicts,
      });
    }
  }

  // flag if official is assigned in the same day
  const assignedSameDayConflicts = otherAssignments
    .filter(assignment => isSameDay(assignment.game.date, game.date))
    .map(assignment => {
      const summary = toGameSummary(assignment.game, { consecutive: isConsecutive(gameAssignment, assignment) });
      return summary;
    })
  const isAssignedSameDay = assignedSameDayConflicts.length > 0;
  if (isAssignedSameDay) {
    addFlag('Assigned Today', {
      games: [...assignedSameDayConflicts]
    });
  }

  // flag if official is already at the venue within 3 hours of the game
  const sixHourRange = createRange(
    moment.tz(game.startTime, game.timezone).subtract(3, 'hour').toDate(),
    moment.tz(game.endTime, game.timezone).add(3, 'hour').toDate()
  );

  const atVenue = otherAssignments
    .filter(assignment => arenaIds.includes(assignment.game.arenaId) && (overlaps(sixHourRange, assignment.game, { adjacent: true })))
    .map(assignment => {
      const summary = toGameSummary(assignment.game, { consecutive: isConsecutive(gameAssignment, assignment) });
      return summary;
    })
  const isAtVenue = atVenue.length > 0;
  if (isAtVenue) {
    addFlag('At Arena', {
      ids: atVenue.map(summary => summary.id), // deprecated
      games: atVenue,
    });
  }

  // flag if official is already at the venue within 1 hour of the game
  const consecutive = assignments
    .filter(assignment =>
      assignment.gameId !== game.id &&
       !isGameInactive(assignment.game) &&
       isConsecutive(gameAssignment, assignment)
    )
    .map(assignment => {
      const summary = toGameSummary(assignment.game, { consecutive: true });
      return summary;
    })

  const hasConsecutives = consecutive.length > 0;
  if (hasConsecutives) {
    addFlag('Consecutive', {
      ids: consecutive.map(summary => summary.id), // deprecated
      games: consecutive,
    });
  }

  // flag if rostered team is playing game at this time
  // no need to check if we already know there's an existing time conflict
  if (!(rosterConflict || hasOfficialConflict || hasOfficialOverlap)) {
    const gameOverlap = rosteredGames
      .filter(game => {
        if (isGameInactive(game)) return false;
        return overlaps(gameRange, game);
      })
      .map(game => toGameSummary(game, { isAffiliate: isAffiliate([game.homeTeamId, game.awayTeamId], rosterMembers) }))

    const practiceOverlap = rosteredPractices
      .filter(practice => {
        if (isGameInactive(practice)) return false;
        return overlaps(gameRange, practice);
      })
      .map(practice => toPracticeSummary(practice, { isAffiliate: isAffiliate(practice.teamIds, rosterMembers) }))

    const activityOverlap = rosteredActivities
      .filter(activity => {
        if (isGameInactive(activity)) return false;
        return overlaps(gameRange, activity);
      })
      .map(activity => toActivitySummary(activity, { isAffiliate: isAffiliate([activity.teamId], rosterMembers) }))

    const hasTeamOverlap = gameOverlap.length > 0 || practiceOverlap.length > 0 || activityOverlap.length > 0;
    if (hasTeamOverlap) {
      addFlag('Roster Conflict', {
        ids: gameOverlap.map(game => game.id), // deprecated
        games: gameOverlap,
        practices: practiceOverlap,
        activities: activityOverlap,
      })
      if (requested) {
        addMeta('Requested', { conflict: true });
      }
    }
  }

  if (!contacts.length) {
    addFlag('Not Verified')
  }

  if (!identities.length) {
    if (!invites.includes(participant.id)) {
      addFlag('Not Registered')
    } else {
      addFlag('Invited')
    }
  }

  if (qualifications.every(qualification => qualification.seasonId === game.seasonId)) {
    addFlag('New');
  }

  if (qualifications.some(qualification => localOfficeIds.includes(qualification.officeId))) {
    addFlag('Local');
  }

  if (suspensions.length > 0) {
    addFlag('Suspended', {
      suspension: toSuspensionSummary(suspensions)
    })
  }


  // TODO: flag teams in same group/schedule
  return {
    id: participant.id,
    participantId: participant.id,
    participant,
    level: levels.Official,
    levels,
    types,
    grades: sport === 'Hockey' && requiresGrades ? grades : undefined,
    flags,
    meta,
    officeId: qualifications[0] && qualifications[0].officeId,
    listIds: isAssigner ? lists.map(list => list.id) : undefined,
    attributeValues: isAssigner ? attributeValues : undefined,
    city: (address && address.city) ? `${address.city}, ${address.province}` : undefined,
  }
}
