import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core'

import { SeasonInput } from '../../common/inputs/EnumInputs';
import Toolbar from '../../common/ra/Toolbar';
import LogoInput from '../../common/inputs/LogoInput';
import { SyncAlert, useRecordSynced } from '../../common/SyncAlert';

import { OfficeInput } from '../offices/OfficeInput';
import { CategoryInput } from '../categories/CategoryInput';

const validate = values => {
  const errors = {}

  if (!values.name) errors.name = 'ra.validation.required'
  if (!values.HCRId) errors.HCRId = 'ra.validation.required'
  if (!values.seasonId) errors.seasonId = 'ra.validation.required'
  if (!values.categoryId) errors.categoryId = 'ra.validation.required'

  if (!values.officeId) errors.officeId = 'ra.validation.required'

  return errors;
}

const inputProps = {
  resource: 'teams',
  basePath: '/teams',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const TeamForm = props => {
  const [ isSynced, toggle ] = useRecordSynced(props)

  return <>
    <SyncAlert isSynced={isSynced} toggle={toggle} />
    <SimpleForm toolbar={<Toolbar isSynced={isSynced} />} validate={validate} {...props}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12} md={8}>
          <TextInput source="name" autoFocus disabled={isSynced} {...inputProps} />
        </Grid>

        <Grid item xs={12} md={4}>
          <SeasonInput source="seasonId" disabled={isSynced} {...inputProps} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput source="HCRId" disabled={isSynced} {...inputProps} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CategoryInput source="categoryId" disabled={isSynced} {...inputProps} />
        </Grid>

        <Grid item xs={12}>
          <OfficeInput source="officeId" filter={{ type: { nin: ['League', 'Tournament', 'Cup', 'Zone', 'Historical'] } }} disabled={isSynced} {...inputProps} />
        </Grid>
        {!isSynced && <Grid item xs={12}>
          <LogoInput source="logo" {...inputProps} />
        </Grid>}
      </Grid>
    </SimpleForm>
  </>
}
