import React, { useEffect, useMemo, useRef, useState } from 'react'
import { RecordContextProvider, useInput, useRecordContext, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Paper, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, makeStyles, TextField, LinearProgress, Menu, MenuItem, FormControlLabel, Checkbox, Box, IconButton, Tooltip, Button, Typography, Chip, useTheme } from '@material-ui/core';
import { MergeType as MergeTypeIcon, MoreVert as MoreVertIcon, Add as AddIcon, Clear, Refresh } from '@material-ui/icons';

import { sum } from '@hisports/parsers';
import { getValidationDiff, unparseMatrix, clearMatrix } from '@hisports/scheduler/src/util/matrix';

import { apiClient } from '../../../../http';
import { TeamField } from '../../../teams/TeamField';
import { useElementSize } from '../../../../common/useElementSize';
import { ScheduleField } from '../../ScheduleField';
import { GroupField } from '../../../groups/GroupField';

import { useGeneratorContext } from './GeneratorContext';
import { AddCrossTeamDialog } from './CrossTeamDialog';
import { sortBy, uniqBy } from '@hisports/common/src/lodash';

const stickyStyle = (theme) => ({
  position: 'sticky',
  zIndex: 2,
});

const stickyBothSidesStyle = {
  zIndex: 3
}

const useStyles = makeStyles(theme => ({
  container: {
    // this height is to have top and bottom stickies working accordingly
    maxHeight: '67vh',
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    '& th': {
      fontWeight: 500,
    },
    // not using mui stickyHeader props because it conflicts with left sticky behaviour
    '& thead th': {
      ...stickyStyle(theme),
      top: 0,
      verticalAlign: 'top',
      '&:first-child': stickyBothSidesStyle,
      '&:nth-child(2)': stickyBothSidesStyle
    },
    '& th:first-child': {
      ...stickyStyle(theme),
      left: 0,
    },
    '& th:nth-child(2)': {
      ...stickyStyle(theme),
      left: props => props.firstCellWidth,
    },
    '& tfoot :is(th, td)': {
      fontWeight: 500,
      ...stickyStyle(theme),
      bottom: 0,
      '&:first-child': stickyBothSidesStyle,
      '&:nth-child(2)': stickyBothSidesStyle
    },
  },
  headerCells: {
    borderBottom: `2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    fontWeight: 500,
  },
  footerCells: {
    borderTop: `2px solid ${theme.palette.divider}`,
    borderRight: `2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    fontWeight: 500,
  },
  firstColumns: {
    width: theme.spacing(45),
  },
  totalColumns: {
    width: theme.spacing(8),
  },
  teamColumn: {
    width: props => props?.compactMode ? theme.spacing(8) : theme.spacing(16)
  },
  overflowColumn: {
    width: '100%',
    overflow: 'hidden',
  },
  teamRow: {
    height: props => props?.compactMode ? theme.spacing(8) : theme.spacing(16),
  },
  crossTooltip: {
    verticalAlign: '-15%'
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    gap: props => props?.gap || theme.spacing(2),
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(.5)
  },
  chip: {
    alignSelf: 'center',
    marginBlock: theme.spacing(.5),
  },
  logo: {
    width: props => theme.spacing(props?.logoSpacing || 4),
    height: props => theme.spacing(props?.logoSpacing || 4),
    objectFit: 'contain',
  }
}))

const PoolChip = ({ pool, poolHighlight, setPoolHighlight, ...props }) => {
  const classes = useStyles()
  const { isPools } = useGeneratorContext()

  const color = poolHighlight === pool?.id ? 'primary' : 'default'
  const onClick = () => setPoolHighlight(prev => prev === pool.id ? null : pool.id)

  if (!pool || !isPools) return null;
  return <Chip label={pool.name} size="small" color={color} className={classes.chip} onClick={onClick} {...props} />
}

const TeamLogo = ({ team, spacing = 4 }) => {
  const classes = useStyles({ logoSpacing: spacing })
  const logoUrl = team?.team?.logo?.url;

  if (!logoUrl) return null;
  return <img src={logoUrl} alt={team.name} className={classes.logo} />
}

const CrossTeamTooltip = ({ schedule, ...props }) => {
  const translate = useTranslate();
  const team = useRecordContext(props);
  const classes = useStyles();
  const { isGroups } = useGeneratorContext();
  const { scheduleId, groupId, isCrossTeam } = team

  if (!isCrossTeam || !isGroups) return null;

  const CrossSchedulingDetails = <span>
    {schedule?.id !== scheduleId && <>{translate('resources.draftGames.labels.cross_schedule')}: <ScheduleField source="scheduleId" link={false} variant="inherit" /></>}
    {schedule?.id !== scheduleId && groupId && <br />}
    {groupId && <>{translate('resources.draftGames.labels.cross_group')}: <GroupField source="groupId" link={false} variant="inherit" /></>}
  </span>

  return <Tooltip title={CrossSchedulingDetails} placement="top" className={classes.crossTooltip}>
    <MergeTypeIcon fontSize="small" />
  </Tooltip>
}

const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = () => setValue(currentValue => !currentValue);

  return [value, toggle];
}

const MatrixOptions = ({
  showOrganisation, toggleShowOrganisation,
  showShortNames, toggleShowShortNames,
  compactMode, toggleCompactMode, setCrossTeamDialogOpen,
  uniquePools, poolHighlight, setPoolHighlight,
  handleResetMatrix, handleRecalculateMatrix
}) => {
  const [ anchor, setAnchor ] = useState(null)
  const toggleMenu = e => setAnchor(anchor => anchor ? !anchor : e.currentTarget)
  const handleClose = () => setAnchor(null)
  const translate = useTranslate();
  const classes = useStyles({ gap: 8 })
  const { isGroups, isPools } = useGeneratorContext()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'flex-start' }}>
      <Box>
        {isPools && !!uniquePools?.length && <div className={classes.stack} style={{ alignItems: 'baseline' }}>
          <Typography variant="caption">{translate('resources.pools.messages.select_highlight')}</Typography>
          <div className={classes.group}>
            {uniquePools.map(pool => <PoolChip key={pool.id} pool={pool} poolHighlight={poolHighlight} setPoolHighlight={setPoolHighlight} />)}
          </div>
        </div>}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: 'auto' }}>
        <Button color="secondary" startIcon={<Clear />} onClick={handleResetMatrix}>
          {translate('ra.action.clear')}
        </Button>
        <Button color="secondary" startIcon={<Refresh />} onClick={handleRecalculateMatrix}>
          {translate('ra.action.recalculate')}
        </Button>
        {isGroups && <Button color="primary" startIcon={<AddIcon />} onClick={() => setCrossTeamDialogOpen(true)}>
          {translate('resources.draftGames.labels.add_cross_team')}
        </Button>}
        <IconButton onClick={toggleMenu}><MoreVertIcon /></IconButton>
      </Box>
      <Menu
        classes={{ paper: classes.menu }}
        anchorEl={anchor}
        open={anchor != null}
        onClose={handleClose}
      >
        <MenuItem>
          <FormControlLabel
            className={classes.controlLabel}
            control={<Checkbox checked={showOrganisation} onChange={toggleShowOrganisation} disabled={false} name="" />}
            label={translate('resources.schedules.labels.matrix.show_organisation')}
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            className={classes.controlLabel}
            control={<Checkbox checked={!showShortNames} onChange={toggleShowShortNames} disabled={false} name="" />}
            label={translate('resources.schedules.labels.matrix.show_full_names')}
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            className={classes.controlLabel}
            control={<Checkbox checked={compactMode} onChange={toggleCompactMode} disabled={false} name="" />}
            label={translate('resources.schedules.labels.matrix.compact_mode')}
          />
        </MenuItem>
      </Menu>
    </Box>
  )
}

const HomeAwayHeaderCell = ({ firstThRef, inverseMatrix }) => {
  const translate = useTranslate();
  const globalClasses = useStyles();

  return <TableCell ref={firstThRef} className={globalClasses.headerCells}>
    {translate(`resources.draftGames.labels.${inverseMatrix ? 'away' : 'home'}`)} vs {translate(`resources.draftGames.labels.${inverseMatrix ? 'home' : 'away'}`)} →
  </TableCell>
}

const useTotalHeaderCellStyles = makeStyles(theme => ({
  cell: {
    borderRight: `4px solid ${theme.palette.divider}`,
  }
}))

const TotalHeaderCell = (props) => {
  const translate = useTranslate();
  const globalClasses = useStyles();
  const classes = useTotalHeaderCellStyles();

  return <TableCell align="center" className={`${globalClasses.headerCells} ${classes.cell}`}>
    {translate('resources.draftGames.labels.total')}
  </TableCell>
}

const useTeamHeaderCellStyles = makeStyles(theme => ({
  cell: {
    borderRight: props => `${props?.isLastPoolTeam || props?.isLastTeam ? 4 : 2}px solid ${theme.palette.divider}`,
    backgroundColor: props => props?.isHighlighted ? theme.palette.lightBlue[50] : theme.palette.background.paper,
  },
}))

const TeamHeaderCell = ({ team, lastTeamsByPool, poolHighlight, index, teams, compactMode, setPoolHighlight, showOrganisation, showShortNames, schedule }) => {
  const isHighlighted = team.pool?.id === poolHighlight;
  const isLastPoolTeam = lastTeamsByPool.some(lastTeam => lastTeam.teamId === team.teamId);
  const isLastTeam = index === teams.length - 1;
  const globalClasses = useStyles();
  const classes = useTeamHeaderCellStyles({ isHighlighted, isLastPoolTeam, isLastTeam });

  return <TableCell key={team.teamId} align="center" className={`${globalClasses.headerCells} ${classes.cell}`}>
    {compactMode
      ? <div className={globalClasses.stack}>
        {index+1}
        <TeamLogo team={team} />
      </div>
      : <RecordContextProvider value={team}>
        <div className={globalClasses.stack}>
          <PoolChip pool={team.pool} poolHighlight={poolHighlight} setPoolHighlight={setPoolHighlight} />
          <TeamLogo team={team} spacing={8} />
          <TeamField source="teamId" link={false} includeOffice={showOrganisation ? 'full' : null} showShortName={showShortNames} />
          <CrossTeamTooltip schedule={schedule} />
        </div>
      </RecordContextProvider>
    }
  </TableCell>
}

const useRowTeamCellStyles = makeStyles(theme => ({
  cell: {
    backgroundColor: props => props?.isHighlighted ? theme.palette.lightBlue[50] : theme.palette.background.paper,
    borderBottom: props => `${props?.isLastPoolTeam ? 4 : 2}px solid ${theme.palette.divider}`,
    borderTop: props => props?.isFirstTeam ? `2px solid ${theme.palette.divider}` : null,
    borderRight: 'none',
  }
}))

const RowTeamCell = ({ compactMode, isHighlighted, isLastPoolTeam, isFirstTeam, team, rowIndex, poolHighlight, setPoolHighlight, showOrganisation, showShortNames, schedule }) => {
  const globalClasses = useStyles();
  const classes = useRowTeamCellStyles({ isHighlighted, isLastPoolTeam, isFirstTeam });

  return <TableCell component="th" scope="row" className={classes.cell}>
    <div className={globalClasses.group}>
      {compactMode && <span style={{ minWidth: 16 }}>{rowIndex+1}</span>}
      <PoolChip pool={team.pool} poolHighlight={poolHighlight} setPoolHighlight={setPoolHighlight} />
      <TeamLogo team={team} spacing={compactMode ? 4 : 8} />
      <RecordContextProvider value={team}>
        <TeamField source="teamId" link={false} includeOffice={showOrganisation ? 'full' : null} showShortName={showShortNames} noWrap />
        <CrossTeamTooltip schedule={schedule} />
      </RecordContextProvider>
    </div>
  </TableCell>
}

const useRowTotalCellStyles = makeStyles(theme => ({
  cell: {
    backgroundColor: props => props?.isHighlighted ? theme.palette.lightBlue[50] : theme.palette.background.paper,
    borderBottom: props => `${props?.isLastPoolTeam ? 4 : 2}px solid ${theme.palette.divider}`,
    borderTop: props => props?.isFirstTeam ? `2px solid ${theme.palette.divider}` : null,
    borderRight: `4px solid ${theme.palette.divider}`,
  }
}))

const RowTotalCell = ({ cellDiff, team, rowSum, isHighlighted, isLastPoolTeam, isFirstTeam }) => {
  const classes = useRowTotalCellStyles({ isHighlighted, isLastPoolTeam, isFirstTeam })
  return <ValidatedTotalCell
    component="th"
    scope="row"
    align="center"
    cellDiff={cellDiff}
    skipValidation={team.isCrossTeam}
    className={classes.cell}
  >
    {rowSum}
  </ValidatedTotalCell>
}

const useMatrixCellStyles = makeStyles(theme => ({
  cell: {
    backgroundColor: props => props?.isHighlighted ? theme.palette.lightBlue[50] : null,
    borderRight: props => `${props?.isLastPoolTeamX || props?.isLastTeamX ? 4 : 2}px solid ${theme.palette.divider}`,
    borderBottom: props => `${props?.isLastPoolTeamY ? 4 : 2}px solid ${theme.palette.divider}`,
    borderTop: props => props?.isFirstTeamY ? `2px solid ${theme.palette.divider}` : null,
  },
  disabledCell: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  input: {
    padding: theme.spacing(.5),
    textAlign: 'center',
  }
}));

const MatrixCell = ({ teams, columnIndex, team, poolHighlight, rowIndex, lastTeamsByPool, matrixTable, handleCellChange, inverseMatrix }) => {
  const theme = useTheme();
  const xTeamId = teams[columnIndex].teamId
  const yTeamId = team.teamId
  const isLastPoolTeamX = lastTeamsByPool.some(lastTeam => lastTeam.teamId === xTeamId)
  const isLastPoolTeamY = lastTeamsByPool.some(lastTeam => lastTeam.teamId === yTeamId)
  const isLastTeamX = columnIndex === teams.length - 1;
  const isFirstTeamY = rowIndex === 0;
  const isHighlighted = [teams[columnIndex]?.pool?.id, team.pool?.id].includes(poolHighlight) && rowIndex !== columnIndex;
  const classes = useMatrixCellStyles({ isLastPoolTeamX, isLastPoolTeamY, isLastTeamX, isFirstTeamY, isHighlighted })

  const highlightBorders = {}
  if (isLastPoolTeamX) highlightBorders.borderRight = `4px solid ${theme.palette.divider}`
  if (isLastPoolTeamY) highlightBorders.borderBottom = `4px solid ${theme.palette.divider}`

  const linearIndex = rowIndex * matrixTable[0].length + columnIndex;
  const totalCells = matrixTable.length * matrixTable[0].length;

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();

      // find next index
      const shift = e.shiftKey ? -1 : 1;
      let nextIndex = linearIndex + shift;

      // find the next valid input element
      let nextInput = null;
      while (!nextInput && nextIndex >= 0 && nextIndex < totalCells) {
        nextInput = document.querySelector(`input[data-index="${nextIndex}"]`);
        if (!nextInput) {
          // skip invalid cells
          nextIndex += shift;
        }
      }

      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  return (
    <TableCell
      key={`${yTeamId}-${xTeamId}`}
      align="center"
      className={`${rowIndex === columnIndex ? classes.disabledCell : classes.cell}`}
    >
      {rowIndex !== columnIndex && <TextField
        inputProps={{
          className: `${classes.input}`,
          'data-index': linearIndex,
          tabIndex: 0,
        }}
        autoFocus={!rowIndex && !columnIndex}
        variant="outlined"
        value={inverseMatrix ? matrixTable[columnIndex][rowIndex] : matrixTable[rowIndex][columnIndex]}
        onChange={e => handleCellChange(e.target.value, rowIndex, columnIndex)}
        onKeyDown={handleKeyDown}
      />
      }
    </TableCell>
  )
}

const useTotalLabelFooterCellStyles = makeStyles(theme => ({
  cell: {
    borderRight: 'none',
  }
}))

const TotalLabelFooterCell = (props) => {
  const translate = useTranslate();
  const classes = useTotalLabelFooterCellStyles();
  const globalClasses = useStyles();

  return <TableCell component="th" scope="row" className={`${globalClasses.footerCells} ${classes.cell}`}>
    {translate('resources.draftGames.labels.total')}
  </TableCell>
}

const useTotalFooterCellStyles = makeStyles(theme => ({
  cell: {
    borderRight: `4px solid ${theme.palette.divider}`,
    whiteSpace: 'nowrap',
  }
}))

const TotalFooterCell = ({ totalDiff, matrixTable }) => {
  const globalClasses = useStyles();
  const classes = useTotalFooterCellStyles();

  return <ValidatedTotalCell cellDiff={totalDiff} component="th" scope="row" align="center" className={`${globalClasses.footerCells} ${classes.cell}`}>
    {sum(matrixTable)}
  </ValidatedTotalCell>
}

const useTeamTotalFooterCellStyles = makeStyles(theme => ({
  cell: {
    borderRight: props => `${props?.isLastPoolTeam || props?.isLastTeam ? 4 : 2}px solid ${theme.palette.divider}`,
    backgroundColor: props => props?.isHighlighted ? theme.palette.lightBlue[50] : theme.palette.background.paper,
  }
}))

const TeamTotalFooterCell = ({ inverseMatrix, columnTotals, columnIndex, rowTotals, gamesPerTeam, team, poolHighlight, lastTeamsByPool, teams }) => {
  const rowSum = inverseMatrix ? columnTotals[columnIndex] : rowTotals[columnIndex]
  const columnSum = inverseMatrix ? rowTotals[columnIndex] : columnTotals[columnIndex]
  const cellDiff = getValidationDiff(gamesPerTeam, columnSum, rowSum)

  const isHighlighted = team.pool?.id === poolHighlight;
  const isLastPoolTeam = lastTeamsByPool.some(lastTeam => lastTeam.teamId === team.teamId);
  const isLastTeam = columnIndex === teams.length - 1;
  const globalClasses = useStyles();
  const classes = useTeamTotalFooterCellStyles({ isLastPoolTeam, isLastTeam, isHighlighted });

  return (
    <ValidatedTotalCell key={team.teamId} align="center" cellDiff={cellDiff} skipValidation={team.isCrossTeam} className={`${globalClasses.footerCells} ${classes.cell}`}>
      {columnSum}
    </ValidatedTotalCell>
  )
}

const getValidationStyles = (cellDiff, theme) => {
  if (!cellDiff) return;

  // over - red
  if (cellDiff == 1) return { backgroundColor: [theme.palette.error[300], '!important'], color: theme.palette.error.contrastText };
  if (cellDiff == 2) return { backgroundColor: [theme.palette.error[400], '!important'], color: theme.palette.error.contrastText };
  if (cellDiff == 3) return { backgroundColor: [theme.palette.error[500], '!important'], color: theme.palette.error.contrastText };
  if (cellDiff == 4) return { backgroundColor: [theme.palette.error[600], '!important'], color: theme.palette.error.contrastText };
  if (cellDiff >= 5) return { backgroundColor: [theme.palette.error[700], '!important'], color: theme.palette.error.contrastText };

  // under - blue
  if (cellDiff == -1) return { backgroundColor: [theme.palette.primary[300], '!important'], color: theme.palette.primary.contrastText };
  if (cellDiff == -2) return { backgroundColor: [theme.palette.primary[400], '!important'], color: theme.palette.primary.contrastText };
  if (cellDiff == -3) return { backgroundColor: [theme.palette.primary[500], '!important'], color: theme.palette.primary.contrastText };
  if (cellDiff == -4) return { backgroundColor: [theme.palette.primary[600], '!important'], color: theme.palette.primary.contrastText };
  if (cellDiff <= -5) return { backgroundColor: [theme.palette.primary[700], '!important'], color: theme.palette.primary.contrastText };
}

const useValidationStyles = makeStyles(theme => ({
  validation: ({ cellDiff }) => getValidationStyles(cellDiff, theme),
}));

const ValidatedTotalCell = ({ cellDiff, skipValidation, children, className: tableCellClasses, ...tableCellProps }) => {
  const classes = useValidationStyles({ cellDiff });

  return <TableCell {...tableCellProps} className={`${tableCellClasses} ${skipValidation ? '' : classes.validation}`} >
    {children}
    {cellDiff !== 0 && !skipValidation && <>{' '}({cellDiff})</>}
  </TableCell>
}

export const Matrix = ({ source = 'matrixTable' }) => {
  const { values } = useFormState();
  const { mutators } = useForm();
  const { schedule, groupId, scheduleTeams } = useGeneratorContext()
  const firstThRef = useRef();
  const { width: firstCellWidth } = useElementSize(firstThRef.current)
  const [ generateMatrixLoaded, setGenerateMatrixLoaded ] = useState(false);
  const [ generateMatrixLoading, setGenerateMatrixLoading ] = useState(true);
  const [ inverseMatrix, setInverseMatrix ] = useState(true) // hardcoded away rows, home columns
  const { input: { value: matrixTable, onChange } } = useInput({ source })
  const [ isCrossTeamDialogOpen, setCrossTeamDialogOpen ] = useState(false);
  const [ showOrganisation, toggleShowOrganisation ] = useToggle(false);
  const [ showShortNames, toggleShowShortNames ] = useToggle(true);
  const [ compactMode, toggleCompactMode ] = useToggle(true);
  const [ recalculateTrigger, setRecalculateTrigger ] = useState(false);
  const [ poolHighlight, setPoolHighlight ] = useState(null);
  const classes = useStyles({ firstCellWidth, compactMode })

  const { gamesPerTeam, crossScheduleTeams = [] } = values;

  const teams = useMemo(() => {
    const allTeams = [...scheduleTeams, ...crossScheduleTeams
      .map(crossScheduleTeam => ({ isCrossTeam: true, ...crossScheduleTeam }))]

    return sortBy(allTeams, 'pool.name', 'order')
  }, [scheduleTeams, crossScheduleTeams])

  useEffect(() => {
    if (matrixTable) return

    setGenerateMatrixLoaded(false)
    setGenerateMatrixLoading(true)

    apiClient(`/schedules/${schedule.id}/generateMatrix`, { params: {
      groupId,
      generationOptions: { gamesPerTeam }
    } })
      .then(res => res.data)
      .then(matrix => {
        const matrixTable = unparseMatrix(matrix)
        onChange(matrixTable)
      })
      .catch(err => {
        onChange()
      })
      .finally(() => {
        setGenerateMatrixLoaded(true)
        setGenerateMatrixLoading(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ schedule.id, groupId, gamesPerTeam, recalculateTrigger ])

  const handleResetMatrix = () => {
    const newMatrixTable = clearMatrix(matrixTable);
    onChange(newMatrixTable);
  }

  const handleRecalculateMatrix = () => {
    onChange()
    setRecalculateTrigger(prev => !prev)
  }

  const handleCellChange = (value, rowIndex, columnIndex) => {
    if (isNaN(value)) return

    // maximum 3 digits
    if (value.length > 3) {
      value = parseInt(value.toString().slice(0, 3))
    }

    const table = [...matrixTable]

    if (inverseMatrix) {
      table[columnIndex][rowIndex] = Number(value)
    } else {
      table[rowIndex][columnIndex] = Number(value)
    }

    onChange(table)
  }

  const onCrossTeamSubmit = (crossTeam) => {
    onChange([
      ...matrixTable.map(row => [...row, 0]), // add zeroes at end of each row
      Array(matrixTable.length + 1).fill(0, 0, matrixTable.length).fill(null, matrixTable.length) // add row [0, 0, ... , 0, null]
    ])
    mutators.push('crossScheduleTeams', crossTeam)
    setCrossTeamDialogOpen(false)
  }

  if (!matrixTable && (generateMatrixLoading || !generateMatrixLoaded)) return <LinearProgress />;
  if (!matrixTable) return null;

  const totalGames = (scheduleTeams.length * gamesPerTeam) / 2
  const totalDiff = sum(matrixTable) - totalGames

  const rowTotals = matrixTable.map(sum)
  const columnTotals = matrixTable.map((row, index) => sum(matrixTable.map(row => row[index])))

  const uniquePools = uniqBy(teams, 'pool.id').map(team => team.pool).filter(Boolean)
  const lastTeamsByPool = (uniquePools || []).map(pool => teams.filter(team => team.pool?.id === pool.id).pop())

  return (
    <>
      <div>
        <MatrixOptions
          showOrganisation={showOrganisation} toggleShowOrganisation={toggleShowOrganisation}
          showShortNames={showShortNames} toggleShowShortNames={toggleShowShortNames}
          compactMode={compactMode} toggleCompactMode={toggleCompactMode}
          isCrossTeamDialogOpen={isCrossTeamDialogOpen} setCrossTeamDialogOpen={setCrossTeamDialogOpen}
          uniquePools={uniquePools} poolHighlight={poolHighlight} setPoolHighlight={setPoolHighlight}
          handleResetMatrix={handleResetMatrix} handleRecalculateMatrix={handleRecalculateMatrix}
        />
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table}>
          <colgroup>
            <col className={classes.firstColumns} />
            <col className={classes.totalColumns} />
            {teams.map((team) => (
              <col key={team.teamId} className={classes.teamColumn} />
            ))}
            <col className={classes.overflowColumn} />
          </colgroup>
          <TableHead>
            <TableRow>
              <HomeAwayHeaderCell firstThRef={firstThRef} inverseMatrix={inverseMatrix} />
              <TotalHeaderCell />
              {teams.map((team, index) => <TeamHeaderCell
                key={team.teamId}
                team={team}
                lastTeamsByPool={lastTeamsByPool}
                poolHighlight={poolHighlight}
                index={index}
                teams={teams}
                compactMode={compactMode}
                setPoolHighlight={setPoolHighlight}
                showOrganisation={showOrganisation}
                showShortNames={showShortNames}
                schedule={schedule}
              />)}
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team, rowIndex) => {
              const rowSum = inverseMatrix ? columnTotals[rowIndex] : rowTotals[rowIndex]
              const columnSum = inverseMatrix ? rowTotals[rowIndex] : columnTotals[rowIndex]
              const cellDiff = getValidationDiff(gamesPerTeam, rowSum, columnSum)
              const isLastPoolTeam = lastTeamsByPool.some(lastTeam => lastTeam.teamId === team.teamId)
              const isFirstTeam = rowIndex === 0;
              const isHighlighted = team.pool?.id === poolHighlight;

              return (
                <TableRow key={team.teamId} className={classes.teamRow}>
                  <RowTeamCell
                    compactMode={compactMode}
                    isLastPoolTeam={isLastPoolTeam}
                    isFirstTeam={isFirstTeam}
                    isHighlighted={isHighlighted}
                    team={team}
                    poolHighlight={poolHighlight}
                    setPoolHighlight={setPoolHighlight}
                    lastTeamsByPool={lastTeamsByPool}
                    rowIndex={rowIndex}
                    showOrganisation={showOrganisation}
                    schedule={schedule}
                    showShortNames={showShortNames}
                  />
                  <RowTotalCell cellDiff={cellDiff} team={team} rowSum={rowSum} isHighlighted={isHighlighted} isLastPoolTeam={isLastPoolTeam} isFirstTeam={isFirstTeam} />
                  {matrixTable[rowIndex].map((cell, columnIndex) => {
                    return <MatrixCell
                      teams={teams}
                      columnIndex={columnIndex}
                      team={team}
                      poolHighlight={poolHighlight}
                      rowIndex={rowIndex}
                      lastTeamsByPool={lastTeamsByPool}
                      matrixTable={matrixTable}
                      handleCellChange={handleCellChange}
                      inverseMatrix={inverseMatrix}
                    />
                  })}
                </TableRow>
              )
            })}
          </TableBody>
          <tfoot>
            <TableRow>
              <TotalLabelFooterCell />
              <TotalFooterCell totalDiff={totalDiff} matrixTable={matrixTable} />
              {teams.map((team, columnIndex) => <TeamTotalFooterCell
                key={team.teamId}
                inverseMatrix={inverseMatrix}
                columnTotals={columnTotals}
                columnIndex={columnIndex}
                rowTotals={rowTotals}
                gamesPerTeam={gamesPerTeam}
                team={team}
                poolHighlight={poolHighlight}
                lastTeamsByPool={lastTeamsByPool}
                teams={teams}
              />)}
            </TableRow>
          </tfoot>
        </Table>
      </TableContainer>
      <AddCrossTeamDialog isOpen={isCrossTeamDialogOpen} setOpen={setCrossTeamDialogOpen} onSubmit={onCrossTeamSubmit} schedule={schedule} groupId={groupId} teams={teams} />
    </>
  );
}
