import React, { useMemo, useState } from 'react';
import { useTranslate, SimpleForm, useNotify, useQuery, GET_MANY_REFERENCE, ArrayInput, RecordContextProvider, useLocale, useRecordContext } from 'react-admin';
import { Dialog, Button, CircularProgress, DialogActions, ListItemIcon, ListItemText, DialogTitle, makeStyles, Accordion, AccordionSummary, Typography, AccordionDetails, Tab, Tabs, Chip } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Category, ExpandMore } from '@material-ui/icons';
import arrayMutators from 'final-form-arrays'
import { useFormState } from 'react-final-form';

import { groupBy, sortBy } from '@hisports/common/src/lodash';
import { translateApiProperty } from '@hisports/common';
import { ALL_OFFICIAL_POSITIONS } from '@hisports/scoresheet/src/constants';
import { getOfficialCategoryIds } from '@hisports/assigning';

import { ActionMenuItem } from '../../../common/ActionMenu';
import { UpdatedAtField } from '../../../common/fields/UpdatedAtField';
import { useIsOwnParticipant } from '../../../common/hooks/hooks';
import SimpleFormIterator, { TransitionProps } from '../../../common/ra/SimpleFormIterator';
import { AssignmentPositionsEnumInput } from '../../../common/inputs/EnumInputs';
import { apiClient, useHttpClient } from '../../../http';
import { SelectableCategoriesInput } from '../../categories/SelectableCategoriesInput';

const useStyles = makeStyles((theme) => ({
  line: {
    alignItems: 'flex-start',
  },
  formControl: {
    marginTop: 0,
  },
  form: {
    '& [class*="ra-input-officialCategories"][class*=".position"]': {
      // applies max-width only to position inputs
      maxWidth: 300,
    },
  },
  updatedAt: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
  summaryList: {
    paddingLeft: theme.spacing(3),
    margin: 0,
  },
  summaryDivisionDiv: {
    breakInside: "avoid",
    marginBottom: theme.spacing(2),
  },
  summaryAccordionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  summaryAccordionContent: {
    columnCount: 6,
    columnGap: theme.spacing(4),
    width: "100%",
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  availableToAllChip: {
    color: theme.palette.success[500],
    border: `1px solid ${theme.palette.success[500]}`,
  },
  notAvailableChip: {
    color: theme.palette.error[500],
    border: `1px solid ${theme.palette.error[500]}`,
  }
}));

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const validate = values => {
  const errors = {};

  errors.officialCategories = values.officialCategories?.map((officialCategory, index) => {
    const error = {}

    if (!officialCategory?.categoryIds == null) {
      error.categoryIds = 'ra.validation.required'
    }

    if (!officialCategory?.position && officialCategory?.position !== null) {
      error.position = 'ra.validation.required'
    }

    if (values.officialCategories.filter(c => c && officialCategory && c.position === officialCategory.position).length > 1) {
      error.position = 'resources.officialCategories.validation.position_exists'
    }

    return error;
  })

  return errors;
}

const UpdatedAt = ({ officialCategories }) => {
  const classes = useStyles();

  if (!officialCategories?.[0]) return null;
  return <div className={classes.updatedAt}>
    <RecordContextProvider value={officialCategories[0]}>
      <UpdatedAtField includePrefix typographyProps={{ variant: 'caption', color: 'textSecondary' }} />
    </RecordContextProvider>
  </div>
}

const OfficialCategoriesAlert = ({ editable }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values: { officialCategories } } = useFormState();

  if (!officialCategories?.length && editable) {
    return <Alert severity="success" className={classes.alert}>
      <AlertTitle>{translate('resources.participants.labels.availability.categories_availabilities.alerts.available_to_all_title')}</AlertTitle>
      {translate('resources.participants.labels.availability.categories_availabilities.alerts.available_to_all_body')}
    </Alert>
  }

  if (!officialCategories?.length && !editable) {
    return <Alert severity="success" className={classes.alert}>
      {translate('resources.participants.labels.availability.categories_availabilities.alerts.available_to_all_title')}
    </Alert>
  }

  if (editable && officialCategories?.filter(Boolean)?.some(({ position }) => position === null)) {
    return <Alert severity="warning" className={classes.alert}>
      {translate('resources.participants.labels.availability.categories_availabilities.alerts.selecting_all_warning')}
    </Alert>
  }

  return null
}

const PositionCategories = ({ categories = [] }) => {
  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();

  const groupedByDivision = useMemo(() => {
    const sorted = sortBy(categories, ['division.order', 'order', 'name']);
    return groupBy(sorted, category => translateApiProperty(category.division, 'name', locale));
  }, [categories, locale]);

  return <div className={classes.summaryAccordionContent}>
    {Object.keys(groupedByDivision).map((division) => (
      <div key={division} className={classes.summaryDivisionDiv}>
        <Typography color="primary">{division}</Typography>
        <ul className={classes.summaryList}>
          {groupedByDivision[division].map((category) => (
            <li key={category.id}>
              <Typography variant="caption" color="textSecondary">
                {translateApiProperty(category, 'name', locale)} - {translate(`resources.categories.values.gender.${category.gender}`)}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
}

const AvailabilityChip = ({ totalSelected, totalCategories }) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (totalSelected === totalCategories) {
    return <Chip
      label={translate('resources.participants.labels.availability.categories_availabilities.chips.all')}
      color="success"
      size="small"
      variant="outlined"
      className={classes.availableToAllChip}
    />
  }

  if (totalSelected === 0) {
    return <Chip
      label={translate('resources.participants.labels.availability.categories_availabilities.chips.none')}
      color="error"
      size="small"
      variant="outlined"
      className={classes.notAvailableChip}
    />
  }

  return null;
}

const Summary = ({ allCategories = [] }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values: { officialCategories } } = useFormState();

  if (!allCategories?.length) return null;

  return <div>
    {ALL_OFFICIAL_POSITIONS.map((position, i) => {
      const positionCategoryIds = getOfficialCategoryIds(position, officialCategories);
      const validCategories = positionCategoryIds?.map(id => allCategories.find(c => c.id === id)).filter(Boolean) || allCategories;
      const totalSelected = validCategories?.length || 0;
      const totalCategories = allCategories?.length || 0;

      return <Accordion TransitionProps={{ unmountOnExit: true }} style={{ marginTop: i === 0 ? 8 : 0 }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div className={classes.summaryAccordionTitle}>
            <Typography variant="body2">{translate(`resources.games.values.assignment.position.${position}`)}</Typography>
            <div className={classes.group}>
              <AvailabilityChip totalSelected={totalSelected} totalCategories={totalCategories} />
              <Typography variant="body2" color="textSecondary" >
                {`${totalSelected} / ${totalCategories}`}
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {totalSelected === 0 &&
            <Typography variant="caption" color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
              {translate('resources.participants.labels.availability.categories_availabilities.labels.no_categories')}
            </Typography>}
          <PositionCategories categories={validCategories} />
        </AccordionDetails>
      </Accordion>
    })}
  </div>
}


const PositionInput = props => {
  const additionalChoices = [
    { id: 'all', name: 'ra.message.all_f' },
  ]

  return <AssignmentPositionsEnumInput
    additionalChoices={additionalChoices}
    {...props}
    format={value => (value === null ? 'all' : value)}
    parse={value => (value === 'all' ? null : value)}
  />
}

const Toolbar = ({ handleSubmit, onClose, editable }) => {
  const translate = useTranslate();
  const { submitting } = useFormState();

  return <DialogActions>
    <Button onClick={onClose}>{translate('ra.action.cancel')}</Button>
    {editable && <Button type="submit" color="primary" onClick={handleSubmit} disabled={submitting}>
      {!submitting ? translate('ra.action.save') : translate('resources.participants.labels.availability.saving')}
    </Button>}
  </DialogActions>
}

const OfficialCategoryEdit = ({ editable, categories }) => {
  const classes = useStyles();
  const officialCategories = useRecordContext();

  return <div>
    <ArrayInput source="officialCategories" label="" className={classes.formControl} disabled={!editable} {...inputProps}>
      <SimpleFormIterator
        disableReordering
        TransitionProps={TransitionProps}
        classes={{
          line: classes.line,
          form: classes.form
        }}
      >
        <PositionInput
          source="position"
          autoFocus
          helperText=""
          label="resources.officials.labels.position"
          {...inputProps}
        />
        <SelectableCategoriesInput source="categoryIds" categories={categories} multiple helperText="" />
      </SimpleFormIterator>
    </ArrayInput>
    { editable && <UpdatedAt officialCategories={officialCategories} /> }
  </div>
}

const OfficialCategoriesForm = ({ onClose, participantId, editable = true }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useStyles();

  const [tab, setTab] = useState('summary');

  const { data: categories } = useHttpClient('/categories');
  const { data: officialCategories = [], loaded, error } = useQuery({
    type: GET_MANY_REFERENCE,
    resource: 'officialCategories',
    payload: {
      target: 'participants',
      id: participantId,
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'position', order: 'ASC' },
      filter: {}
    },
  }, { enabled: participantId != null });

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSave = async values => {
    const { officialCategories: positionCategories } = values;

    try {
      await apiClient(`/participants/${participantId}/officialSettings`, {
        method: 'PATCH',
        data: {
          positionCategories,
        },
      })

      notify('resources.participants.labels.availability.categories_updated', 'success');
    } catch (error) {
      notify('resources.participants.labels.availability.categories_error', 'error');
    }

    onClose();
  }

  if (error) {
    return <Alert severity="error">{translate('ra.page.error_try_again')}</Alert>
  }

  if (!loaded) {
    return <Alert severity="info" icon={<CircularProgress color="secondary" size={22} />}>{translate('ra.page.loading')}</Alert>
  }

  const values = { officialCategories }
  return <RecordContextProvider value={values}>
    <SimpleForm initialValues={values} mutators={arrayMutators} toolbar={<Toolbar onClose={onClose} editable={editable} />} validate={validate} fullWidth save={handleSave}>
      <OfficialCategoriesAlert onClose={onClose} editable={editable} />

      <div style={{ width: '100%' }}>
        {editable && <Tabs value={tab} onChange={handleTabChange} indicatorColor="primary" className={classes.tabs}>
          <Tab label={translate('ra.action.summary')} value="summary" />
          <Tab label={translate('ra.action.edit')} value="edit" />
        </Tabs>}

        {tab === 'summary' && <Summary allCategories={categories} />}
        {tab === 'edit' && <OfficialCategoryEdit editable={editable} categories={categories} />}
      </div>

    </SimpleForm>
  </RecordContextProvider>
}

const OfficialCategoriesDialog = ({ isOpen, handleClose, participantId }) => {
  const translate = useTranslate();
  const isOwnParticipant = useIsOwnParticipant(participantId);

  return <Dialog
    maxWidth="lg"
    fullWidth
    open={isOpen}
  >
    <DialogTitle>{translate(`resources.participants.labels.availability.categories_availabilities.${isOwnParticipant ? 'own_profile' : 'other_profile'}.title`)}</DialogTitle>
    <OfficialCategoriesForm onClose={handleClose} participantId={participantId} />
  </Dialog>
}

export const OfficialCategoriesMenuItem = ({ participantId, ...props }) => {
  const translate = useTranslate();
  const [isOpen, setOpen] = useState(false);
  const isOwnParticipant = useIsOwnParticipant(participantId);

  return <>
    <ActionMenuItem color="primary" size="small" onClick={() => setOpen(true)}>
      <ListItemIcon><Category fontSize="small" /></ListItemIcon>
      <ListItemText>{translate(`resources.participants.labels.availability.categories_availabilities.${isOwnParticipant ? 'own_profile' : 'other_profile'}.title`)}</ListItemText>
    </ActionMenuItem>
    {isOpen && <OfficialCategoriesDialog isOpen={isOpen} handleClose={() => setOpen(false)} participantId={participantId} {...props} />}
  </>
}
