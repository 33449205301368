const messages = {
  Hockey: {
    resources: {
      surfaces: {
        name: 'Rink |||| Rinks',
        fields: {
          venueId: 'Arena'
        },
        values: {
          size: {
            XL: 'Olympic',
            M: 'NHL',
            XS: '3 on 3',
          },
        },
        actions: {
          add: 'Add Rink',
          edit: 'Edit Rink',
        },
        labels: {
          card: {
            title: 'Rinks',
            splitTitle: 'Rink Splits',
            splitSubtitle: 'A rink can be split to allow multiple events at the same time',
          },
          alerts: {
            message: "This rink is a split of"
          },
          edit: 'Update Rink',
          add: 'New Rink',
          name: 'Rink Name',
        },
        messages: {
          surfaces: 'rinks',
          availability: {
            available: 'This rink %{input} be available',
          }
        },
      },
      venues: {
        name: 'Arena |||| Arenas',
        actions: {
          add: 'Add Arena',
          edit: 'Edit Arena',
        },
        labels: {
          name: 'Arena Name',
          my: 'My Arenas'
        },
        messages: {
          add_venues: 'Add arenas',
          search_venue: 'Search by arena name or city',
        },
        alerts: {
          loading_venues: 'Loading arenas...',
        }
      },
      activities: {
        fields: {
          arenaId: 'Rink'
        },
        alerts: {
          activity_explanation: 'Activities are solely off-ice events, not to be confused with games or practices.'
        }
      },
      practices: {
        fields: {
          arenaId: 'Rink'
        }
      },
      events: {
        fields: {
          arenaId: 'Rink'
        }
      },
      draftGames: {
        fields: {
          arenaId: 'Rink',
        },
        messages: {
          match_columns: 'Select the corresponding teams and rinks to match with your selected columns.',
          publish_help: 'Publishing a draft will make the game public and available for scorekeeping. Published games require a date, time and rink to be set and must have a sequence available for game numbering.',
          confirm_clear_date: 'Are you sure you want to clear the date on %{smart_count} selected draft game? This will also reset the time and rink. |||| Are you sure you want to clear the date on %{smart_count} selected draft games? This will also reset the time and rink on these draft games.',
          change_dates_dialog: 'Changing the date will reset time and rink on these drafts.'
        },
      },
      games: {
        fields: {
          arenaId: 'Rink',
          effectiveSurfaces: 'Rink'
        },
        filters: {
          surfaceOffices: 'Rink Organization',
        },
        labels: {
          flags: {
            'Low Severity Penalties': 'Minor Penalty |||| Minor Penalties',
            'High Severity Penalties': 'Major Penalty |||| Major Penalties',
          }
        },
        messages: {
          group_games_by_arena: 'Group games by rink',
        },
        alerts: {
          assignment: {
            suggestions: {
              officials_requested_this_game_already: 'Some officials have requested this game or are already at this arena',
              officials_already_arena: 'Some officials are already at this arena',
              no_officials_this_arena: 'No officials are already at this arena, but some are ready',
            },
            assigner: {
              travel: {
                another_venue: 'This official will be <strong>at another arena</strong> within 1 hour of this game',
                this_venue: 'This official is assigned to this arena within 3 hours of this game',
              },
              unavailable: {
                no_availability_arena: 'Official has not included this arena in their availability settings',
              },
            },
            assignee: {
              travel: {
                another_venue: "You're <strong>assigned to another arena</strong> within 1 hour of this game",
                this_venue: "You're assigned to this arena within 3 hours of this game",
              },
              available: {
                assigned: "Your calendar indicates that you're available at this time and arena",
              },
              unavailable: {
                no_availability_arena: "You haven't included this arena in your availability settings",
              },
            }
          }
        },
        values: {
          status: {
            'Rink Changed': 'Rink Changed',
          },
          assignment: {
            position: {
              LinespersonTwo: 'Video Judge',
              LinespersonThree: 'Other Off-Ice Official',
            },
            position_short: {
              LinespersonTwo: 'VJ',
              LinespersonThree: 'OI',
            }
          }
        }
      },
      participants: {
        labels: {
          availability: {
            card: {
              subtitle: 'Select the dates and times that you are available to officiate games, or block out times that you are unavailable. Open Settings to select your assigners and arenas.'
            },
            venues_updated: 'Arenas updated',
            venues_error: 'Error updating arenas',
            venues_availabilities: {
              own_profile: {
                title: 'My Arenas',
                select_venues: "Select the arenas that you are willing to regularly travel to. If you don't select any, you'll be available for all arenas.",
              },
              other_profile: {
                title: "Official's Arenas",
                select_venues: 'Select the arenas that this official is available to officiate games at. If no arenas are selected, the official will be available for all arenas.',
              },
            }
          }
        },
        alerts: {
          availability: {
            selection: {
              title: 'Available to all arenas',
              own_profile: {
                available_to_all_arenas: "Search above if you would like to optionally limit which arenas you're available to.",
              },
              other_profile: {
                available_to_all_arenas: "Search above if you would like to optionally limit which arenas the official is available to.",
              },
            },
          }
        }
      },
      qualifications: {
        validations: {
          level: 'Must be greater than or equal to 0. "U9" is level 0.',
        },
        values: {
          level: {
            zero: 'U9',
            level_zero: 'U9',
          }
        }
      },
      assignSettings: {
        validations: {
          min_setting_range: 'Must be greater than or equal to 0. "U9" is level 0.',
        }
      },
      assignFees: {
        fields: {
          LinespersonTwo: 'Video Judge',
          LinespersonThree: 'Other Off-Ice Official',
        }
      },
      assignSystems: {
        fields: {
          linespersonsTwo: 'Video Judge',
          linespersonsThree: 'Other Off-Ice Official',
        }
      },
      officials: {
        filters: {
          ignore_arena: 'Ignore arena availability'
        }
      },
      officialConflicts: {
        labels: {
          card: {
            subtitle: "The following games are scheduled at the same time with the same teams or arena. If no active games are listed, this game's status may be changed to Active.",
          }
        }
      },
      offices: {
        labels: {
          tabs: {
            surfaces: 'Rinks'
          }
        }
      },
      leagues: {
        labels: {
          tabs: {
            surfaces: 'Rinks'
          }
        }
      },
      tournaments: {
        labels: {
          tabs: {
            surfaces: 'Rinks'
          }
        }
      },
      cups: {
        labels: {
          tabs: {
            surfaces: 'Rinks'
          }
        }
      },
      officeSettings: {
        fields: {
          skaterStatsHiddenDivisionIds: 'Skater Stats Hidden Divisions',
          gameLength: 'Default Rink Reservation Time',
          surfaceOfficeId: 'Rink Organization',
        },
        labels: {
          stats_schema: {
            skater: 'Skater Stats Schema',
          },
          standings_order: {
            skater: 'Skater Standings Order',
            diff: 'Goal Differential',
            minutesPlayed: 'Time On Ice',
          },
          hidden_stats: {
            skater: 'Hidden Skater Stats',
          },
        },
        helpers: {
          gameLength: 'Optional - Total duration (in minutes) reserved on a rink for all games of that schedule',
          surfaceOfficeId: "Optional - Rinks will come from this organization instead of the home team's organization in the draft scheduler",
        },
        values: {
          stats: {
            skater: 'Skater stats'
          }
        }
      },
      scheduleSettings: {
        fields: {
          surfaceSizes: 'Rink Sizes',
          gameLength: 'Default Rink Reservation Time',
          surfaceOfficeId: 'Rink Organization',
        },
        labels: {
          stats_schema: {
            skater: 'Skater Stats Schema',
          },
          standings_order: {
            skater: 'Skater Standings Order',
          },
          hidden_stats: {
            skater: 'Hidden Skater Stats',
          },
        },
        helpers: {
          gameLength: 'Optional - Total duration (in minutes) reserved on a rink for all games of that schedule',
          surfaceOfficeId: "Optional - Rinks will come from this organization instead of the home team's organization in the draft scheduler",
        },
      },
      teams: {
        fields: {
          arenaId: 'Rink'
        }
      },
      teamArenas: {
        name: 'Home Rink |||| Home Rinks',
        fields: {
          arenaId: 'Rink'
        },
        actions: {
          edit: 'Edit Home Rinks'
        },
        labels: {
          card: {
            title: 'Home Rinks',
            subtitle: 'Team home rinks will be used when drafting a schedule.'
          },
        },
      },
      arenaoffices: {
        fields: {
          arenaId: 'Rink'
        }
      },
      arenaslots: {
        fields: {
          arenaId: 'Rink'
        },
        labels: {
          card: {
            subtitle: 'Record your rink reservations to see your available time'
          }
        }
      },
      availabilities: {
        labels: {
          surface_conflicts: 'Arena Conflicts',
        }
      },
      drafts: {
        fields: {
          columns: {
            arenaId: 'Rink'
          }
        },
        labels: {
          no_venue: 'No rink'
        },
        messages: {
          share: {
            messages: {
              info: 'Associations will only be able to edit times and arenas for their own games and will not be able to publish the draft.'
            }
          }
        }
      },
      rulebooks: {
        labels: {
          skaters: 'Skaters',
        },
        values: {
          lineup_variances: {
            HC: 'Additional skater for U18 AAA, Junior, Senior'
          },
        }
      },
      scoresheets: {
        labels: {
          clock: 'Clock',
          penalty: {
            game_time: 'Off Time',
            start_time: 'Start Time',
          },
          goal: {
            game_time: 'Game Time',
          }
        },
        alerts: {
          requirements: {
            skaters: {
              min: 'At least %{min} skaters',
              max: 'Up to %{max} skaters',
            }
          }
        }
      }
    },
    components: {
      calendar: {
        messages: {
          no_home_surface: 'No home rinks have been configured for this team',
          no_result: 'No rinks found with the selected surface size filter',
          remove_time_field: 'Are you sure you want to remove the time and rink from this game?',
        }
      },
      exporter: {
        transactions_exporter: {
          reports: {
            details: {
              columns: {
                surface: "Rink"
              }
            }
          }
        }
      }
    },
  },
  Soccer: {
    resources: {
      surfaces: {
        name: 'Field |||| Fields',
        values: {
          size: {
            XL: 'XL (11x11)',
            L: 'L (11x11 or 9x9)',
            M: 'M (7x7 or 9x9)',
            S: 'S (7x7)',
            XS: 'XS (4x4)'
          },
        },
        actions: {
          add: 'Add Field',
          edit: 'Edit Field',
        },
        labels: {
          card: {
            title: 'Fields',
            splitTitle: 'Field Splits',
            splitSubtitle: 'A field can be split to allow multiple events at the same time',
          },
          alerts: {
            message: "This field is a split of"
          },
          edit: 'Update Field',
          add: 'New Field',
          name: 'Field Name',
        },
        messages: {
          surfaces: 'fields',
          availability: {
            available: 'This field %{input} be available',
          }
        },
      },
      activities: {
        fields: {
          arenaId: 'Field'
        },
        alerts: {
          activity_explanation: 'Activities are solely off-field events, not to be confused with games or practices.'
        }
      },
      practices: {
        fields: {
          arenaId: 'Field'
        }
      },
      events: {
        fields: {
          arenaId: 'Field'
        }
      },
      groups: {
        values: {
          type: {
            'League': 'Championship',
          }
        }
      },
      schedules: {
        values: {
          type: {
            'League': 'Championship',
          }
        }
      },
      schedulingConstraints: {
        values: {
          scheduleTypes: {
            'League': 'Championship',
          }
        }
      },
      assignRules: {
        values: {
          types: {
            'League': 'Championship',
          }
        },
        options: {
          minLinesperson: 'Assistant Level',
          minOfficial: '4th Referee Level',
        },
        labels: {
          rules: {
            minLinesperson: 'Assistant Level: %{value}',
            minOfficial: '4th Referee Level: %{value}',
          }
        }
      },
      reports: {
        values: {
          scheduleType: {
            'League': 'Championship',
          }
        }
      },
      draftGames: {
        fields: {
          arenaId: 'Field',
        },
        messages: {
          match_columns: 'Select the corresponding teams and fields to match with your selected columns.',
          publish_help: 'Publishing a draft will make the game public and available for scorekeeping. Published games require a date, time and field to be set and must have a sequence available for game numbering.',
          confirm_clear_date: 'Are you sure you want to clear the date on %{smart_count} selected draft game? This will also reset the time and field. |||| Are you sure you want to clear the date on %{smart_count} selected draft games? This will also reset the time and field on these draft games.',
          change_dates_dialog: 'Changing the date will reset the time and field on these drafts.'
        },
      },
      games: {
        fields: {
          arenaId: 'Field',
          effectiveSurfaces: 'Field'
        },
        filters: {
          surfaceOffices: 'Field Organization',
        },
        messages: {
          group_games_by_arena: 'Group games by field',
        },
        values: {
          status: {
            'Rink Changed': 'Field Changed',
          },
          assignment: {
            position: {
              Linesperson: 'Assistant 1',
              LinespersonTwo: 'Assistant 2',
              LinespersonThree: 'Assistant 3',
              Official: '4th Referee'
            },
            position_short: {
              Linesperson: 'A1',
              LinespersonTwo: 'A2',
              LinespersonThree: 'A3',
            }
          }
        }
      },
      gameAssignSettings: {
        fields: {
          minLinesperson: 'Minimum Assistant Level',
          minOfficial: 'Minimum 4th Referee Level',
        },
        labels: {
          linesperson: 'Assistant Level',
          official: '4th Referee Level'
        }
      },
      participants: {
        labels: {
          availability: {
            add_associations: 'Add organizations',
            assigner_availabilities: {
              own_profile: {
                select_associations: 'Select the organizations to make yourself available to officiate games for.',
              },
              other_profile: {
                select_associations: 'Select the organizations that this official is available to officiate games for.',
              },
            }
          }
        },
        messages: {
          availability: {
            search_by_association: 'Search by organization name',
          }
        },
        alerts: {
          availability: {
            loading_associations: 'Loading organizations...',
            no_associations: {
              title: 'No organizations selected',
              own_profile: {
                search_make_yourself_available: 'Search above to make yourself available to an organization.',
                not_available_to_assigners: 'You will not be available to any assigners or able to view any games until you select an organization.',
              },
              other_profile: {
                search_make_yourself_available: 'Search above to make the official available to an organization.',
                not_available_to_assigners: 'The official will not be available to any assigners or able to view any games until an organization is selected.',
              }
            },
          }
        }
      },
      assignFees: {
        fields: {
          Linesperson: 'Assistant 1',
          LinespersonTwo: 'Assistant 2',
          LinespersonThree: 'Assistant 3',
          Official: '4th Referee'
        }
      },
      assignSystems: {
        fields: {
          linespersons: 'Assistants 1',
          linespersonsTwo: 'Assistants 2',
          linespersonsThree: 'Assistants 3',
          officials: '4th Referee'
        }
      },
      offices: {
        labels: {
          tabs: {
            surfaces: 'Fields'
          }
        },
        values: {
          type: {
            Association: 'Club',
            Cup: 'Cup'
          }
        }
      },
      leagues: {
        labels: {
          tabs: {
            surfaces: 'Fields'
          }
        },
        values: {
          type: {
            Association: 'Club',
          }
        }
      },
      tournaments: {
        labels: {
          tabs: {
            surfaces: 'Fields'
          }
        },
        values: {
          type: {
            Association: 'Club',
          }
        }
      },
      cups: {
        labels: {
          tabs: {
            surfaces: 'Fields'
          }
        },
        values: {
          type: {
            Association: 'Club',
          }
        }
      },
      officeSettings: {
        fields: {
          skaterStatsHiddenDivisionIds: 'Outfield Player Stats Hidden Divisions',
          gameLength: 'Default Field Reservation Time',
          surfaceOfficeId: 'Field Organization',
        },
        labels: {
          stats_schema: {
            skater: 'Outfield Player Stats Schema',
          },
          standings_order: {
            skater: 'Outfield Player Standings Order',
            penaltyOccurrences: 'Total Number of Cards',
            diff: 'Goal Differential',
          },
          hidden_stats: {
            skater: 'Hidden Outfield Player Stats',
          },
        },
        helpers: {
          gameLength: 'Optional - Total duration (in minutes) reserved on a field for all games of that schedule',
          surfaceOfficeId: "Optional - Fields will come from this organization instead of the home team's organization in the draft scheduler",
        },
        values: {
          stats: {
            skater: 'Outfield Player stats'
          }
        }
      },
      scheduleSettings: {
        fields: {
          surfaceSizes: 'Field Sizes',
          gameLength: 'Default Field Reservation Time',
          surfaceOfficeId: 'Field Organization',
        },
        labels: {
          stats_schema: {
            skater: 'Outfield Player Stats Schema',
          },
          standings_order: {
            skater: 'Outfield Player Standings Order',
          },
          hidden_stats: {
            skater: 'Hidden Outfield Player Stats',
          },
        },
        helpers: {
          gameLength: 'Optional - Total duration (in minutes) reserved on a field for all games of that schedule',
          includeRegistrations: "Registered players based on the team's club, division and season will be included as lineup options.",
          surfaceOfficeId: "Optional - Fields will come from this organization instead of the home team's organization in the draft scheduler",
        }
      },
      teams: {
        fields: {
          arenaId: 'Field'
        }
      },
      teamArenas: {
        name: 'Home Field |||| Home Fields',
        fields: {
          arenaId: 'Field'
        },
        actions: {
          edit: 'Edit Home Fields'
        },
        labels: {
          card: {
            title: 'Home Fields',
            subtitle: 'Team home fields will be used when drafting a schedule.'
          },
        },
      },
      arenaoffices: {
        fields: {
          arenaId: 'Field'
        }
      },
      arenaslots: {
        fields: {
          arenaId: 'Field'
        },
        labels: {
          card: {
            subtitle: 'Record your field reservations to see your available time',
          }
        }
      },
      availabilities: {
        labels: {
          surface_conflicts: 'Field Conflicts',
        }
      },
      profiles: {
        alerts: {
          private_profile: 'This profile will only appear to authorized league and club administrators, and will not appear on league websites.',
        }
      },
      drafts: {
        fields: {
          columns: {
            arenaId: 'Field'
          }
        },
        labels: {
          no_venue: 'No field'
        },
        messages: {
          share: {
            messages: {
              list: 'This draft will be shared with the following clubs:',
              info: 'Clubs will only be able to edit times and venues for their own games and will not be able to publish the draft.'
            }
          }
        }
      },
      rulebooks: {
        labels: {
          skaters: 'Outfield Players',
        },
        values: {
          lineup_variances: {
            HC: 'Additional outfield player for U18 AAA, Junior, Senior'
          },
        }
      },
      help: {
        values: {
          roles: {
            association: 'Clubs'
          }
        }
      },
      scoresheets: {
        actions: {
          add_penalties: 'Add Cards',
        },
        labels: {
          add_penalties: 'Add Cards',
          clock: 'Minute',
          penalty: {
            game_time: 'Minute'
          },
          goal: {
            game_time: 'Minute',
          },
          home: {
            penalty: 'Home Card',
          },
          away: {
            penalty: 'Away Card',
          },
        },
        messages: {
          end_game: 'At what minute did the game end at?'
        },
        alerts: {
          requirements: {
            skaters: {
              min: 'At least %{min} outfield Players',
              max: 'Up to %{max} outfield players',
            }
          }
        }
      },
      gamepenalties: {
        name: 'Cards',
        fields: {
          gameTime: 'Minute',
        },
      },
      suspensions: {
        fields: {
          penaltyId: 'Card',
        }
      },
      gameIncidents: {
        fields: {
          penaltyId: 'Card',
        },
      },
      gameEvents: {
        values: {
          eventType: {
            penaltyStarted: 'Card Added',
            penaltyEdited: 'Card Updated',
            penaltyDeleted: 'Card Deleted',
          }
        }
      },
      members: {
        values: {
          positions: {
            'F': 'Player',
          }
        }
      }
    },
    components: {
      login: {
        validation: {
          game_already_completed_long: 'This game has already been completed. For corrections, please contact your club.',
        }
      },
      calendar: {
        messages: {
          no_home_surface: 'No home fields have been configured for this team',
          no_result: 'No fields found with the selected surface size filter',
          remove_time_field: 'Are you sure you want to remove the time and field from this game?',
        }
      },
      exporter: {
        transactions_exporter: {
          reports: {
            details: {
              columns: {
                surface: "Field"
              }
            }
          }
        },
        penalties_exporter: {
          reports: {
            summary: {
              columns: {
                game_time: 'Minute'
              }
            }
          },
        }
      }
    }
  },
  Baseball: {
    resources: {
      surfaces: {
        name: 'Field |||| Fields',
        values: {
          size: {
            XL: 'XL (18U+)',
            L: 'L (15U)',
            M: 'M (13U)',
            S: 'S (11U)',
            XS: 'XS (9U)'
          },
        },
        actions: {
          add: 'Add Field',
          edit: 'Edit Field',
        },
        labels: {
          card: {
            title: 'Fields',
            splitTitle: 'Field Splits',
            splitSubtitle: 'A field can be split to allow multiple events at the same time',
          },
          alerts: {
            message: "This field is a split of"
          },
          edit: 'Update Field',
          add: 'New Field',
          name: 'Field Name',
        },
        messages: {
          surfaces: 'fields',
          availability: {
            available: 'This field %{input} be available',
          }
        },
      },
      activities: {
        fields: {
          arenaId: 'Field'
        },
        alerts: {
          activity_explanation: 'Activities are solely off-field events, not to be confused with games or practices.'
        }
      },
      practices: {
        fields: {
          arenaId: 'Field'
        }
      },
      events: {
        fields: {
          arenaId: 'Field'
        }
      },
      schedules: {
        values: {
          type: {
            'League': 'Regular Season',
          }
        }
      },
      schedulingConstraints: {
        values: {
          scheduleTypes: {
            'League': 'Regular Season',
          }
        }
      },
      assignRules: {
        values: {
          types: {
            'League': 'Regular Season',
          }
        },
        options: {
          minReferee: 'Plate Umpire Level',
          minLinesperson: 'Base Umpire Level',
        },
        labels: {
          rules: {
            minReferee: 'Plate Umpire Level: %{value}',
            minLinesperson: 'Base Umpire Level: %{value}',
          }
        }
      },
      reports: {
        values: {
          scheduleType: {
            'League': 'Regular Season',
          }
        }
      },
      seasons: {
        values: {
          seasonId: {
            '2025-26': '2025',
            '2024-25': '2024',
            '2023-24': '2023',
            '2022-23': '2022',
            '2021-22': '2021',
            '2020-21': '2020',
            '2019-20': '2019',
            '2018-19': '2018',
          }
        }
      },
      draftGames: {
        fields: {
          arenaId: 'Field',
        },
        messages: {
          match_columns: 'Select the corresponding teams and fields to match with your selected columns.',
          publish_help: 'Publishing a draft will make the game public and available for scorekeeping. Published games require a date, time and field to be set and must have a sequence available for game numbering.',
          confirm_clear_date: 'Are you sure you want to clear the date on %{smart_count} selected draft game? This will also reset the time and field. |||| Are you sure you want to clear the date on %{smart_count} selected draft games? This will also reset the time and field on these draft games.',
          change_dates_dialog: 'Changing the date will reset the time and field on these drafts.'
        },
      },
      games: {
        fields: {
          arenaId: 'Field',
          effectiveSurfaces: 'Field'
        },
        filters: {
          surfaceOffices: 'Field Organization',
        },
        labels: {
          flags: {
            'High Severity Penalties': 'Ejection |||| Ejections',
          }
        },
        messages: {
          group_games_by_arena: 'Group games by field',
        },
        values: {
          status: {
            'Rink Changed': 'Field Changed',
          },
          assignment: {
            position: {
              Referee: 'Plate Umpire',
              Linesperson: 'Base Umpire',
              LinespersonTwo: '2nd Base Umpire',
              LinespersonThree: '3rd Base Umpire',
            },
            position_short: {
              Referee: 'PU',
              Linesperson: 'BU',
              LinespersonTwo: 'BU2',
              LinespersonThree: 'BU3',
            }
          }
        }
      },
      offices: {
        labels: {
          tabs: {
            surfaces: 'Fields'
          }
        }
      },
      leagues: {
        labels: {
          tabs: {
            surfaces: 'Fields'
          }
        }
      },
      tournaments: {
        labels: {
          tabs: {
            surfaces: 'Fields'
          }
        }
      },
      cups: {
        labels: {
          tabs: {
            surfaces: 'Fields'
          }
        }
      },
      officeSettings: {
        fields: {
          gameLength: 'Default Field Reservation Time',
          surfaceOfficeId: 'Field Organization',
        },
        helpers: {
          gameLength: 'Optional - Total duration (in minutes) reserved on a field for all games of that schedule',
          surfaceOfficeId: "Optional - Fields will come from this organization instead of the home team's organization in the draft scheduler",
          incidentReports: 'Games will require incident reports for all ejections prior to completion'
        }
      },
      scheduleSettings: {
        fields: {
          surfaceSizes: 'Field Sizes',
          gameLength: 'Default Field Reservation Time',
          surfaceOfficeId: 'Field Organization',
        },
        helpers: {
          gameLength: 'Optional - Total duration (in minutes) reserved on a field for all games of that schedule',
          surfaceOfficeId: "Optional - Fields will come from this organization instead of the home team's organization in the draft scheduler",
        }
      },
      teams: {
        fields: {
          arenaId: 'Field'
        }
      },
      teamArenas: {
        name: 'Home Field |||| Home Fields',
        fields: {
          arenaId: 'Field'
        },
        actions: {
          edit: 'Edit Home Fields'
        },
        labels: {
          card: {
            title: 'Home Fields',
            subtitle: 'Team home fields will be used when drafting a schedule.'
          },
        },
      },
      arenaoffices: {
        fields: {
          arenaId: 'Field'
        }
      },
      arenaslots: {
        fields: {
          arenaId: 'Field'
        },
        labels: {
          card: {
            subtitle: 'Record your field reservations to see your available time',
          }
        }
      },
      availabilities: {
        labels: {
          surface_conflicts: 'Field Conflicts',
        }
      },
      drafts: {
        values: {
          draft_type: {
            'Single Rotation': 'One Game Per Round',
            'Double Rotation': 'Two Games Per Round',
          },
        },
        fields: {
          columns: {
            arenaId: 'Field'
          }
        },
        labels: {
          no_venue: 'No field'
        },
      },
      gameAssignSettings: {
        fields: {
          minReferee: 'Minimum Plate Umpire Level',
          minLinesperson: 'Minimum Base Umpire Level',
        },
        labels: {
          referee: 'Plate Umpire Level',
          linesperson: 'Base Umpire Level',
        }
      },
      assignFees: {
        fields: {
          Referee: 'Plate Umpire',
          Linesperson: 'Base Umpire',
          LinespersonTwo: '2nd Base Umpire',
          LinespersonThree: '3rd Base Umpire',
        }
      },
      assignSystems: {
        fields: {
          referees: 'Plate Umpire',
          linespersons: 'Base Umpire',
          linespersonsTwo: '2nd Base Umpire',
          linespersonsThree: '3rd Base Umpire',
        }
      },
      scoresheets: {
        actions: {
          add_penalties: 'Add Ejections',
        },
        labels: {
          add_penalties: 'Add Ejections',
          home: {
            penalty: 'Home Ejection',
          },
          away: {
            penalty: 'Away Ejection',
          }
        },
        messages: {
          end_game: 'At what inning did the game end at?'
        },
        values: {
          half: {
            first: 'Top',
            second: 'Bottom'
          }
        },
        alerts: {
          missingIncidents: {
            message: 'Some ejections require an incident report which must be submitted before the game can be completed.',
          }
        }
      },
      members: {
        values: {
          positions: {
            'F': 'Player',
          }
        }
      },
      notifications: {
        fields: {
          majorPenalty: 'Ejections',
        },
        helpers: {
          majorPenalty: 'Scoresheets with ejections will be sent after game completion',
        }
      },
      gamepenalties: {
        name: "Ejection |||| Ejections",
      },
      suspensions: {
        fields: {
          penaltyId: 'Ejection',
        },
      },
      gameIncidents: {
        fields: {
          penaltyId: 'Ejection',
        },
        labels: {
          penaltyId: 'Ejection',
        },
      },
    },
    components: {
      calendar: {
        messages: {
          no_home_surface: 'No home fields have been configured for this team',
          no_result: 'No fields found with the selected surface size filter',
          remove_time_field: 'Are you sure you want to remove the time and field from this game?',
        }
      },
      exporter: {
        transactions_exporter: {
          reports: {
            details: {
              columns: {
                surface: "Field"
              }
            }
          }
        }
      }
    }
  }
}

export default messages;
