import React from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import FunctionField from './FunctionField';

const renderUpdatedAt = (record, translate, includePrefix = false, typographyProps = {}) => {
  const { updatedAt, updatedByAuthor } = record || {};
  const date = moment(updatedAt).calendar()
  if (!updatedAt) return null;

  const prefix = includePrefix ? `${translate('ra.message.updated')} ` : ''
  if (!updatedByAuthor) return `${prefix}${date}`;
  return <Typography {...typographyProps}>{`${prefix}${date} ${translate('ra.function.by')} ${updatedByAuthor}`}</Typography>
}

export const UpdatedAtField = ({ includePrefix = false, typographyProps, ...props }) => {
  const translate = useTranslate();
  return <FunctionField source="updatedAt" render={(record) => renderUpdatedAt(record, translate, includePrefix, typographyProps)} {...props} />
}
