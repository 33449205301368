import React, { Fragment, useState } from 'react';
import { Datagrid, useDataProvider, useListContext, useNotify, useRecordContext, useTranslate, BooleanInput } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';

import { isEmpty } from '@hisports/common/src/_.lod.cjs';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import useSyncedPreferences from '../../../common/useSyncedPreferences';

import ImportLists from '../../lists/dialogs/officials';
import { ListGrid } from '../../lists/ListGrid';
import { AddListButton } from '../../lists/ListModal';
import { SelectOfficialsButton } from '../../officials/SelectOfficialsDialog';
import { ParticipantField } from '../../participants/ParticipantField';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.error.main,
  },
}));

const filterProps = {
  variant: 'outlined',
  options: {
    helperText: undefined,
    style: { marginRight: 0 }
  },
  helperText: false,
  margin: 'normal',
  style: { marginLeft: 10, marginBottom: 10 }
}

const DeleteButton = ({ onDelete }) => {
  const { selectedIds, refetch, onUnselectItems } = useListContext();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  const handleDelete = async () => {
    await Promise.all(selectedIds.map(id => dataProvider.delete('listMembers', { id })))
      .then(() => {
        refetch();
        onUnselectItems();
        notify(translate('resources.listMembers.notifications.list_officials_removed', selectedIds?.length));
      })
      .catch(() => notify(translate('resources.listMembers.notifications.remove_list_officials_error')))
      .finally(() => onDelete());
  }

  if (!selectedIds?.length) return null;

  return <Button className={classes.deleteButton} size="small" onClick={handleDelete}>{translate('ra.action.delete')}</Button>
}

const ManageButton = ({ isEditing, onManage }) => {
  const { data, onUnselectItems } = useListContext();
  const translate = useTranslate();

  const handleManage = () => {
    if (isEditing) {
      onUnselectItems();
    }
    onManage();
  };

  if (isEmpty(data)) return null;

  return <Button color="primary" size="small" onClick={handleManage}>
    {translate(`ra.action.${isEditing ? 'cancel' : 'manage'}`)}
  </Button>
}

const MembersGrid = (props) => {
  return <Datagrid {...props}>
    <ParticipantField source="participantId" includeId="inline" link={(record, reference) => `/${reference}/${record.participantId}/show/officiating`} />
  </Datagrid>
}

const MembersList = (props) => {
  const list = useRecordContext(props);
  const [isEditing, setIsEditing] = useState(false);

  const { id: listId, officeId } = list;

  const canAddListMembers = isAuthorized(list, 'listMembers', 'create');
  const canManageListMembers = isAuthorized(list, 'listMembers', 'edit');

  const actions = [
    canManageListMembers && <>
      <DeleteButton onDelete={() => setIsEditing(false)} />
      <ManageButton isEditing={isEditing} onManage={() => setIsEditing(!isEditing)} />
    </>,
  ].filter(Boolean);

  return <ListCard {...props}
    reference="listMembers"
    resource="listMembers"
    target="listId"
    sort={{
      field: ['participant.firstName', 'participant.lastName'],
      order: ['ASC', 'ASC'],
    }}
    addButton={canAddListMembers && <SelectOfficialsButton initialValues={{ listId, officeId, includeSubOffices: true }} />}
    actions={actions}
    component={Fragment}
    hasBulkActions={isEditing}
  >
    <MembersGrid rowClick={isEditing ? "toggleSelection" : false} />
  </ListCard>
}

export default (props) => {
  const office = useRecordContext(props);
  const [effectiveOfficials, setEffectiveOfficials] = useSyncedPreferences('effectiveOfficials', true);

  const showLists = isAuthorized(office, 'lists', 'list');
  const canAddLists = isAuthorized(office, 'lists', 'create');
  const canImportMembers = isAuthorized(office, 'lists', 'import');

  const officeId = office?.id;

  const listFilters = { officeId, effectiveOffices: effectiveOfficials, includeParents: false, _scope: 'Parents' }

  const actions = [
    canImportMembers && <ImportLists officeId={officeId} size="small" />,
  ].filter(Boolean)
  const filters = [
    <BooleanInput key="effective-offices" source="effectiveOffices" label="resources.officialOffices.filters.effectiveOfficials" alwaysOn onChange={setEffectiveOfficials} {...filterProps} />
  ]

  if (!showLists) return null;
  return <ListCard
    title="resources.lists.labels.official"
    subtitle="resources.lists.labels.card.subtitle"
    icon={<FilterList />}
    collapsable
    reference="lists"
    target="officeId"
    filter={listFilters}
    filters={filters}
    sort={{ field: 'name', order: 'ASC' }}
    addButton={canAddLists && <AddListButton initialValues={{ officeId }} />}
    actions={actions}
  >
    <ListGrid expand={<MembersList />} showOffice />
  </ListCard>
}
