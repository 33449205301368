export const SEASONS = [
  '2025-26',
  '2024-25',
  '2023-24',
  '2022-23',
  '2021-22',
  '2020-21',
  '2019-20',
  '2018-19',
]

export const LATEST_SEASON = SEASONS[0];

export const getSeasonDisplayName = (seasonId, sport) => {
  if (sport == 'Baseball') {
    return seasonId.substring(0, 4);
  }

  return seasonId;
}
