import React, { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Table, TableHead, TableCell, TableBody, TableRow, Grid, Button } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { styled } from '@material-ui/styles';

import { getLineupPlayers, getThrowsInnings } from '@hisports/scoresheet/src/selectors';

import { useSport } from '../../../http';
import { isAuthorized } from '../../../common/Authorize';
import InfoCard from '../../../common/cards/InfoCard';

import { useScoresheet } from '../ScoresheetContext';
import { ThrowsInningsModal } from './ThrowsInningsModal';
import { Alert } from '@material-ui/lab';

const Player = styled(TableCell)(({ theme }) => ({
  minWidth: theme.spacing(12),
  padding: theme.spacing(1),
}))

const Throw = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  width: theme.spacing(15),
  padding: theme.spacing(1),
}))

const Inning = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  width: theme.spacing(15),
  padding: theme.spacing(1),
}))

const ThrowsInnings = ({ teamId }) => {
  const translate = useTranslate();
  const sport = useSport();

  const playerLineup = useScoresheet(scoresheet => getLineupPlayers(scoresheet, { teamId, affiliatesLast: false, sport, hideUnsaved: true }))
  const playerThrowsInnings = useScoresheet(scoresheet => getThrowsInnings(scoresheet, { teamId }))

  const throws = (player) => {
    const players = playerThrowsInnings.find(throws => throws.participantId === player.participantId)
    return players?.throws ?? '—'
  }

  const inningsPitched = (player) => {
    const players = playerThrowsInnings.find(inningsPitched => inningsPitched.participantId === player.participantId)
    return players?.inningsPitched ?? '—'
  }

  return <Table selectable={false} fullWidth>
    <TableHead displaySelectAll={false} adjustForCheckbox={false}>
      <TableRow>
        <Player>{translate('components.throws_innings.labels.player')}</Player>
        <Throw>{translate('components.throws_innings.labels.throws')}</Throw>
        <Inning>{translate('components.throws_innings.labels.innings')}</Inning>
      </TableRow>
    </TableHead>
    <TableBody displayRowCheckbox={false}>
      {playerLineup.map(player =>
        <TableRow key={player.participantId}>
          <Player>#{player.number} {player.participant.fullName}</Player>
          <Throw>{throws(player)}</Throw>
          <Inning>{inningsPitched(player)}</Inning>
        </TableRow>
      )}
    </TableBody>
  </Table>
}

const EditThrowsInningsButton = ({ game, teamId }) => {
  const translate = useTranslate();
  const [throwsInningsOpen, setThrowsInningsOpen] = useState(false);

  return <>
    <Button onClick={() => setThrowsInningsOpen(true)} startIcon={<Create fontSize="small" />}>{translate('components.throws_innings.labels.edit')}</Button>
    <ThrowsInningsModal game={game} teamId={teamId} open={throwsInningsOpen} onClose={() => setThrowsInningsOpen(false)} />
  </>
}

export const ThrowsInningsCard = ({ teamId, isScorekeeper, canEdit = false }) => {
  const game = useRecordContext();
  const translate = useTranslate();
  const canEditThrowsInnings = (isAuthorized(game, 'games', 'throwsInnings') || isScorekeeper) && !game.isCertified && canEdit;

  return <InfoCard title="components.throws_innings.name" actions={canEditThrowsInnings && <EditThrowsInningsButton game={game} teamId={teamId} />}>
    {!canEditThrowsInnings && <Alert severity={game.isCertified ? "info" : "warning"}>{game.isCertified ? translate('resources.scoresheets.alerts.lineup_throwsinnings_certified'): translate('resources.scoresheets.alerts.lineup_throwsinnings')}</Alert>}
    <Grid container spacing={3} xs={12}>
      <Grid item xs={12}>
        <ThrowsInnings teamId={teamId} />
      </Grid>
    </Grid>
  </InfoCard>
}
