import React from 'react';
import { BooleanInput, TextInput, useTranslate } from "react-admin";
import { FormControl, MenuItem, Select, makeStyles } from "@material-ui/core";

import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import { isAuthorized } from '../../Authorize';
import { InlineDateInput } from "../../inputs/DateInput";
import { GameStatusEnumInput } from '../../inputs/EnumInputs';
import { CALENDAR_VIEWS, GAME_LIST_FILTERS, useGameListFilter } from '../../../resources/events/EventViewSettings';
import { OfficeInput } from '../../../resources/offices/OfficeInput';
import { CategoryInput } from '../../../resources/categories/CategoryInput';
import { TeamInput } from '../../../resources/teams/TeamInput';
import { GroupInput, hasGroups } from '../../../resources/groups/GroupInput';
import { SurfaceInput } from '../../../resources/surfaces/SurfaceInput';

const filterProps = {
  variant: 'outlined',
  options: {
    helperText: undefined,
    style: { marginRight: 0 }
  },
  helperText: false,
  margin: 'normal',
}

const multipleProps = {
  multiple: true,
  allowEmpty: false,
}

const allOfficeFilter = {
  type: { neq: 'Historical' }
}

const localOfficeFilter = {
  type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] }
}

const useStyles = makeStyles(theme => ({
  listFilterDiv: {
    flexGrow: 1,
    padding: theme.spacing(1.5)
  },
  listFilter: {
    width: '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
    }
  },
}))

export const getGameListFilters = (office, schedule, groups, scopes, seasonId, calendarView) => {
  const canListTeams = isAuthorized(scopes, 'teams', 'list');
  const canListOffices = isAuthorized(scopes, 'offices', 'show');
  const canListSurfaces = isAuthorized(scopes, 'surfaces', 'list');

  const scheduleId = schedule?.id;
  const scheduleType = schedule?.type;
  const officeId = schedule?.officeId || office?.id;
  const categoryIds = schedule && [schedule.categoryId];
  const scheduleHasGroups = hasGroups(groups, schedule?.officeId, scheduleType)
  const teamFilter = {
    seasonId,
    ...(schedule ? { scheduleId } : {}),
    ...(office && !['League', 'Tournament', 'Cup'].includes(office?.type) ? { officeId } : {})
  }

  if (calendarView === CALENDAR_VIEWS.SEASON) {
    return [
      canListOffices && <OfficeInput source="homeTeamOffices" label="resources.games.filters.homeTeamOffices" filter={localOfficeFilter} {...filterProps} {...multipleProps} />,
      canListOffices && <OfficeInput source="awayTeamOffices" label="resources.games.filters.awayTeamOffices" filter={localOfficeFilter} {...filterProps} {...multipleProps} />,
      <BooleanInput source="conflictsOnly" label="resources.games.filters.conflictsOnly" variant="outlined" />,
    ].filter(Boolean)
  }

  return [
    <TextInput source="number" alwaysOn {...filterProps} />,
    <InlineDateInput source="startTime" label="resources.draftGames.filters.startTime" alwaysOn {...filterProps} />,
    canListSurfaces && <SurfaceInput source="effectiveSurfaces" filter={{ parentId: null }} alwaysOn {...filterProps} {...multipleProps} />,
    canListTeams && <TeamInput source="teamId" label="resources.teams.fields.teamId" filter={teamFilter} alwaysOn {...filterProps} {...multipleProps} />,
    office && <CategoryInput source="categoryId" alwaysOn {...filterProps} />,
    schedule && scheduleHasGroups && <GroupInput
      select
      multiple
      source="groupId"
      scheduleId={scheduleId}
      scheduleType={scheduleType}
      officeId={officeId}
      categoryIds={categoryIds}
      allowEmpty
      alwaysOn
      {...filterProps}
    />,
    <GameStatusEnumInput source="status" alwaysOn {...multipleProps} {...filterProps} />,
    canListTeams && <TeamInput source="homeTeamId" label="resources.games.fields.homeTeamId" filter={teamFilter} {...filterProps} {...multipleProps} />,
    canListTeams && <TeamInput source="awayTeamId" label="resources.games.fields.awayTeamId" filter={teamFilter} {...filterProps} {...multipleProps} />,
    canListOffices && office && <OfficeInput source="scheduleOffices" label="resources.games.filters.scheduleOffices" filter={allOfficeFilter} {...filterProps} {...multipleProps} />,
    canListOffices && <OfficeInput source="teamOffices" label="resources.games.filters.teamOffices" filter={localOfficeFilter} {...filterProps} {...multipleProps} />,
    canListOffices && <OfficeInput source="homeTeamOffices" label="resources.games.filters.homeTeamOffices" filter={localOfficeFilter} {...filterProps} {...multipleProps} />,
    canListOffices && <OfficeInput source="awayTeamOffices" label="resources.games.filters.awayTeamOffices" filter={localOfficeFilter} {...filterProps} {...multipleProps} />,
    canListOffices && <OfficeInput source="surfaceOffices" label="resources.games.filters.surfaceOffices" filter={localOfficeFilter} {...filterProps} {...multipleProps} />,
    <BooleanInput source="conflictsOnly" label="resources.games.filters.conflictsOnly" variant="outlined" />,
    <BooleanInput source="bracketGamesOnly" label="resources.games.filters.bracketGamesOnly" variant="outlined" />,
  ].filter(Boolean)
}

export const GameListFilterInput = props => {
  const [ gameListFilter, setGameListFilter ] = useGameListFilter()
  const translate = useTranslate();
  const classes = useStyles();

  const handleChange = e => {
    setGameListFilter(e.target.value);
  }

  return <div className={classes.listFilterDiv}>
    <FormControl className={classes.listFilter}>
      <Select
        value={gameListFilter}
        onChange={handleChange}
        {...props}
      >
        {Object.values(GAME_LIST_FILTERS).map(FILTER => {
          return <MenuItem value={FILTER}>{translate(`components.calendar.values.game_list_filters.${FILTER}`)}</MenuItem>
        })}
      </Select>
    </FormControl>
  </div>
}
