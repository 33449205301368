import React from 'react';
import { Datagrid } from 'react-admin';

import DateRangeField from '../../common/fields/DateRangeField';
import { EnumField } from '../../common/inputs/EnumInputs';
import { NameWithStatusesField } from '../../common/fields/NameWithStatusesField';

import { CategoryField } from '../categories/CategoryField';
import { OfficeField } from '../offices/OfficeField';

export const ScheduleGrid = ({ showOffice = true, ...props }) =>
  <Datagrid size="medium" {...props}>
    <NameWithStatusesField source="name" />
    <CategoryField source="categoryId" />
    <EnumField source="type" />

    <DateRangeField source="startDate" startSource="startDate" endSource="endDate" />

    {showOffice && <OfficeField source="officeId" />}
  </Datagrid>
