import React from 'react';
import { FunctionField, ReferenceField, TextField } from 'react-admin';
import { Chip, withStyles } from '@material-ui/core';

const CustomChip = withStyles(theme => ({
  root: {
    height: theme.spacing(2.5),
    fontSize: '0.725rem',
    lineHeight: '1rem',
  },
}))(Chip);

export const PoolChipField = ({ variant = 'default', color = 'default', ...props }) =>
  <ReferenceField basePath="/pools" resource="pools" reference="pools" link={false} allowEmpty {...props}>
    <FunctionField source="name" render={record => <CustomChip label={record.name} size="small" color={color} variant={variant} />} />
  </ReferenceField>

export const PoolField = ({ variant = 'body2', ...props }) =>
  <ReferenceField basePath="/pools" resource="pools" reference="pools" link={false} allowEmpty {...props}>
    <TextField source="name" variant={variant} />
  </ReferenceField>

PoolField.defaultProps = {
  addLabel: true,
}
