import React, { useEffect, useMemo } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { translateApiProperty } from '@hisports/common';
import { groupBy, sortBy, get } from '@hisports/common/src/lodash';

const useStyles = makeStyles(theme => ({
  group: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
  },
  outerAccordion: {
    marginBottom: theme.spacing(1),
    boxShadow: 'none',
    '&:hover': {
      border: `1px solid rgba(0, 0, 0, 0.87)`,
    },
    border: props => props.hasError ? '1px solid red' : `1px solid rgba(0, 0, 0, 0.23)`,
  },
  innerAccordion: {
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    '&:hover': {
      border: `1px solid rgba(0, 0, 0, 0.87)`,
    },
    boxShadow: 'none',
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  errorMessage: {
    marginLeft: 14,
  },
  disabled: {
    color: props => props?.disabled ? 'rgba(0, 0, 0, 0.38)' : undefined,
  }
}))

const OuterAccordionTitle = ({ selectedCount = 0, categoryCount = 0, handleToggleAll, disabled }) => {
  const translate = useTranslate();
  const classes = useStyles({ disabled });

  const isAllSelected = selectedCount === categoryCount;

  return <Box className={classes.titleBox}>
    <FormControlLabel
      disabled={disabled}
      control={
        <Checkbox
          checked={isAllSelected}
          indeterminate={selectedCount > 0 && selectedCount < categoryCount}
          onChange={() => handleToggleAll(isAllSelected)}
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.stopPropagation()}
        />
      }
      label={
        <Typography variant="body2">
          {translate('resources.categories.name', 2)}
        </Typography>
      }
    />
    <Typography variant="body2" color="textSecondary" className={classes.disabled}>
      {`${selectedCount} / ${categoryCount}`}
    </Typography>
  </Box>
}

const DivisionTitle = ({ selectedCount, divisionCategories, handleToggleAllInDivision, division, disabled }) => {
  const classes = useStyles({ disabled });

  const isAllSelected = selectedCount === divisionCategories.length;

  return <Box className={classes.titleBox}>
    <FormControlLabel
      disabled={disabled}
      control={
        <Checkbox
          checked={isAllSelected}
          indeterminate={selectedCount > 0 && selectedCount < divisionCategories.length}
          onChange={() => handleToggleAllInDivision(divisionCategories, isAllSelected)}
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.stopPropagation()}
        />
      }
      label={
        <Typography variant="body2">
          {division}
        </Typography>
      }
    />
    <Typography variant="body2" color="textSecondary" className={classes.disabled}>
      {`${selectedCount} / ${divisionCategories.length}`}
    </Typography>
  </Box>
}

const SelectableCategory = ({ category, selected, handleCategoryToggle, disabled }) => {
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles({ disabled });

  return <FormControlLabel
    key={category.id}
    disabled={disabled}
    control={
      <Checkbox
        checked={selected?.includes(category.id)}
        onChange={() => handleCategoryToggle(category.id)}
      />
    }
    label={
      <div className={classes.group}>
        <Typography variant="body2" color="textPrimary" className={classes.disabled}>
          {translateApiProperty(category, 'name', locale)}
        </Typography>
        <Typography variant="body2" color="textSecondary" className={classes.disabled}>
          {translate(`resources.categories.values.gender.${category.gender}`)}
        </Typography>
      </div>
    }
  />
}

export const SelectableCategoriesInput = ({ categories = [], source, disabled = false }) => {
  const locale = useLocale();
  const translate = useTranslate();
  const { values, errors, submitFailed } = useFormState();
  const { change } = useForm();

  const fieldError = get(errors, source);
  const hasError = submitFailed && fieldError;

  const classes = useStyles({ hasError });

  const selected = useMemo(() => get(values, source, []), [values, source]);

  const groupedByDivision = useMemo(() => {
    const sorted = sortBy(categories, ['division.order', 'order', 'name']);
    return groupBy(sorted, category => translateApiProperty(category.division, 'name', locale));
  }, [categories, locale]);

  useEffect(() => {
    // set initial value if not set
    if (get(values, source) == null) {
      change(source, []);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleCategoryToggle = (categoryId) => {
    const newSelection = selected.includes(categoryId)
      ? selected.filter(id => id !== categoryId)
      : [...selected, categoryId];

    change(source, newSelection);
  };

  const handleToggleAllInDivision = (divisionCategories, isAllSelected) => {
    const divisionCategoryIds = divisionCategories.map(category => category.id);

    const newSelection = isAllSelected
      ? selected.filter(id => !divisionCategoryIds.includes(id))
      : [...selected, ...divisionCategoryIds.filter(id => !selected.includes(id))];

    change(source, newSelection);
  };

  const handleToggleAll = (isAllSelected) => {
    const categoryIds = categories.map(category => category.id);

    const newSelection = isAllSelected
      ? []
      : [...categoryIds];

    change(source, newSelection);
  }

  if (!categories?.length) return null;

  return (
    <Box>
      <Accordion className={classes.outerAccordion} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />} className={classes.accordionSummary}>
          <OuterAccordionTitle selectedCount={selected.length} categoryCount={categories.length} handleToggleAll={handleToggleAll} disabled={disabled} />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {Object.keys(groupedByDivision).map((division) => {
              const divisionCategories = groupedByDivision[division] || [];
              const selectedCount = divisionCategories.filter(category => selected?.includes(category.id)).length;

              if (!divisionCategories.length) return null;
              return <Grid item xs={12} md={6} key={division}>
                <Accordion key={division} className={classes.innerAccordion} TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary expandIcon={<ExpandMore />} className={classes.accordionSummary} >
                    <DivisionTitle selectedCount={selectedCount} divisionCategories={divisionCategories} handleToggleAllInDivision={handleToggleAllInDivision} division={division} disabled={disabled} />
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={0}>
                      {divisionCategories.map((category) => (
                        <Grid item xs={12} md={6} key={category.id}>
                          <SelectableCategory category={category} selected={selected} handleCategoryToggle={handleCategoryToggle} disabled={disabled} />
                        </Grid>
                      ))
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            }
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {submitFailed && fieldError && (
        <Typography variant="caption" color="error" className={classes.errorMessage}>
          {translate(fieldError)}
        </Typography>
      )}
    </Box>
  );
};
