import React, { useRef } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import { LayersOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';

import { GroupGrid } from '../../groups/GroupGrid';
import { AddGroupButton } from '../../groups/GroupModal';
import { useOfficeSettingsWithStore } from '../../officeSettings';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: 750,
  },
}));

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason();
  const translate = useTranslate();
  const theme = useTheme();
  const listCardRef = useRef(null);
  const { data: officeSettings } = useOfficeSettingsWithStore(office?.id);
  const classes = useStyles();

  const isPools = officeSettings?.groupingType === 'Pools';
  const isGroups = officeSettings?.groupingType === 'Groups';

  const showGroups = isAuthorized(office, 'groups', 'list')
  const canAddGroups = isGroups && isAuthorized(office, 'groups', 'create')

  if (!showGroups) return null;

  return <Tooltip
    title={isPools
      ? <Typography variant="body2" display="inline">
        <div dangerouslySetInnerHTML={{ __html: `${translate("resources.pools.tooltips.groups_unavailable")}${translate('resources.officeSettings.tooltips.groupingType')}` } } />
      </Typography>
      : ''}
    placement="center"
    classes={{ tooltip: classes.tooltip }}
  >
    <div ref={listCardRef}>
      <ListCard
        title="resources.groups.labels.card.title"
        subtitle="resources.groups.labels.card.subtitle"
        icon={<LayersOutlined />}
        collapsable
        canOpen={isGroups}
        reference="groups"
        target="offices"
        source="id"
        filter={{ seasonId }}
        sort={{ field: 'name', order: 'ASC' }}
        addButton={canAddGroups && <AddGroupButton initialValues={{ officeId: office?.id }} officeId={office?.id} />}
        componentProps={{ style: { color: isPools ? theme.palette.text.disabled : undefined } }}
      >
        <GroupGrid rowOptions={{ isRowSelectable: () => isGroups }} />
      </ListCard>
    </div>
  </Tooltip>
}
