import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInput, useTranslate } from "react-admin";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Clear } from '@material-ui/icons';

import { debounce as debounceCallback } from '@hisports/common/src/lodash';

export const DebounceTextInput = ({ label, helperText, debounce = 300, ...props }) => {
  const {
    input: { value, onChange, ...inputProps },
    meta: { touched, error },
    isRequired
  } = useInput(props);
  const translate = useTranslate();
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const debouncedStoreChange = useMemo(
    () =>
      debounceCallback((newVal) => {
        onChange(newVal);
      }, debounce),
    [onChange, debounce]
  );

  const handleChange = useCallback((event) => {
    const newVal = event.target.value;
    setLocalValue(newVal);
    debouncedStoreChange(newVal);
  }, [debouncedStoreChange]);

  const handleClear = useCallback(() => {
    setLocalValue('');
    onChange('');
  }, [onChange]);

  return (
    <TextField
      {...props}
      {...inputProps}
      label={translate(label ? label : 'ra.action.search')}
      required={isRequired}
      value={localValue}
      onChange={handleChange}
      error={!!(touched && error)}
      helperText={touched && error ? error : helperText ? translate(helperText) : ''}
      placeholder={translate('ra.input.autocomplete.type_to_search')}
      InputProps={{
        endAdornment: (
          localValue && <InputAdornment position="end">
            <IconButton size="small" edge="end" onClick={handleClear}>
              <Clear fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};
