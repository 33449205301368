import React, { Fragment } from 'react';
import { useNotify, SimpleForm, FormInput, NumberInput, useTranslate, useRecordContext } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Table, TableHead, TableCell, TableBody, TableRow, useMediaQuery } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import { updateThrowsInnings } from '@hisports/scoresheet/src/actions';
import { useScoresheet, useScoresheetDispatch } from '../ScoresheetContext';
import { getLineupPlayers } from '@hisports/scoresheet/src/selectors';
import { getThrowsInnings } from '@hisports/scoresheet/src/selectors/baseball';
import { useSport } from '../../../http';

const Heading = styled('h4')(() => ({
  margin: '-12px 0 16px 0'
}))

const Player = styled(TableCell)(({ theme }) => ({
  minWidth: theme.spacing(12),
  padding: theme.spacing(1),
}))

const Throw = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  width: theme.spacing(15),
  padding: theme.spacing(1),
}))

const Inning = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  width: theme.spacing(15),
  padding: theme.spacing(1),
}))

const ThrowFormInput = styled(FormInput)(({ theme }) => ({
  input: {
    width: theme.spacing(15),
    margin: 0,
  }
}))

const InningFormInput = styled(FormInput)(({ theme }) => ({
  input: {
    width: theme.spacing(15),
    margin: 0,
  }
}))

const validateThrowsInnings = value => {
  if (!value) return;
  if (value < 0) return 'ra.validation.positive';
  if (!Number.isInteger(value)) return 'ra.validation.whole';
}

const ThrowInput = ({ index }) =>
  <ThrowFormInput input={
    <NumberInput
      source={`throwsInnings[${index}].throws`}
      validate={validateThrowsInnings}
      step={1}
      min={0}
      max={999}
      label={false}
      variant="outlined"
      helperText={false}
      options={{
        InputLabelProps: {
          shrink: false,
        }
      }}
    />
  } />

const InningInput = ({ index }) =>
  <InningFormInput input={
    <NumberInput
      source={`throwsInnings[${index}].inningsPitched`}
      validate={validateThrowsInnings}
      step={1}
      min={0}
      max={999}
      label={false}
      variant="outlined"
      helperText={false}
      options={{
        InputLabelProps: {
          shrink: false,
        }
      }}
    />
  } />

const ThrowsInningsForm = ({ throwsInnings, teamId, ...props }) => {
  const translate = useTranslate();
  return <SimpleForm {...props}>
    <Table selectable={false} fullWidth>
      <TableHead displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <Player>{translate('components.throws_innings.labels.player')}</Player>
          <Throw>{translate('components.throws_innings.labels.throws')}</Throw>
          <Inning>{translate('components.throws_innings.labels.innings')}</Inning>
        </TableRow>
      </TableHead>
      <TableBody displayRowCheckbox={false}>
        {throwsInnings.map((player, index) => {
          if (teamId == player.teamId) {
            return <TableRow key={player.participantId}>
              <Player>#{player.number} {player.participant.fullName}</Player>
              <Throw>
                <ThrowInput index={index} />
              </Throw>
              <Inning>
                <InningInput index={index} />
              </Inning>
            </TableRow>
          }
        })}
      </TableBody>
    </Table>
  </SimpleForm>
}

const getTotals = (participantId, throwsInnings, type) => {
  const data = throwsInnings.find(player => player.participantId == participantId);
  if (!data) return;
  return data[type];
}

const getPlayerThrowsInnings = (teamId, lineupPlayers, throwsInnings) => {
  const playerThrowsInnings = [];

  lineupPlayers.forEach((player) => {
    playerThrowsInnings.push({
      participantId: player.participantId,
      number: player.number,
      participant: player.participant,
      teamId: teamId,
      throws: getTotals(player.participantId, throwsInnings, 'throws'),
      inningsPitched: getTotals(player.participantId, throwsInnings, 'inningsPitched'),
    })
  })

  playerThrowsInnings.sort((a, b) => {
    if (a.number < b.number) return -1;
    if (a.number > b.number) return 1;

    return 0;
  })

  return playerThrowsInnings;
}

const GameThrowsInningsModal = ({ isOpen, onClose, ...props }) => {
  const game = useRecordContext(props);
  const teamId = props.teamId;
  const sport = useSport();
  const notify = useNotify();
  const translate = useTranslate();
  const dispatch = useScoresheetDispatch();

  const lineupPlayers = useScoresheet(scoresheet => getLineupPlayers(scoresheet, { teamId, affiliatesLast: false, sport, hideUnsaved: true }))
  const throwsInnings = useScoresheet(scoresheet => getThrowsInnings(scoresheet, { teamId }))
  const playerThrowsInnings = getPlayerThrowsInnings(teamId, lineupPlayers, throwsInnings)

  const onSubmit = async (values) => {
    const throwsInnings = values.throwsInnings.filter(player => player.throws != null || player.inningsPitched != null)
    try {
      await dispatch(updateThrowsInnings(game.id, props.teamId, throwsInnings))
      notify('components.throws_innings.notifications.updated', 'info')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      notify('ra.message.error', 'error')
    }
    onClose()
  }

  return <>
    <DialogTitle>{translate('components.throws_innings.name')}</DialogTitle>
    <DialogContent>
      <Heading>{translate('resources.games.labels.game_number', { number: game.number })}</Heading>
      <ThrowsInningsForm game={game} teamId={props.teamId} initialValues={{ throwsInnings: playerThrowsInnings }} throwsInnings={playerThrowsInnings} save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.save" onCancel={onClose} />
      } />
    </DialogContent>
  </>
}

export const ThrowsInningsModal = ({ open, onClose, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return <Dialog open={open} onClose={onClose} fullScreen={fullScreen} maxWidth="sm" fullWidth>
    <GameThrowsInningsModal open={open} onClose={onClose} {...props} />
  </Dialog>
}
