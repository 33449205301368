import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { useTranslate } from "react-admin";
import { VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.warning[500],
  }
}))

export const NotPublicIcon = ({ record, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (record?.isPublic !== false) return null;
  return <Tooltip title={translate('resources.schedules.labels.not_public')}>
    <VisibilityOff className={classes.icon} fontSize="inherit" {...props} />
  </Tooltip>
}
