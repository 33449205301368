import { useEffect } from 'react';

import usePreferences from './usePreferences';

const EVENT_NAME = 'preferences-changed';

export default function useSyncedPreferences(key, initialValue) {
  const [state, setState, loaded] = usePreferences(key, initialValue);

  useEffect(() => {
    function handlePreferenceChange(event) {
      if (event.detail?.key === key) {
        setState(prev => (prev === event.detail.value ? prev : event.detail.value));
      }
    }

    window.addEventListener(EVENT_NAME, handlePreferenceChange);
    return () => {
      window.removeEventListener(EVENT_NAME, handlePreferenceChange);
    };
  }, [key, setState]);

  useEffect(() => {
    if (!loaded) return;

    const event = new CustomEvent(EVENT_NAME, {
      detail: { key, value: state }
    });

    window.dispatchEvent(event);
  }, [key, state, loaded]);

  return [state, setState, loaded];
}
